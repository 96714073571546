/**
 * Solicitudes Pragma SA
 */

import { Auth } from '@aws-amplify/auth';
import axios, { AxiosRequestConfig } from 'axios';

/**
 * Axios Interceptor
 *
 * @param config AxiosRequestConfig
 * @returns Promise
 */
export default async <T = unknown>(config: AxiosRequestConfig<T>) => {
  axios.interceptors.request.use(
    (req) =>
      new Promise((resolve) => {
        Auth.currentSession()
          .then((session) => {
            const idTokenExpire = session.getIdToken().getExpiration();
            const refreshToken = session.getRefreshToken();
            const currentTimeSeconds = Math.round(+new Date() / 1000);

            if (idTokenExpire < currentTimeSeconds) {
              Auth.currentAuthenticatedUser().then((res) => {
                res.refreshSession(refreshToken, (err: Error, data: typeof session) => {
                  if (err) Auth.signOut();
                  else {
                    req.headers = {
                      ...req.headers,
                      'Accept-Language': localStorage.getItem('lang') || 'es',
                      Authorization: data.getIdToken().getJwtToken(),
                    };
                    resolve(req);
                  }
                });
              });
            } else {
              req.headers = {
                ...req.headers,
                'Accept-Language': localStorage.getItem('lang') || 'es',
                Authorization: session.getIdToken().getJwtToken(),
              };
              resolve(req);
            }
          })
          .catch(() => {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          });
      }),
  );

  try {
    const response = await axios(config);
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    // En caso de error del servidor
    if (error.response.data.mensaje) {
      throw Error(error.response.data.mensaje);
    }

    // En caso de error de conexión
    throw error;
  }
};
