/**
 * Solicitudes Pragma SA
 */

import React, { useContext } from 'react';

import FacilitateHistory from '@/app/advances/facilitator/history';
import FacilitatePending from '@/app/advances/facilitator/pending';
import AdvancesSearch from '@/app/advances/search';
import Tabs from '@/components/ui/tab';
import { sessionContext } from '@/contexts/session';
import { useTranslation } from '@/hooks/useLocalTranslation';

const FacilitatePage = () => {
  const { t } = useTranslation('pages');
  const { state } = useContext(sessionContext);
  const facilitatorRoute = state.menu.data.advances?.find(
    (route) => route.path === '/porfacilitardemiequipoanticipos',
  );

  return facilitatorRoute?.hijos ? (
    <Tabs
      isMain
      subMenus={[
        {
          name: 'listaranticiposfacilitador',
          title: t('advances.title.subMenu.listaranticiposfacilitador'),
          element: <FacilitatePending />,
          notifications:
            state.notifications.advances.porfacilitardemiequipoanticipos
              ?.listaranticiposfacilitador,
        },
        {
          name: 'historialfacilitador',
          title: t('advances.title.subMenu.historialfacilitador'),
          element: <FacilitateHistory />,
          notifications:
            state.notifications.advances.porfacilitardemiequipoanticipos?.historialfacilitador,
        },
        {
          name: 'facilitadorbusqueda',
          title: t('advances.title.subMenu.facilitadorbusqueda'),
          element: <AdvancesSearch />,
          notifications:
            state.notifications.advances.porfacilitardemiequipoanticipos?.facilitadorbusqueda,
        },
      ].filter((route) => facilitatorRoute.hijos.find((subMenu) => subMenu.nombre === route.name))}
    />
  ) : null;
};

export default FacilitatePage;
