/**
 * Solicitudes Pragma SA
 */

import { formatISO, lastDayOfMonth, lastDayOfYear, startOfYear } from 'date-fns';

/**
 * Transform filter into params
 *
 * @param month number
 * @param year number
 * @returns { fechaInicio: string, fechaFinal: string }
 */
export default (month?: number, year?: number) => {
  if (year === undefined || year === -1) return { fechaInicio: '', fechaFinal: '', todos: false };
  if (month === undefined || month === -1) {
    return {
      fechaInicio: formatISO(startOfYear(new Date(year, 0)), { representation: 'date' }),
      fechaFinal: formatISO(lastDayOfYear(new Date(year, 0)), { representation: 'date' }),
      todos: false,
    };
  }
  return {
    fechaInicio: formatISO(new Date(year, month), { representation: 'date' }),
    fechaFinal: formatISO(lastDayOfMonth(new Date(year, month)), { representation: 'date' }),
    todos: true,
  };
};
