/**
 * Solicitudes Pragma SA
 */

import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { ChangeApproverAdvances } from '@/app/advances/administrator/changeApprover';
import PollPage from '@/app/advances/administrator/poll';
import Tabs from '@/components/ui/tab';
import { sessionContext } from '@/contexts/session';

const AdministratorAdvancePage = () => {
  const { t } = useTranslation('pages');
  const { state } = useContext(sessionContext);
  const administratorRoute = state.menu.data.advances?.find(
    (route) => route.path === '/administradoranticipos',
  );

  return administratorRoute?.hijos ? (
    <Tabs
      isMain
      subMenus={[
        {
          name: 'habilitarencuestaanticipo',
          title: t('advances.title.subMenu.habilitarencuesta'),
          element: <PollPage />,
        },
        {
          name: 'cambiaraprobadoranticipo',
          title: t('advances.title.subMenu.reasignaraprobador'),
          element: <ChangeApproverAdvances />,
        },
      ].filter((route) =>
        administratorRoute.hijos.find((subMenu) => subMenu.nombre === route.name),
      )}
    />
  ) : null;
};

export default AdministratorAdvancePage;
