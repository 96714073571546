/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useRef, useState } from 'react';

import Button from '@/components/ui/button';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';

import i18n from './i18n.json';
import AttachButtonConstants from '@/constants/attach-button-constants';

type TProps = {
  maxSize: number;
  fileTypes: string[];
  onChange?: (file: File, base64: string | ArrayBuffer | null) => void;
  className?: string;
};

const UploadButton = ({ maxSize, fileTypes, onChange, className }: TProps) => {

  const constants = AttachButtonConstants;
  const {
    actions: { addToast },
  } = useContext(utilsContext);
  const translate = useLocalTranslation(i18n as Resource);
  const hiddenFileInput = useRef<HTMLInputElement>(constants.NULL);
  const [value, setValue] = useState(constants.EMPTY_STRING);
  const extensiones = constants.EXTENCIONES.TYPESFILES;

  const handleFile = (file: File) => {
    const typeFile = file.type.split(constants.SIGNS.SLASH)[constants.NUMBERS.ONE];

    if (file.size > maxSize) {
      addToast(`${translate('toast.fileSizeError')} ${maxSize / constants.MAX_SIZE}${constants.EXTENCIONES.MB}`, AttachButtonConstants.TOAST_TYPE.DANGER);
    } else if (!fileTypes.includes(typeFile)) {
        addToast(translate('toast.fileTypeError'), AttachButtonConstants.TOAST_TYPE.DANGER);
    } else if (onChange) {
        readFileAsDataURL(file).then(result => {
            
            validateFile(typeFile, result!.toString(), file);

        }).catch( () => {
          addToast(translate('toast.fileTypeError'), AttachButtonConstants.TOAST_TYPE.DANGER);
        });
    }
    setValue(constants.EMPTY_STRING);
};

const validateFile = (typeFile: string, result?: string, file?: File | any) => {
  if (extensiones[typeFile as keyof typeof extensiones]) {
    const bodyBase64 = result!.toString().split(constants.SIGNS.COMMA)[constants.NUMBERS.ONE].slice(constants.NUMBERS.ZERO, constants.NUMBERS.NINE).toUpperCase();
    const headBase64 = result!.toString().split(constants.SIGNS.COMMA)[constants.NUMBERS.ZERO];
    const extension = extensiones[typeFile as keyof typeof extensiones];

    if (bodyBase64.includes(extension.toUpperCase())) {
      const startIndex = result!.toString().toUpperCase().indexOf(extension);

      const base64 = headBase64.concat(constants.SIGNS.COMMA + result!.toString().substring(startIndex));
      
      if (onChange){
        onChange(file, base64);        
      }

    } else {
      addToast(translate('toast.fileCorruptError'), AttachButtonConstants.TOAST_TYPE.DANGER);
    }
  } else {
    addToast(translate('toast.fileTypeError'), AttachButtonConstants.TOAST_TYPE.DANGER);
  }
};

const readFileAsDataURL = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(addToast(translate('toast.uploadError'), AttachButtonConstants.TOAST_TYPE.DANGER));
      reader.readAsDataURL(file);
  });
};
  

  return (
    <>
      <Button
        name="comp-uploadButton"
        className={className}
        onClick={() => hiddenFileInput.current && hiddenFileInput.current.click()}
      >
        {translate('label')}
      </Button>
      <input
        type="file"
        accept="image/png, image/jpeg, application/pdf"
        ref={hiddenFileInput}
        onChange={(e) => e.currentTarget.files && handleFile(e.currentTarget.files[constants.NUMBERS.ZERO])}
        value={value}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default UploadButton;
