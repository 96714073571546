/**
 * Solicitudes Pragma SA
 */

import React, { useState } from 'react';

import Button from '@/components/ui/button';
import TextField from '@/components/ui/textField';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import type { Types } from '@/services/vacations';

import i18n from './i18n.json';
import styles from './style.module.scss';

type TProps = {
  handleSearch: (form: Types.TGetInformationForm, page?: number) => void;
};

const Filter = ({ handleSearch }: TProps) => {
  const t = useLocalTranslation(i18n as Resource);
  const [filter, setFilter] = useState('');

  const handleClick = () => {
    const params = {
      nombres: '',
      identificacion: '',
    };
    if (isNaN(Number(filter))) {
      params.nombres = filter;
    } else {
      params.identificacion = filter;
    }
    handleSearch(params);
  };

  return (
    <div className={styles['search']}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleClick();
        }}
        className={styles['filter']}
      >
        <TextField
          name="vac-information_filter"
          placeholder={t('name.placeholder')}
          onChange={({ currentTarget }) => setFilter(currentTarget.value)}
          value={filter}
        />
        <Button
          name="vac-information_button-search-user"
          onClick={handleClick}
          theme="primary-white"
        >
          {t('actions.search')}
        </Button>
      </form>
    </div>
  );
};

export default Filter;
