/* eslint-disable */

export default {
  aws_project_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_TwsywfrID',
  aws_user_pools_web_client_id: '30m3d0abdr0r1pl02p6o9t7suk',
  oauth: {
    domain: 'pragma-perfil.auth.us-east-1.amazoncognito.com',
    scope: [
      'aws.cognito.signin.user.admin',
      'd3jvm6ee97l28q.cloudfront.net/cv',
      'email',
      'openid',
      'phone',
      'profile',
    ],
    redirectSignIn: window.location.origin + '/login',
    redirectSignOut: window.location.origin + '/login',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
};
