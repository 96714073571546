/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import { MoneyIcon } from '@/assets/icons/icons';
import user from '@/assets/images/user.png';
import type { TData } from '@/components/card';
import useFormatDate from '@/hooks/useFormatDate';
import useLocalTranslation from '@/hooks/useLocalTranslation';
import type { Types } from '@/services/advances';
import endpoints from '@/services/endpoints';

const i18n = {
  es: {
    card: {
      info: {
        consignmentDate: 'Fecha de consignación',
        approver: 'Aprobador',
        costCenters: 'Centro(s) de costo',
        totalAmount: 'Monto total',
        tickets: 'Tiquetes',
        hosting: 'Hospedaje',
        facilitator: 'Facilitador',
        defaultFacilitator: 'Sin asignar',
        requestCash: 'Dinero en efectivo',
        options: {
          true: 'SI',
          false: 'NO',
        },
      },
    },
    table: {
      header: {
        id: 'No.',
        consignmentDate: 'Fecha de consignación',
        idUser: 'Documento',
        username: 'Nombre solicitante',
        currency: 'Moneda',
        amountRequest: 'Monto solicitado',
        amountLegalized: 'Monto legalizado',
        amountDiff: 'Diferencia',
        userState: 'Estado de usuario',
        payroll: 'Descuento nómina',
        applicantEmail: 'Correo de solicitante',
        authorizerEmail: 'Correo de autorizador',
        costCenter: 'Centro de costos',
        payment: 'Pago',
        creationDate: 'Fecha de creación',
        approverName: 'Aprobador',
        travelExpenses: 'Viáticos',
        date: 'Fecha',
        message: 'Mensaje',
        action: 'Acción',
        name: 'Nombre',
        business: 'Negocio',
        brand: 'Marca',
        percent: 'Porcentaje (%)',
        cityName: 'Ciudad',
        arrivalDate: 'Fecha de llegada',
        departureDate: 'Fecha de salida',
        cityOrigin: 'Ciudad origen',
        cityDestination: 'Ciudad destino',
        travelDate: 'Fecha de viaje',
        travelTime: 'Hora de viaje',
        companyName: 'Nombre del Establecimiento',
        invoiceValue: 'Valor de la factura',
        idInvoice: 'Id de la factura',
        requestCash: 'Dinero en efectivo',
        authorizerName: 'Nombre autorizador',
        eDescription: 'Descripcion',
        files: 'Archivos',
      },
    },
    state: 'Estado',
  },
  en: {
    card: {
      info: {
        consignmentDate: 'Consignment Date',
        approver: 'Approver',
        costCenters: 'Cost centers',
        totalAmount: 'Total Amount',
        tickets: 'Tickets',
        hosting: 'Hosting',
        facilitator: 'Facilitator',
        defaultFacilitator: 'Unassigned',
        requestCash: 'Request cash',
        options: {
          true: 'YES',
          false: 'NO',
        },
      },
    },
    table: {
      header: {
        id: 'No.',
        consignmentDate: 'Consignment date',
        idUser: 'Document No.',
        username: 'Applicant',
        currency: 'Currency',
        amountRequest: 'Requested amount',
        amountLegalized: 'Legalized amount',
        amountDiff: 'Difference',
        userState: 'User state',
        payroll: 'Payroll',
        applicantEmail: 'Applicant email',
        authorizerEmail: 'Authorizer email',
        costCenter: 'Cost center(s)',
        payment: 'Payment',
        creationDate: 'Creation date',
        approverName: 'Approver',
        travelExpenses: 'Travel expenses',
        date: 'Date',
        message: 'Message',
        action: 'Action',
        name: 'Name',
        business: 'usiness',
        brand: 'Client',
        percent: 'Percent (%)',
        cityName: 'City',
        arrivalDate: 'Arrival date',
        departureDate: 'Departure date',
        cityOrigin: 'City origin',
        cityDestination: 'City destination',
        travelDate: 'Date travel',
        travelTime: 'Time travel',
        companyName: 'Company Name',
        invoiceValue: 'Invoice Value',
        idInvoice: 'Invoice Id',
        requestCash: 'Request cash',
        authorizerName: 'Authorizer',
        eDescription: 'Description',
      },
    },
    state: 'State',
  },
};

const useAdvancesData = () => {
  const t = useLocalTranslation(i18n, 'advances');
  const formatDate = useFormatDate();

  return {
    cardInfo: (data: Types.TAdvanceItem): TData[] => [
      {
        label: t('card.info.consignmentDate'),
        value: formatDate(new Date(`${data.fechaConsignacion}T00:00`)),
        width: '170px',
      },
      {
        label: t('card.info.approver'),
        value: data.nombreUsuarioAutorizador,
        width: '180px',
      },
      {
        label: t('card.info.costCenters'),
        value: (
          <>
            {data.centroCostos.map((item, i) => (
              <span
                key={`${i}-${item.codigoInterno}(${item.porcentaje}%)`}
              >{`${item.codigoInterno}(${item.porcentaje}%)`}</span>
            ))}
          </>
        ),
        width: '160px',
      },
      {
        label: t('card.info.totalAmount'),
        value: `$ ${data.montoTotal} ${data.codigoMoneda || ''}`,
      },
      {
        label: t('card.info.tickets'),
        value: data.tiquetes ? t('card.info.options.true') : t('card.info.options.false'),
      },
      {
        label: t('card.info.hosting'),
        value: data.hospedajes ? t('card.info.options.true') : t('card.info.options.false'),
      },
      {
        label: (
          <>
            <img
              style={{ borderRadius: '50%', gridArea: '1 / 1 / 2 / 1' }}
              height={32}
              width={32}
              src={
                data.fotografiaUsuarioFacilitador?.contenido
                  ? `${endpoints.GET_MULTIMEDIA}/${data.fotografiaUsuarioFacilitador?.contenido}`
                  : user
              }
              alt={data.nombreUsuarioFacilitador}
            />
            <span style={{ gridArea: '1 / 2 / 1 / 2' }}>{t('card.info.facilitator')}</span>
          </>
        ),
        value: data.nombreUsuarioFacilitador || `-${t('card.info.defaultFacilitator')}-`,
        style: {
          display: 'grid',
          gridTemplateColumns: '32px 1fr',
          gridTemplateRows: '16px 16px',
          columnGap: 8,
        },
        valueStyle: { gridArea: ' 2 / 2 / 2 / 2' },
      },
      {
        label: t('card.info.requestCash'),
        value: <MoneyIcon width={25} height={25} />,
        style: { display: data.solicitaEfectivo ? 'inherit' : 'none' },
      },
    ],
    tableHeader: (name: keyof typeof i18n.es.table.header) => t(`table.header.${name}`),
  };
};

export default useAdvancesData;
