/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import NotFound from '@/app/404';

const Page404 = () => <NotFound />;

export default Page404;
