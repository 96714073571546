/**
 * Solicitudes Pragma SA
 */

import React, { useState, useContext, useEffect } from 'react';

import { MinusIcon, MoneyIcon } from '@/assets/icons/icons';
import Table from '@/components/table';
import Button from '@/components/ui/button';
import { utilsContext } from '@/contexts/utils';
import useAdvancesData from '@/hooks/useAdvancesData';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import useList from '@/hooks/useList';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Types, generateCompensationReport, getCompensationList } from '@/services/advances';
import { createFileBase64, getFileName } from '@/utils/downloadFile';

import Filter from './filter';
import i18n from './i18n.json';
import styles from './styles.module.scss';

const Compensation = () => {
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const cancellablePromise = useCancellablePromise();
  const [list, setList, setListLoading] = useList<Types.TCompensationListItem>();
  const [form, setForm] = useState<Types.TCompensationListForm>({
    fechaInicio: '',
    fechaFin: '',
  });
  const t = useLocalTranslation(i18n as Resource);
  const { tableHeader } = useAdvancesData();

  const handleDownload = () => {
    setLoading(true);
    generateCompensationReport({
      ...form,
      fechaOrdenAscendente: true,
    })
      .then((base64) => {
        createFileBase64(base64, getFileName('reporte_compensacion'))
          .then(() => addToast(t('toast.documentSuccess'), 'success'))
          .catch((e) => addToast(e.message, 'danger'));
      })
      .catch((e) => addToast(e.message, 'danger'))
      .finally(() => setLoading(false));
  };

  const requestList = (query?: Types.TCompensationListForm, page = 0) => {
    setListLoading(true, !page);
    cancellablePromise(
      getCompensationList({
        ...(query || form),
        numeroPagina: page,
        totalElementos: 20,
        fechaOrdenAscendente: true,
      })
        .finally(() => setListLoading(false))
        .then((res) => {
          setList(res.dato || [], !!page, res.cantidadPaginas, res.ultimaPagina, page);
        })
        .catch((e) => addToast(e.message, 'danger')),
    );
    if (query) setForm(query);
  };

  useEffect(() => {
    requestList();
  }, []);

  return (
    <div className={styles['compensation']}>
      <Filter handleSearch={requestList} />
      <div className={styles['actions']}>
        <Button name="ant-compensation_button-download" onClick={handleDownload}>
          {t('actions.download')}
        </Button>
      </div>
      <Table
        name="ant-compensation_table"
        header={[
          { name: 'id', label: tableHeader('id') },
          { name: 'fechaConsignacion', label: tableHeader('consignmentDate') },
          { name: 'identificacion', label: tableHeader('idUser') },
          { name: 'nombreUsuario', label: tableHeader('username') },
          { name: 'codigoMoneda', label: tableHeader('currency') },
          { name: 'montoTotalSolicitado', label: tableHeader('amountRequest') },
          { name: 'montoTotalLegalizado', label: tableHeader('amountLegalized') },
          { name: 'diferenciaMontos', label: tableHeader('amountDiff') },
          { name: 'estadoUsuario', label: tableHeader('userState') },
          { name: 'descuentoNomina', label: tableHeader('payroll') },
          { name: 'requestCash', label: tableHeader('requestCash') },
        ]}
        data={list.list.map((item) => ({
          _key: item.id,
          ...item,
          montoTotalSolicitado: `$ ${new Intl.NumberFormat('es-CO').format(
            item.montoTotalSolicitado,
          )}`,
          montoTotalLegalizado: `$ ${new Intl.NumberFormat('es-CO').format(
            item.montoTotalLegalizado,
          )}`,
          diferenciaMontos: new Intl.NumberFormat('es-CO').format(item.diferenciaMontos),
          requestCash:
            item.solicitaEfectivo === 'SI.' ? (
              <MoneyIcon width={25} height={25} className={styles['icon']} />
            ) : (
              <MinusIcon width={25} height={2} className={styles['icon']} />
            ),
        }))}
        advanced={{ ...list, handleSearch: (page) => requestList(form, page) }}
        emptyLabel={`${t('table.noData')} 🚀`}
      />
    </div>
  );
};

export default Compensation;
