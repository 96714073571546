/**
 * Solicitudes Pragma SA
 */

import { useState } from 'react';

export type TList<T> = {
  list: (T & { _visible: boolean })[];
  count: number;
  page: number;
  pages?: number;
  lastPage?: boolean;
  isAnyVisible?: boolean;
  isLoading?: boolean;
  isNewSearch?: boolean;
};

type TSetList<T> = (
  list: T,
  join?: boolean,
  pages?: number,
  lastPage?: boolean,
  page?: number,
) => void;
type TSetVisible<R> = (
  match: (R & Record<string, unknown>) | (R & Record<string, unknown>)[],
  value: boolean,
) => void;
type TSetParam<R, V> = (match: R & Record<string, unknown>, value: V) => void;
type TSetLoading = (isLoading: boolean, isNewSearch?: boolean) => void;
type TUseList = <T = unknown, R = { id: number }, V = unknown>(
  initial?: T[],
  initialPage?: number,
) => [TList<T>, TSetList<T[]>, TSetLoading, TSetVisible<R>, TSetParam<R, V>];

const useList: TUseList = <T, R, V>(initial: T[] = [], initialPage = 0) => {
  const [value, setValue] = useState<TList<T>>({
    list: initial.map((item) => ({ ...item, _visible: true })),
    count: initial.length,
    isAnyVisible: true,
    page: initialPage,
    isLoading: false,
    isNewSearch: true,
  });

  const setList: TSetList<T[]> = (list, join, pages, lastPage, page) => {
    setValue({
      list: join
        ? [...value.list, ...list.map((item) => ({ ...item, _visible: true }))]
        : list.map((item) => ({ ...item, _visible: true })),
      count: join ? [...value.list, list].length : list.length,
      pages,
      lastPage,
      isAnyVisible: true,
      page: page || 0,
    });
  };

  const setVisible: TSetVisible<R> = (match, visible) => {
    const isArray = Array.isArray(match);
    let normalizedMatch = [];
    if (!isArray) normalizedMatch[0] = match;
    else normalizedMatch = match;
    let { list } = value;
    normalizedMatch.forEach((itemMatch) => {
      const key = Object.keys(itemMatch)[0];
      if (key) {
        list = list.map((item: T & { _visible: boolean } & Record<string, unknown>) => {
          if (item[key] === itemMatch[key]) {
            return { ...item, _visible: visible };
          }
          return item;
        });
      }
    });
    const isAnyVisible = list.some((item) => item._visible === true);
    setValue({
      ...value,
      list,
      isAnyVisible,
    });
  };

  const setParam: TSetParam<R, V> = (match, newValue) => {
    const key = Object.keys(match)[0];
    if (key) {
      setValue({
        ...value,
        list: value.list.map((item: T & { _visible: boolean } & Record<string, unknown>) => {
          if (item[key] === match[key]) {
            return { ...item, ...newValue };
          }
          return item;
        }),
      });
    }
  };

  const setLoading: TSetLoading = (isLoading, isNewSearch = false) => {
    setValue({ ...value, isLoading, isNewSearch });
  };

  return [value, setList, setLoading, setVisible, setParam];
};

export default useList;
