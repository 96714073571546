/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import DenyModal, { TDeny } from '@/components/denyModal';
import { utilsContext } from '@/contexts/utils';
import { denyRequest } from '@/services/vacations';

const Approve = ({ id }: { id: number | null }) => {
  const {
    actions: { setLoading, addToast },
  } = useContext(utilsContext);
  const [denyModal, setDenyModal] = useState<TDeny>({ id: id || 0, show: true });
  const navigate = useNavigate();

  const handleDeny = (reason: string) => {
    if (id) {
      setLoading(true);
      denyRequest(id, reason)
        .finally(() => setLoading(false))
        .then((res) => {
          addToast(res, 'success');
          navigate('/solicitudesvacaciones', { replace: true });
        })
        .catch((e) => addToast(e.message, 'danger'));
    } else navigate('/404', { replace: true });
  };

  return (
    <DenyModal
      handleDeny={(idModal, reason) => handleDeny(reason)}
      deny={denyModal}
      setDeny={setDenyModal}
    />
  );
};

export default Approve;
