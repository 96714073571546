/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import { motion, AnimatePresence } from 'framer-motion';

import userPhoto from '@/assets/images/user.png';
import useFormatDate from '@/hooks/useFormatDate';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import endpoints from '@/services/endpoints';

import i18n from './i18n.json';
import styles from './styles.module.scss';

export type TData = {
  label: React.ReactNode;
  value: React.ReactNode;
  width?: string;
  style?: React.CSSProperties;
  valueStyle?: React.CSSProperties;
};

type TProps = {
  _visible?: boolean;
  header: {
    id: number;
    photo?: string;
    name: string;
    email: string;
    date: Date;
    tag?: string;
    onClick?: () => void;
  };
  data: TData[];
  state?: React.ReactNode;
  actions?: React.ReactNode[];
  footer?: React.ReactNode;
  overflow?: boolean;
};

const Card = ({ _visible = true, header, data, state, actions, footer, overflow }: TProps) => {
  const t = useLocalTranslation(i18n as Resource, 'card');
  const formatDate = useFormatDate();

  return (
    <AnimatePresence>
      {_visible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={overflow ? `${styles['card']} ${styles['overflow']}` : styles['card']}
          data-footer={footer ? '' : undefined}
        >
          <div className={styles['header']}>
            <div className={styles['user']}>
              <img
                src={header.photo ? `${endpoints.GET_MULTIMEDIA}/${header.photo}` : userPhoto}
                alt={t('photo')}
              />
              <div className={styles['info']}>
                <h3>{header.name}</h3>
                <div className={styles['email']}>{header.email}</div>
              </div>
            </div>
            <div className={styles['request']}>
              <div className={styles['id']}>{`${t('request')} #${header.id}`}</div>
              <div className={styles['date']}>{`${t('dateSend')} ${formatDate(header.date)}`}</div>
            </div>
            {header.tag && <div className={styles['tag']}>{header.tag}</div>}
          </div>
          <div className={styles['body']}>
            <div className={styles['info']}>
              {data.map((i, _key) => (
                <div key={_key} className={styles['data']} style={{ ...i.style, width: i.width }}>
                  {i.label}
                  <span style={i.valueStyle} className={styles['value']}>
                    {i.value}
                  </span>
                </div>
              ))}
            </div>
            {state}
          </div>
          {actions?.length ? (
            <div className={styles['actions']}>{actions.map((a) => a)}</div>
          ) : null}
          {footer}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default React.memo(Card);
