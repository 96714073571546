/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import Table from '@/components/table';
import useAdvancesData from '@/hooks/useAdvancesData';
import useFormatDate from '@/hooks/useFormatDate';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import type { Types } from '@/services/advances';

import i18n from './i18n.json';
import styles from './styles.module.scss';

type TProps = {
  id: string;
  data: Types.TAdvanceHistory[];
};

const AdvanceHistory = ({ id, data }: TProps) => {
  const t = useLocalTranslation(i18n as Resource);
  const { tableHeader } = useAdvancesData();
  const formatDate = useFormatDate();

  return (
    <>
      <h2 className={styles['title']}>{`${t('title')} ${id}`}</h2>
      <Table
        name="ant-search-history_table"
        header={[
          { name: 'fechaAccion', label: tableHeader('date') },
          { name: 'mensaje', label: tableHeader('message') },
          { name: 'accion', label: tableHeader('action') },
          { name: 'nombreUsuario', label: tableHeader('name') },
        ]}
        data={data.map((item, _key) => ({
          _key,
          ...item,
          fechaAccion: formatDate(new Date(item.fechaAccion), 'PPpp'),
        }))}
        emptyLabel={`${t('table.noData')} 🚀`}
      />
    </>
  );
};

export default AdvanceHistory;
