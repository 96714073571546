/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import PaymentRequest from '@/app/vacations/paidRequest';

const PaymentRequestPage = () => <PaymentRequest />;

export default PaymentRequestPage;
