/**
 * Solicitudes Pragma SA
 */

import React, { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import imgError from '@/assets/images/error_astronaut.svg';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const Error = () => {
  const t = useLocalTranslation(i18n as Resource);
  const location = useLocation();
  const navigate = useNavigate();
  const { msg } = (location.state as { msg: string }) || { msg: null };

  useEffect(() => {
    !msg && navigate('/');
  }, [msg]);

  return (
    <div className={styles['error-page']}>
      <img className={styles['logo']} src={imgError} alt="error" />
      <h1>{t('title')}</h1>
      <h3>{t('subtitle')}</h3>
      <h3>{t('description')}</h3>
      <h4>{msg}</h4>
    </div>
  );
};

export default Error;
