/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import Request from '@/app/advances/request';

const RequestPage = () => <Request />;

export default RequestPage;
