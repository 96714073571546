/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import { Route, RouteObject, Routes } from 'react-router-dom';

import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import i18n from '@/pages/i18n.json';
import Template from '@/template';

import Page404 from './404';
import AccountingPage from './advances/accounting';
import AdministratorAdvancePage from './advances/administratorAdvances';
import CompensationPage from './advances/compensation';
import DetailsPage from './advances/details';
import FacilitatePage from './advances/facilitate';
import FacilitateSupportsPage from './advances/facilitateSupports';
import AdvancesHistoryPage from './advances/history';
import LegalizationReviewPage from './advances/legalizationReview';
import LegalizePage from './advances/legalize';
import PendingPage from './advances/pending';
import AdvancesRequestPage from './advances/request';
import TreasuryPage from './advances/treasury';
import ErrorPage from './error';
import LoginPage from './login';
import AdministratorPage from './vacations/administrator';
import ApproveURLPage from './vacations/approveUrl';
import HistoryPage from './vacations/history';
import LiquidationsPage from './vacations/liquidations';
import OverviewPage from './vacations/overview';
import PaymentRequestPage from './vacations/paymentRequest';
import VacationsPendingPage from './vacations/pending';
import RejectURLPage from './vacations/rejectUrl';
import VacationsRequestPage from './vacations/request';

export type RouteObjectExtended = {
  hidden?: boolean;
  name?: string;
} & RouteObject;

export type TApps = 'vacations' | 'advances';

export type TRoutes = {
  name: TApps;
  id: string;
  index: string;
  routes: RouteObjectExtended[];
};

export enum Apps {
  VACATIONS = 'vacations',
  ADVANCES = 'advances',
}

export const PRAGMA: TRoutes[] = [
  {
    name: Apps.VACATIONS,
    id: '77b691f7-7a53-4f86-9cf1-75ff51c21c80',
    index: '/',
    routes: [
      { path: '/', element: <VacationsRequestPage /> },
      { path: '/vacacionespagas', element: <PaymentRequestPage /> },
      { path: '/solicitudesvacaciones', element: <VacationsPendingPage /> },
      { path: '/historial', element: <HistoryPage /> },
      { path: '/liquidaciones', element: <LiquidationsPage /> },
      { path: '/vistageneralvacaciones', element: <OverviewPage /> },
      { path: '/administrador', element: <AdministratorPage /> },
      {
        path: '/approve/:id',
        element: <ApproveURLPage />,
        hidden: true,
        name: 'approveRequest',
      },
      {
        path: '/reject/:id',
        element: <RejectURLPage />,
        hidden: true,
        name: 'rejectRequest',
      },
    ],
  },

  {
    name: Apps.ADVANCES,
    id: '77b691f7-7a53-4f86-9cf1-75ff51c21c81',
    index: '/solicitaranticipo',
    routes: [
      { path: '/solicitaranticipo', element: <AdvancesRequestPage /> },
      { path: '/solicitudesanticipos', element: <PendingPage /> },
      { path: '/porfacilitardemiequipoanticipos', element: <FacilitatePage /> },
      { path: '/tesoreria', element: <TreasuryPage /> },
      { path: '/contabilidad', element: <AccountingPage /> },
      { path: '/historialdemisanticipos', element: <AdvancesHistoryPage /> },
      { path: '/compensacion', element: <CompensationPage /> },
      { path: '/administradoranticipos', element: <AdministratorAdvancePage /> },
      {
        path: '/detallesanticipo',
        element: <DetailsPage />,
        hidden: true,
        name: 'details',
      },
      {
        path: '/legalizaranticipo',
        element: <LegalizePage />,
        hidden: true,
        name: 'legalize',
      },
      {
        path: '/revisarlegalizacionanticipo',
        element: <LegalizationReviewPage />,
        hidden: true,
        name: 'review',
      },
      {
        path: '/facilitar',
        element: <FacilitateSupportsPage />,
        hidden: true,
        name: 'facilitate',
      },
    ],
  },
];

let routes: JSX.Element[] = [];
PRAGMA.forEach((app) =>
  app.routes.forEach((route) => {
    routes = [...routes, ...[<Route key={route.path} path={route.path} element={route.element} />]];
  }),
);

const Pages = () => {
  useLocalTranslation(i18n as Resource, 'pages');

  return (
    <Routes>
      <Route path="/" element={<Template />}>
        {routes}
      </Route>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default Pages;
