/**
 * Solicitudes Pragma SA
 */

import React, { useState, useContext } from 'react';

import Button from '@/components/ui/button';
import TextField from '@/components/ui/textField';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Types, getAdvanceHistory, getDetailsExt } from '@/services/advances';

import Details from './details';
import History from './history';
import i18n from './i18n.json';
import styles from './styles.module.scss';

const Search = () => {
  const t = useLocalTranslation(i18n as Resource);
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const [idSearched, setIdSearched] = useState('');
  const [idTitle, setIdTitle] = useState('');
  const [history, setHistory] = useState<Types.TAdvanceHistory[] | null>(null);
  const [details, setDetails] = useState<Types.TAdvanceDetailsExt | null>(null);

  const handleSearchAdvance = () => {
    setLoading(true);
    setHistory(null);
    getDetailsExt(Number(idSearched))
      .finally(() => setLoading(false))
      .then((res) => setDetails(res))
      .catch((e) => {
        addToast(e.message, 'danger');
        setDetails(null);
      });
  };

  const handleViewHistoryAdvance = () => {
    setLoading(true);
    setDetails(null);
    setIdTitle(idSearched);
    getAdvanceHistory(Number(idSearched))
      .finally(() => setLoading(false))
      .then((res) => setHistory(res))
      .catch((e) => {
        addToast(e.message, 'danger');
        setHistory(null);
      });
  };

  return (
    <section className={styles['search']}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSearchAdvance();
        }}
      >
        <TextField
          label={t('request.label')}
          placeholder={t('request.placeholder')}
          keyPattern={/^[0-9]*$/}
          onChange={(e) => setIdSearched(e.currentTarget.value)}
          value={idSearched}
        />
        <Button
          name="ant-search_button_search"
          onClick={handleSearchAdvance}
          disabled={idSearched === ''}
        >
          {t('actions.search')}
        </Button>
        <Button
          name="ant-search_button_history"
          onClick={handleViewHistoryAdvance}
          disabled={idSearched === ''}
        >
          {t('actions.history')}
        </Button>
      </form>
      <div className={styles['content']}>
        {details ? <Details data={details} /> : history && <History id={idTitle} data={history} />}
      </div>
    </section>
  );
};

export default Search;
