/**
 * Solicitudes Pragma SA
 */

import { PRAGMA, TApps } from '@/pages';
import axiosClient from '@/services/axiosClient';
import endpoints from '@/services/endpoints';
import type * as APITypes from '@/services/types';
import formatParams from '@/utils/formatParams';

import type * as Types from './types';

export { Types };

export enum ROL {
  vacationsApprover = '77b691f7-7a53-4f86-9cf1-75ff51c21c91',
  advancesApprover = '77b691f7-7a53-4f86-9cf1-75ff51c21c96',
}

export const getCountries = async (form?: Types.TCountryForm): Promise<Types.TCountry[]> => {
  const response: APITypes.TResponseList<Types.TCountry> = await axiosClient({
    url: endpoints.catalogs.GET_COUNTRIES,
    method: 'GET',
    params: formatParams(
      form || {
        estados: ['ACTIVO'],
        nombreAscendente: true,
        numeroPagina: 0,
        totalElementos: 100,
        nivel: 0,
      },
    ),
  });

  return response.dato || [];
};

export const getBusiness = async (): Promise<Types.TBusiness[]> => {
  const response: APITypes.TResponse<Types.TBusiness[]> = await axiosClient({
    url: endpoints.catalogs.GET_BUSINESS,
    method: 'GET',
  });

  return response.dato || [];
};

export const getApprovers = async (app: TApps, idRol: ROL): Promise<Types.TApprover[]> => {
  const response: APITypes.TResponse<Types.TApprover[]> = await axiosClient({
    url: endpoints.security.GET_APPROVERS,
    method: 'GET',
    params: {
      idAplicacion: PRAGMA.find((item) => item.name === app)?.id,
      idRol,
    },
  });

  return response.dato || [];
};

export const getNonWorkingDays = async (id: string): Promise<Types.TNonWorkingDays | null> => {
  const response: APITypes.TResponse<Types.TNonWorkingDays> = await axiosClient({
    url: `${endpoints.catalogs.GET_NON_WORKING_DAYS}/${id}`,
    method: 'GET',
  });

  return response.dato || null;
};

export const calculateWorkingDays = async (
  form: Types.TCalculateWorkingDaysForm,
): Promise<number> => {
  const response: APITypes.TResponse<number> = await axiosClient({
    url: endpoints.catalogs.GET_WORKING_DAYS,
    method: 'GET',
    params: formatParams(form),
  });

  return response.dato || 0;
};

export const getDocumentTypes = async (): Promise<Types.TDocumentType[]> => {
  const response: APITypes.TResponse<Types.TDocumentType[]> = await axiosClient({
    url: endpoints.catalogs.GET_TYPE_DOCUMENT,
    method: 'GET',
  });

  return response.dato || [];
};

export const getUserByDocument = async (
  docType: number,
  document: number,
): Promise<Types.TApplicant | undefined> => {
  const response: APITypes.TResponse<Types.TApplicant> = await axiosClient({
    url: `${endpoints.profile.GET_USER_BY_DOCUMENT}/${docType}/${document}`,
    method: 'GET',
  });

  return response.dato;
};

export const getFacilitators = async (): Promise<Types.TFacilitator[]> => {
  const response: APITypes.TResponse<Types.TFacilitator[]> = await axiosClient({
    url: endpoints.advances.GET_FACILITATORS,
    method: 'GET',
  });

  return response.dato || [];
};

export const getCoins = async (): Promise<Types.TCoin[]> => {
  const response: APITypes.TResponse<Types.TCoin[]> = await axiosClient({
    url: endpoints.catalogs.GET_CURRENCY,
    method: 'GET',
  });

  return response.dato || [];
};

export const getApproverByBusiness = async (id: string): Promise<Types.TApprover[]> => {
  const response: APITypes.TResponse<Types.TApprover[]> = await axiosClient({
    url: `${endpoints.security.GET_APPROVERS_BY_BUSINESS}/${id}`,
    method: 'GET',
  });

  return response.dato || [];
};

export const getClientsByBusiness = async (id: string): Promise<Types.TClient[]> => {
  const response: APITypes.TResponse<Types.TClient[]> = await axiosClient({
    url: `${endpoints.catalogs.GET_CLIENTS_BY_BUSINESS}/${id}`,
    method: 'GET',
  });

  return response.dato || [];
};

export const getClients = async (): Promise<Types.TClient[]> => {
  const response: APITypes.TResponse<Types.TClient[]> = await axiosClient({
    url: endpoints.catalogs.GET_CLIENTS,
    method: 'GET',
  });

  return response.dato || [];
};

export const getCities = async (keyword: string): Promise<Types.TCity[]> => {
  const response: APITypes.TResponseList<Types.TCity> = await axiosClient({
    url: endpoints.catalogs.GET_CITIES,
    method: 'GET',
    params: {
      keyword,
    },
  });

  return response.dato || [];
};

export const getCityById = async (cityId: number): Promise<Types.TCityId | null> => {
  const response: APITypes.TResponse<Types.TCityId> = await axiosClient({
    url: `${endpoints.catalogs.GET_CITIES}/${cityId}`,
    method: 'GET',
  });

  return response.dato || null;
};
