/**
 * Solicitudes Pragma SA
 */

import React, { useState } from 'react';

import { addDays, formatISO } from 'date-fns';
import { useFormContext } from 'react-hook-form';

import { CancelTreasuryIcon } from '@/assets/icons/icons';
import SelectCity from '@/components/selects/selectCity';
import Table from '@/components/table';
import Button from '@/components/ui/button';
import calendar from '@/components/ui/calendar';
import useAdvancesData from '@/hooks/useAdvancesData';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import type { Types } from '@/services/advances';
import type { Types as CatalogsTypes } from '@/services/catalogs';

import { TForm } from '..';

import i18n from './i18n.json';
import styles from './styles.module.scss';

export type THost = {
  host?: CatalogsTypes.TCity;
  dateIn?: Date | null;
  dateOut?: Date | null;
};

const Hosts = () => {
  const t = useLocalTranslation(i18n as Resource);
  const { watch, setValue } = useFormContext<TForm>();
  const [dateIn, setDateIn] = useState<Date | null>();
  const [dateOut, setDateOut] = useState<Date | null>();
  const [host, setHost] = useState<THost>({ dateIn, dateOut });
  const { tableHeader } = useAdvancesData();

  const hosts = watch('hospedajes') || [];
  const tickets = watch('tiquetes') || [];

  const reset = () => {
    setDateIn(null);
    setDateOut(null);
    setHost({ dateIn, dateOut });
  };

  const handleAdd = () => {
    if (dateIn && dateOut) {
      setValue('hospedajes', [
        {
          ciudad: host.host?.ciudadId || 0,
          nombreCiudad:
            `${host.host?.nombre}, ${host.host?.departamento} - ${host.host?.pais}` || '-',
          fechaLlegada: formatISO(dateIn, { representation: 'date' }),
          fechaSalida: formatISO(dateOut, { representation: 'date' }),
        },
      ]);
      reset();
    }
  };

  const handleAddRecomended = () => {
    let hostList: Types.THostsForm[] = [];

    tickets.forEach((ticket, i) => {
      if (i < tickets.length - 1) {
        hostList = [
          ...hostList,
          ...[
            {
              ciudad: tickets[i + 1].origen,
              nombreCiudad: tickets[i + 1].nombreOrigen,
              fechaLlegada: ticket.fechaViaje,
              fechaSalida: tickets[i + 1].fechaViaje,
            },
          ],
        ];
      }
    });

    setValue('hospedajes', hostList);
    reset();
  };

  return (
    <section className={styles['hosts']}>
      <h3>{t('title')}</h3>
      <form>
        <SelectCity
          className={styles['city']}
          onChange={(value) => setHost({ ...host, host: value })}
          value={host.host}
        />
        <calendar.Input
          label={t('fields.arrivalDate.label')}
          placeholderText={t('fields.arrivalDate.placeholder')}
          className={styles['date-in']}
          selected={dateIn}
          minDate={addDays(new Date(), 1)}
          onChange={(day) => {
            if (day) {
              setDateIn(day);
              setDateOut(undefined);
            }
          }}
        />
        <calendar.Input
          label={t('fields.departureDate.label')}
          placeholderText={t('fields.departureDate.placeholder')}
          className={styles['date-out']}
          selected={dateOut}
          minDate={addDays(dateIn || new Date(), 1)}
          onChange={(day) => day && setDateOut(day)}
          disabled={!dateIn}
        />
        <div className={styles['actions']}>
          <Button
            theme="secondary"
            name="ant-request_button-add-recommended-hosting"
            disabled={tickets.length < 2 || hosts.length > 0}
            onClick={handleAddRecomended}
          >
            {t('actions.autocomplete')}
          </Button>
          <Button
            className={styles['host-button-add']}
            name="ant-request_button-add-hosting"
            disabled={host.host === null || !dateIn || !dateOut}
            onClick={handleAdd}
          >
            {t('actions.add')}
          </Button>
        </div>
      </form>
      {!!hosts.length && (
        <Table
          name="ant-request-hosts_table"
          header={[
            { name: 'nombreCiudad', label: tableHeader('cityName') },
            { name: 'fechaLlegada', label: tableHeader('arrivalDate') },
            { name: 'fechaSalida', label: tableHeader('departureDate') },
            { name: '_actions', label: t('table.actions') },
          ]}
          data={hosts.map((item, _key) => ({
            _key,
            ...item,
            _actions: (
              <Button
                name="ant-request_button-delete-hosting"
                theme="error"
                className={styles.delete}
                onClick={() => {
                  const tmp = [...hosts];
                  tmp.splice(_key, 1);
                  setValue('hospedajes', tmp);
                }}
              >
                <CancelTreasuryIcon width={12} height={12} />
              </Button>
            ),
          }))}
        />
      )}
    </section>
  );
};

export default Hosts;
