/**
 * Solicitudes Pragma SA
 */

import React, { useState } from 'react';

import SelectMonth from '@/components/selects/selectMonth';
import SelectState from '@/components/selects/selectState';
import SelectYear from '@/components/selects/selectYear';
import Button from '@/components/ui/button';
import useConstants from '@/hooks/useConstants';
import { useTranslation } from '@/hooks/useLocalTranslation';
import { Types, getHistoryYears } from '@/services/advances';
import formatDate from '@/utils/formatDate';

import styles from './styles.module.scss';

type TProps = {
  handleSearch: (form: Types.TGetAdvanceListForm, page?: number) => void;
};

const Filter = ({ handleSearch }: TProps) => {
  const [selectYear, setSelectYear] = useState<number | undefined>(-1);
  const [selectMonth, setSelectMonth] = useState<number | undefined>(-1);
  const [selectedState, setSelectedState] = useState<Types.TAdvanceStates | 'ALL'>('ALL');
  const { getStatesAdvances } = useConstants();
  const { t } = useTranslation('selects');

  const states: Types.TAdvanceStates[] = ['RECHAZADA', 'FINALIZADO', 'CANCELADA'];

  return (
    <div className={styles['filter']}>
      <SelectState
        states={getStatesAdvances(['ALL', ...states])}
        onChange={(state) => setSelectedState(state as Types.TAdvanceStates)}
        value={selectedState}
        searchable={false}
      />
      <SelectYear
        years={getHistoryYears}
        onChange={(year) => {
          setSelectYear(year);
          if (year !== undefined) setSelectMonth(-1);
        }}
        value={selectYear}
        setAllOption
        searchable={false}
      />
      <SelectMonth
        onChange={(month) => setSelectMonth(month)}
        value={selectMonth}
        setAllOption
        isDisabled={selectYear === -1}
        searchable={false}
      />
      <Button
        name="ant-history_button-search"
        onClick={() =>
          handleSearch({
            estados: selectedState === 'ALL' ? states : [selectedState],
            ...formatDate(selectMonth, selectYear),
          })
        }
        className={styles['search']}
      >
        {t('button.search')}
      </Button>
    </div>
  );
};

export default Filter;
