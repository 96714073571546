/**
 * Solicitudes Pragma SA
 */

import React, { useContext } from 'react';

import AccountingList from '@/app/advances/accounting/list';
import AccountingReports from '@/app/advances/accounting/reports';
import AdvancesSearch from '@/app/advances/search';
import Tabs from '@/components/ui/tab';
import { sessionContext } from '@/contexts/session';
import { useTranslation } from '@/hooks/useLocalTranslation';

const AccountingPage = () => {
  const { t } = useTranslation('pages');
  const { state } = useContext(sessionContext);
  const accountingRoute = state.menu.data.advances?.find((route) => route.path === '/contabilidad');

  return accountingRoute?.hijos ? (
    <Tabs
      isMain
      subMenus={[
        {
          name: 'listaranticiposcontabilidad',
          title: t('advances.title.subMenu.listaranticiposcontabilidad'),
          element: <AccountingList />,
          notifications: state.notifications.advances.contabilidad?.listaranticiposcontabilidad,
        },
        {
          name: 'reportes',
          title: t('advances.title.subMenu.reportes'),
          element: <AccountingReports />,
          notifications: state.notifications.advances.contabilidad?.reportes,
        },
        {
          name: 'busqueda',
          title: t('advances.title.subMenu.busqueda'),
          element: <AdvancesSearch />,
          notifications: state.notifications.advances.contabilidad?.busqueda,
        },
      ].filter((route) => accountingRoute.hijos.find((subMenu) => subMenu.nombre === route.name))}
    />
  ) : null;
};

export default AccountingPage;
