/**
 * Solicitudes Pragma SA
 */

import React, { useState, useContext } from 'react';

import Button from '@/components/ui/button';
import Select from '@/components/ui/select';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Types, getAccountingReport } from '@/services/advances';
import { createFileBase64, getFileName } from '@/utils/downloadFile';

import i18n from './i18n.json';
import styles from './styles.module.scss';

type TState = { id: Types.TAccountingReportsID; name: string };

const AccountingReports = () => {
  const t = useLocalTranslation(i18n as Resource);
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const [selectedState, setSelectedState] = useState<TState>({
    id: 'POR_LEGALIZAR',
    name: t('states.toLegalize'),
  });

  const states: TState[] = [
    { id: 'POR_LEGALIZAR', name: t('states.toLegalize') },
    { id: 'EN_REVISION', name: t('states.inReview') },
    { id: 'EN_REVISION_APROBADOS', name: t('states.inReviewApproved') },
    { id: 'RECHAZADA', name: t('states.rejected') },
  ];

  const handleDownload = () => {
    setLoading(true);
    getAccountingReport(selectedState.id)
      .finally(() => setLoading(false))
      .then((base64) => {
        createFileBase64(base64, getFileName(selectedState.id)).catch(() =>
          addToast(t('toast.fileCreatingError'), 'danger'),
        );
      })
      .catch((e) => addToast(e.message, 'danger'));
  };

  return (
    <div className={styles['reports']}>
      <Select
        name="ant-accounting-reports_select_legalizations-filter"
        label={t('select.label')}
        className={styles['select']}
        options={states}
        onChange={(value) => value && setSelectedState(value)}
        getOptionLabel={(option) => option.name}
        value={states.find((stateVal) => stateVal.id === selectedState.id)}
      />
      <Button
        name="ant-accounting-reports_button_download"
        className={styles['download']}
        onClick={handleDownload}
      >
        {t('actions.download')}
      </Button>
    </div>
  );
};

export default AccountingReports;
