/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect, useState } from 'react';

import { format, getMonth, getYear, parseISO } from 'date-fns';

import Table from '@/components/table';
import Button from '@/components/ui/button';
import Modal from '@/components/ui/modal';
import { catalogsContext } from '@/contexts/catalogs';
import { utilsContext } from '@/contexts/utils';
import useAdvancesData from '@/hooks/useAdvancesData';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import useConstants from '@/hooks/useConstants';
import useList from '@/hooks/useList';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { generateAdminReport, getAdminReportList, Types } from '@/services/advances';
import { createFileBase64, getFileName } from '@/utils/downloadFile';

import Filter from './filter/index';
import i18n from './i18n.json';
import styles from './styles.module.scss';

enum Params {
  MONTHS_FIX = 1,
}

const AdminReports = () => {
  const { months } = useConstants();
  const { actions } = useContext(utilsContext);
  const { state: catalogState } = useContext(catalogsContext);
  const cancellablePromise = useCancellablePromise();
  const [list, setList, setListLoading] = useList<Types.TAdminReportListItem>();
  const [form, setForm] = useState<Types.TAdminReportFilter>({
    documentoSolicitante: '',
    idNegocio: '',
    idMarca: '',
    fechaInicio: '',
    fechaFinal: '',
  });
  const [confirmModal, setConfirmModal] = useState(false);
  const t = useLocalTranslation(i18n as Resource);
  const { tableHeader } = useAdvancesData();

  const requestList = (query?: Types.TAdminReportFilter, page = 0) => {
    setListLoading(true, !page);
    cancellablePromise(
      getAdminReportList({
        ...(query || form),
        numeroPagina: page,
        totalElementos: 10,
        fechaOrdenAscendente: true,
      })
        .finally(() => setListLoading(false))
        .then((res) => {
          setList(res.dato || [], !!page, res.cantidadPaginas, res.ultimaPagina, page);
        })
        .catch((e) => actions.addToast(e.message, 'danger')),
    );
    if (query) setForm(query);
  };

  const handleDownload = () => {
    actions.setLoading(true);
    generateAdminReport({
      ...form,
      fechaOrdenAscendente: true,
    })
      .then((base64) => {
        createFileBase64(base64, getFileName('reporte_compensacion'))
          .then(() => actions.addToast(t('toast.documentSuccess'), 'success'))
          .catch((e) => actions.addToast(e.message, 'danger'));
      })
      .catch((e) => actions.addToast(e.message, 'danger'))
      .finally(() => actions.setLoading(false));
  };

  useEffect(() => {
    requestList();
  }, []);

  return (
    <div className={styles['admin-reports']}>
      <Filter handleSearch={requestList} />
      <div className={styles['actions']}>
        <Button name="ant-administrator-reports_download" onClick={() => setConfirmModal(true)}>
          {t('actions.download')}
        </Button>
      </div>
      <Table
        name="ant-administrator-reports_table"
        header={[
          { name: 'id', label: tableHeader('id') },
          { name: 'fechaCreacion', label: tableHeader('creationDate') },
          { name: 'documentoSolicitante', label: tableHeader('idUser') },
          { name: 'correoSolicitante', label: tableHeader('applicantEmail') },
          { name: 'montoTotalSolicitado', label: tableHeader('amountRequest') },
          { name: 'montoTotalLegalizado', label: tableHeader('amountLegalized') },
          { name: 'tipoMoneda', label: tableHeader('currency') },
          { name: 'centroDeCostos', label: tableHeader('costCenter') },
          { name: 'nombreAutorizador', label: tableHeader('approverName') },
          { name: 'viaticos', label: tableHeader('travelExpenses') },
        ]}
        data={list.list.map((item) => ({
          _key: item.id,
          ...item,
          fechaCreacion: format(new Date(item.fechaCreacion), 'yyyy-MM-dd'),
          correoSolicitante: item.correoSolicitante.split('@')[0],
          montoTotalSolicitado: `$ ${new Intl.NumberFormat('es-CO').format(
            item.montoTotalSolicitado,
          )}`,
          montoTotalLegalizado: `$ ${new Intl.NumberFormat('es-CO').format(
            item.montoTotalLegalizado,
          )}`,
          tipoMoneda: item.tipoMoneda || '-',
          centroDeCostos: item.centroDeCostos?.join(', ') || '-',
          viaticos: item.viaticos ? t('table.options.yes') : t('table.options.no'),
        }))}
        advanced={{
          ...list,
          handleSearch: (page) => requestList(form, page),
        }}
        emptyLabel={`${t('table.noData')} 🚀`}
      />
      <Modal
        title={t('modal.title')}
        successLabel={t('modal.successLabel')}
        onClose={() => setConfirmModal(false)}
        show={confirmModal}
        onSuccess={() => {
          setConfirmModal(false);
          handleDownload();
        }}
      >
        {t('modal.description')}
        <ul className={styles['modal-filter']}>
          {form.documentoSolicitante && (
            <li>
              <strong>{`${t('modal.filters.document')}: `}</strong>
              {form.documentoSolicitante}
            </li>
          )}
          <li>
            <strong>{`${t('modal.filters.business')}: `}</strong>
            {form.idNegocio !== ''
              ? catalogState.business.find((item) => item.id === form.idNegocio)?.nombre
              : t('modal.filters.all')}
          </li>
          <li>
            <strong>{`${t('modal.filters.client')}: `}</strong>
            {form.idMarca !== ''
              ? catalogState.clients.find((item) => item.id === form.idMarca)?.nombre
              : t('modal.filters.all')}
          </li>
          <li>
            <strong>{`${t('modal.filters.month')}: `}</strong>
            {form.todos
              ? months[getMonth(parseISO(form?.fechaInicio || '')) + Params.MONTHS_FIX]?.name
              : t('modal.filters.all')}
          </li>
          <li>
            <strong>{`${t('modal.filters.year')}: `}</strong>
            {form.fechaFinal ? getYear(parseISO(form.fechaFinal)) : t('modal.filters.all')}
          </li>
        </ul>
      </Modal>
    </div>
  );
};

export default AdminReports;
