/**
 * Solicitudes Pragma SA
 */

import React, { useState } from 'react';

import { MdOutlineFileDownload, MdRemoveRedEye } from 'react-icons/md';

import { CancelTreasuryIcon } from '@/assets/icons/icons';

import UploadButton from '@/components/attachButton';

import Table from '@/components/table';

import Button from '@/components/ui/button';

import TextField from '@/components/ui/textField';

import useAdvancesData from '@/hooks/useAdvancesData';

import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';

import { TElectronicInvoice } from '@/services/advances/types';

import downloadFile from '@/utils/downloadFile';

import i18n from './i18n.json';

import styles from './styles.module.scss';

type TProps = {
  tipoMoneda?: string;
  data: TElectronicInvoice[];
  setData?: (value: TElectronicInvoice[]) => void;
  readOnly?: boolean;
  showTitle?: boolean;
};

const ElectronicInvoices = ({ tipoMoneda, data, setData, readOnly, showTitle }: TProps) => {
  const t = useLocalTranslation(i18n as Resource);

  const [idInvoice, setIdInvoice] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [invoiceName, setInvoiceName] = useState('');
  const [invoiceBill, setInvoiceBill] = useState('');
  const [eDescription, setEDescription] = useState('');
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [invoiceValue, setInvoiceValue] = useState<number>();
  const { tableHeader } = useAdvancesData();

  const reset = () => {
    setIdInvoice('');
    setCompanyName('');
    setInvoiceValue(undefined);
    setInvoiceName('');
    setInvoiceBill('');
    setEDescription('');
  };

  const convertBase64ToBlob = (base64: string) => {
    const parts = base64.split(';base64,');
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: parts[0].split(':')[1] });
  };

  const viewFile = (fileURL: string) => {
    const splitFile = fileURL.split('.');
    if (splitFile[splitFile.length - 1] === 'pdf')
      window.open(`https://docs.google.com/viewerng/viewer?url=${fileURL}`);
    else {
      window.open('', '_blank')?.document.write(`
        <html>
          <head>
          </head>
          <body>
            <img src="${fileURL}" alt="${fileURL}" maxheight="100%" maxwidth="100%">
          </body>
        </html>
      `);
    }
  };

  return (
    <div className={styles['details']}>
      {showTitle || (readOnly && data?.length) ? <h3 className="h3">{t('title')}</h3> : ''}
      {!readOnly && (
        <>
          <div className={styles['fields']}>
            <TextField
              label={t('information.idInvoice.label')}
              placeholder={t('information.idInvoice.placeholder')}
              onChange={({ currentTarget }) => setIdInvoice(currentTarget.value)}
              value={idInvoice}
            />
            <TextField
              label={t('information.companyName.label')}
              placeholder={t('information.companyName.placeholder')}
              onChange={({ currentTarget }) => setCompanyName(currentTarget.value)}
              value={companyName}
            />
            <TextField
              label={t('information.invoiceValue.label')}
              placeholder={t('information.invoiceValue.placeholder')}
              onChange={({ currentTarget }) => setInvoiceValue(Number(currentTarget.value))}
              value={invoiceValue === undefined ? '' : invoiceValue}
              leading="$"
              trailing={tipoMoneda}
              keyPattern={/^[0-9]*\.?[0-9]*$/}
            />
            <TextField
              label={t('information.eDescription.label')}
              placeholder={t('information.eDescription.placeholder')}
              onChange={({ currentTarget }) => setEDescription(currentTarget.value)}
              value={eDescription}
            />
          </div>
          <div className={styles['actions']}>
            <Button
              name="ant-legalize-add-invoice"
              disabled={!companyName || !invoiceValue || !eDescription}
              onClick={() => {
                if (setData && companyName && invoiceValue && eDescription) {
                  setData([
                    ...data,
                    {
                      idFacturaElectronica: idInvoice,
                      nombreEstablecimiento: companyName,
                      valorFactura: invoiceValue,
                      descripcionFactura: eDescription,
                      fileNames: '',
                      soportes: [
                        {
                          nombreSoporte: invoiceName,
                          descripcionFactura: null,
                          archivoSoporte: invoiceBill,
                        },
                      ],
                    },
                  ]);
                  reset();
                }
              }}
            >
              {t('actions.add')}
            </Button>
          </div>
        </>
      )}
      {data?.length ? (
        <Table
          name="ant-legalize_bills_table"
          header={
            !readOnly
              ? [
                  { name: 'idFacturaElectronica', label: tableHeader('idInvoice') },
                  { name: 'nombreEstablecimiento', label: tableHeader('companyName') },
                  { name: 'valorFactura', label: tableHeader('invoiceValue') },
                  { name: 'fileNames', label: tableHeader('files') },
                  { name: 'descripcionFactura', label: tableHeader('eDescription') },
                  { name: 'actions', label: tableHeader('action') },
                ]
              : [
                  { name: 'idFacturaElectronica', label: tableHeader('idInvoice') },
                  { name: 'nombreEstablecimiento', label: tableHeader('companyName') },
                  { name: 'valorFactura', label: tableHeader('invoiceValue') },
                  { name: 'fileNames', label: tableHeader('files') },
                  { name: 'descripcionFactura', label: tableHeader('eDescription') },
                  { name: 'actions', label: tableHeader('action') },
                ]
          }
          data={data.map((item, _key) => ({
            _key,
            idFacturaElectronica: item.idFacturaElectronica,
            nombreEstablecimiento: item.nombreEstablecimiento,
            valorFactura: item.valorFactura,
            descripcionFactura: item.descripcionFactura,
            fileNames: (
              <div>
                {item.soportes[0]?.nombreSoporte || ''}
                {!readOnly && (
                  <UploadButton
                    className={styles['upload']}
                    maxSize={1000000}
                    fileTypes={['png', 'jpge', 'jpg', 'jpeg', 'pdf']}
                    onChange={(file, base64) => {
                      {
                        item.soportes[0].nombreSoporte = file.name;
                      }
                      {
                        item.soportes[0].archivoSoporte = base64 as string;
                      }
                      {
                        item.descripcionFactura as string;
                      }
                    }}
                  />
                )}
              </div>
            ),
            fileDescription: <textarea className={styles['fileDescription']} />,
            actions: (
              <div className={styles['actions']}>
                <Button
                  name="ant-legalization-supports-view"
                  theme="secondary"
                  className={styles['cancel']}
                  onClick={() => {
                    readOnly
                      ? viewFile(item.soportes[0].archivoSoporte)
                      : window.open(
                          URL.createObjectURL(convertBase64ToBlob(item.soportes[0].archivoSoporte)),
                        );
                  }}
                >
                  <MdRemoveRedEye />
                </Button>
                {readOnly ? (
                  <Button
                    name="ant-legalization-review_button-download-support"
                    className={styles['cancel']}
                    theme="secondary"
                    onClick={() => downloadFile(item.soportes[0].archivoSoporte)}
                  >
                    <MdOutlineFileDownload />
                  </Button>
                ) : (
                  <Button
                    name="ant-legalization-supports_button-delete"
                    theme="error"
                    className={styles['cancel']}
                    onClick={() => {
                      const tmpList = [...data];
                      tmpList.splice(_key, 1);
                      const tmpFileNames = [...fileNames];
                      tmpFileNames.splice(_key, 1);
                      if (setData) setData(tmpList);
                      setFileNames(tmpFileNames);
                    }}
                  >
                    <CancelTreasuryIcon width={12} height={12} />
                  </Button>
                )}
              </div>
            ),
          }))}
          emptyLabel={`${t('noData')} 🚀`}
        />
      ) : null}
      ,
    </div>
  );
};

export default ElectronicInvoices;
