/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect, useState } from 'react';

import { OutlineUpCircleDisableIcon, OutlineDownCircleDisableIcon } from '@/assets/icons/icons';
import Item from '@/components/timeline/item';
import Button from '@/components/ui/button';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { getAdvanceHistory, Types } from '@/services/advances';

import i18n from './i18n.json';
import styles from './styles.module.scss';

enum Params {
  ACTION_POSITION = 0,
}

const Timeline = ({ data }: { data: Types.TAdvanceItem }) => {
  const t = useLocalTranslation(i18n as Resource);
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const [show, setShow] = useState(false);
  const [actions, setActions] = useState<Types.TAdvanceHistory[] | null>(null);

  useEffect(() => {
    if (!actions && show) {
      setLoading(true);
      getAdvanceHistory(data.id)
        .finally(() => setLoading(false))
        .then((res) => setActions(res))
        .catch((e) => addToast(e.message, 'danger'));
    }
  }, [show]);

  const action = actions?.filter((status) => status?.accion !== 'RECORDATORIO') || [];

  return (
    <section className={styles['timeline']}>
      <Button
        name="comp-timeline_button-show"
        className={`${styles['show']} ${show ? styles['visible'] : ''}`}
        onClick={() => setShow(!show)}
        trailing={
          show ? (
            <div>
              <div>
                <OutlineUpCircleDisableIcon width={20} height={20} className={styles['lighting']} />
                <span className={styles['shadow']}></span>
              </div>
              <OutlineUpCircleDisableIcon width={20} height={20} className={styles['dark']} />
            </div>
          ) : (
            <div>
              <div>
                <OutlineDownCircleDisableIcon
                  width={20}
                  height={20}
                  className={styles['lighting']}
                />
                <span className={styles['shadow']}></span>
              </div>
              <OutlineDownCircleDisableIcon width={20} height={20} className={styles['dark']} />
            </div>
          )
        }
      >
        {t('title')}
      </Button>
      {show && !!action.length && data.montoTotal > 0 && (
        <div className={styles['items']}>
          {action[Params.ACTION_POSITION].accion === 'CREAR' && (
            <>
              <Item text={t('items.created')} theme="active" nextLine="active" />
              <Item
                text={t('items.approver')}
                theme="semiactive"
                line="active"
                nextLine="inactive"
              />
              <Item text={t('items.management')} line="inactive" nextLine="inactive" />
              <Item text={t('items.legalized')} line="inactive" nextLine="inactive" />
              <Item text={t('items.approver')} line="inactive" nextLine="inactive" />
              <Item text={t('items.accounting')} line="inactive" />
            </>
          )}
          {(action[Params.ACTION_POSITION].accion === 'APROBAR' ||
            action[Params.ACTION_POSITION].accion === 'ASIGNAR_FACILITADOR' ||
            action[Params.ACTION_POSITION].accion === 'REASIGNAR_FACILITADOR') && (
            <>
              <Item text={t('items.created')} theme="active" nextLine="active" />
              <Item text={t('items.approver')} theme="active" line="active" nextLine="active" />
              <Item
                text={t('items.management')}
                line="active"
                theme="semiactive"
                nextLine="inactive"
              />
              <Item text={t('items.legalized')} line="inactive" nextLine="inactive" />
              <Item text={t('items.approver')} line="inactive" nextLine="inactive" />
              <Item text={t('items.accounting')} line="inactive" />
            </>
          )}
          {(action[Params.ACTION_POSITION].accion === 'GESTIONAR' ||
            action[Params.ACTION_POSITION].accion === 'PAGAR') && (
            <>
              <Item text={t('items.created')} theme="active" nextLine="active" />
              <Item text={t('items.approver')} theme="active" line="active" nextLine="active" />
              <Item text={t('items.management')} theme="active" line="active" nextLine="active" />
              <Item
                text={t('items.legalized')}
                theme="semiactive"
                line="active"
                nextLine="inactive"
              />
              <Item text={t('items.approver')} line="inactive" nextLine="inactive" />
              <Item text={t('items.accounting')} line="inactive" />
            </>
          )}
          {action[Params.ACTION_POSITION].accion === 'LEGALIZAR' && (
            <>
              <Item text={t('items.created')} theme="active" nextLine="active" />
              <Item text={t('items.approver')} theme="active" line="active" nextLine="active" />
              <Item text={t('items.management')} theme="active" line="active" nextLine="active" />
              <Item text={t('items.legalized')} theme="active" line="active" nextLine="active" />
              <Item
                text={t('items.approver')}
                theme="semiactive"
                line="active"
                nextLine="inactive"
              />
              <Item text={t('items.accounting')} line="inactive" />
            </>
          )}
          {action[Params.ACTION_POSITION].accion === 'APROBAR_LEGALIZACION' && (
            <>
              <Item text={t('items.created')} theme="active" nextLine="active" />
              <Item text={t('items.approver')} theme="active" line="active" nextLine="active" />
              <Item text={t('items.management')} theme="active" line="active" nextLine="active" />
              <Item text={t('items.legalized')} theme="active" line="active" nextLine="active" />
              <Item text={t('items.approver')} theme="active" line="active" nextLine="active" />
              <Item text={t('items.accounting')} theme="semiactive" line="active" />
            </>
          )}
          {action[Params.ACTION_POSITION].accion === 'RECHAZAR_LEGALIZACION' && (
            <>
              <Item text={t('items.created')} theme="active" nextLine="active" />
              <Item text={t('items.approver')} theme="active" line="active" nextLine="active" />
              <Item text={t('items.management')} theme="active" line="active" nextLine="active" />
              <Item text={t('items.legalized')} theme="active" line="active" nextLine="error" />
              <Item text={t('items.approver')} theme="error" line="error" nextLine="inactive" />
              <Item text={t('items.accounting')} line="inactive" />
            </>
          )}
          {action[Params.ACTION_POSITION].accion === 'FINALIZAR' && (
            <>
              <Item text={t('items.created')} theme="active" nextLine="active" />
              <Item text={t('items.approver')} theme="active" line="active" nextLine="active" />
              <Item text={t('items.management')} theme="active" line="active" nextLine="active" />
              <Item text={t('items.legalized')} theme="active" line="active" nextLine="active" />
              <Item text={t('items.approver')} theme="active" line="active" nextLine="active" />
              <Item text={t('items.accounting')} theme="active" line="active" />
            </>
          )}
          {action[Params.ACTION_POSITION].accion === 'RECHAZAR_CONTABILIDAD' && (
            <>
              <Item text={t('items.created')} theme="active" nextLine="active" />
              <Item text={t('items.approver')} theme="active" line="active" nextLine="active" />
              <Item text={t('items.management')} theme="active" line="active" nextLine="active" />
              <Item text={t('items.legalized')} theme="active" line="active" nextLine="active" />
              <Item text={t('items.approver')} theme="active" line="active" nextLine="error" />
              <Item text={t('items.accounting')} theme="error" line="error" />
            </>
          )}
        </div>
      )}
      {show && !!action.length && data.montoTotal === 0 && (data.tiquetes || data.hospedajes) && (
        <div className={styles['items']}>
          {action[Params.ACTION_POSITION].accion === 'CREAR' && (
            <>
              <Item text={t('items.created')} theme="active" nextLine="active" />
              <Item
                text={t('items.approver')}
                theme="semiactive"
                line="active"
                nextLine="inactive"
              />
              <Item text={t('items.management')} line="inactive" />
            </>
          )}
          {(action[Params.ACTION_POSITION].accion === 'APROBAR' ||
            action[Params.ACTION_POSITION].accion === 'ASIGNAR_FACILITADOR' ||
            action[Params.ACTION_POSITION].accion === 'REASIGNAR_FACILITADOR') && (
            <>
              <Item text={t('items.created')} theme="active" nextLine="active" />
              <Item text={t('items.approver')} theme="active" line="active" nextLine="active" />
              <Item text={t('items.management')} line="active" theme="semiactive" />
            </>
          )}
          {(action[Params.ACTION_POSITION].accion === 'GESTIONAR' ||
            action[Params.ACTION_POSITION].accion === 'PAGAR') && (
            <>
              <Item text={t('items.created')} theme="active" nextLine="active" />
              <Item text={t('items.approver')} theme="active" line="active" nextLine="active" />
              <Item text={t('items.management')} theme="active" line="active" />
            </>
          )}
        </div>
      )}
    </section>
  );
};

export default Timeline;
