/**
 * Solicitudes Pragma SA
 */

import React, { forwardRef, useId, Ref } from 'react';

import Select from '@/components/ui/select';
import { TAdvancesStates, TVacationsStates } from '@/hooks/useConstants';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Types as AdvTypes } from '@/services/advances';
import { Types as VacTypes } from '@/services/vacations';

import i18n from './i18n.json';
import styles from './styles.module.scss';

type TProps = {
  name?: string;
  states: (TVacationsStates | TAdvancesStates)[];
  onChange?: (value?: VacTypes.TVacationStates | AdvTypes.TAdvanceStates | 'ALL') => void;
  isClearable?: boolean;
  value?: VacTypes.TVacationStates | AdvTypes.TAdvanceStates | 'ALL';
  className?: string;
  error?: string;
  isDisabled?: boolean;
  searchable?: boolean;
};

const SelectState = (
  {
    name,
    states,
    onChange,
    isClearable,
    value,
    className,
    error,
    isDisabled,
    searchable = true,
  }: TProps,
  ref: Ref<HTMLLabelElement>,
) => {
  const t = useLocalTranslation(i18n as Resource, 'selects');
  const uName = useId();

  return (
    <Select
      isSearchable={searchable}
      labelRef={ref}
      name={name || uName}
      label={t('selectState.label')}
      placeholder={t('selectState.placeholder')}
      noOptionsMessage={() => t('selectState.noData')}
      error={error}
      className={`${styles['state']} ${className || ''}`}
      options={states}
      onChange={(option, actionMeta) => {
        if (actionMeta.action === 'clear') onChange && onChange(undefined);
        else if (option) onChange && onChange(option.id);
      }}
      getOptionLabel={(option) => option.name}
      value={value ? states.find((option) => option.id === value) : null}
      isClearable={isClearable}
      isDisabled={isDisabled}
    />
  );
};

export default forwardRef(SelectState);
