/**
 * Solicitudes Pragma SA
 */

import { CSSProperties } from 'react';

import type { TData } from '@/components/card';
import useFormatDate from '@/hooks/useFormatDate';
import useLocalTranslation from '@/hooks/useLocalTranslation';
import type { Types } from '@/services/vacations';

const i18n = {
  es: {
    table: {
      header: {
        numberRequest: 'No. Solicitud',
        identification: 'Cédula',
        requestType: 'Tipo solicitud',
        userName: 'Nombre',
        requester: 'Solicitante',
        authorizer: 'Autorizador',
        startDate: 'Fecha de inicio',
        returnDate: 'Fecha de regreso',
        numberDays: 'Número de dias',
        state: 'Estado',
        paidDate: 'Fecha de pago',
        documentType: 'Tipo de documento',
        typeContract: 'Tipo de contrato',
        lastDate: 'Última fecha de contrato',
        daysWon: 'Días ganados',
        daysTaken: 'Días Tomados',
        daysSeparated: 'Días apartados',
        daysAvailable: 'Días disponibles',
      },
    },
    card: {
      header: {
        tag: 'Vacaciones en dinero',
      },
      info: {
        departure: 'Salida',
        arrival: 'Llegada',
        country: 'País',
        business: 'Área',
        requestedDays: 'Días solicitados',
        notes: 'Notas',
        extraDays: 'Días adicionales',
        approver: 'Aprobador',
        payDate: 'Fecha pago',
        quantityDays: 'Cantidad de días',
      },
    },
    state: 'Estado',
  },
  en: {
    table: {
      header: {
        numberRequest: 'Request No.',
        identification: 'Identification',
        requestType: 'Request type',
        userName: 'Name',
        requester: 'Requester',
        authorizer: 'Authorizer',
        startDate: 'Start date',
        returnDate: 'Return date',
        numberDays: 'Number of days',
        state: 'State',
        paidDate: 'Paid date',
        documentType: 'Document type',
        typeContract: 'Type of contract',
        lastDate: 'Last contract day ',
        daysWon: 'Days won',
        daysTaken: 'Days taken',
        daysSeparated: 'Days set aside',
        daysAvailable: 'Days available',
      },
    },
    card: {
      header: {
        tag: 'Money Vacation',
      },
      info: {
        departure: 'Departure',
        arrival: 'Arrival',
        country: 'Country',
        business: 'Business',
        requestedDays: 'Requested days',
        notes: 'Notes',
        extraDays: 'Additional days',
        approver: 'Approver',
        payDate: 'Pay date',
        quantityDays: 'Days',
      },
    },
    state: 'State',
  },
};

const useVacationsData = () => {
  const t = useLocalTranslation(i18n, 'vacations');
  const formatDate = useFormatDate();

  return {
    cardInfo: (data: Types.TVacationItem): TData[] => [
      ...(!data.fechaPago
        ? [
            {
              label: t('card.info.departure'),
              value: formatDate(new Date(`${data.fechaInicio}T00:00`)),
              width: '140px',
            },
            ...(data.fechaLlegadaTrabajo
              ? [
                  {
                    label: t('card.info.arrival'),
                    value: formatDate(new Date(`${data.fechaLlegadaTrabajo}T00:00`) || null),
                    width: '140px',
                  },
                ]
              : []),
            {
              label: t('card.info.country'),
              value: data.paisNombre,
              width: '100px',
            },
            {
              label: t('card.info.requestedDays'),
              value: data.cantidadDias,
            },
            {
              label: t('card.info.business'),
              value: data.vicepresidenciaNombre,
              width: '180px',
            },
            {
              label: t('card.info.approver'),
              value: data.aprobadorNombre,
              width: '200px',
            },
            {
              label: t('card.info.notes'),
              value: data.observaciones || '-',
              width: '100%',
              valueStyle: { whiteSpace: 'pre-wrap' } as CSSProperties,
            },
            ...(data.diasAdicionales
              ? [
                  {
                    label: t('card.info.extraDays'),
                    value: data.cantidadDiasAdicionales,
                  },
                ]
              : []),
          ]
        : [
            {
              label: t('card.info.payDate'),
              value: formatDate(new Date(`${data.fechaPago}T00:00`)),
              width: '140px',
            },
            {
              label: t('card.info.approver'),
              value: data.aprobadorNombre,
              width: '200px',
            },
            {
              label: t('card.info.quantityDays'),
              value: data.cantidadDias,
            },
          ]),
    ],
    tableHeader: (name: keyof typeof i18n.es.table.header) => t(`table.header.${name}`),
  };
};

export default useVacationsData;
