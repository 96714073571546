/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useState } from 'react';

import SelectMonth from '@/components/selects/selectMonth';
import SelectState from '@/components/selects/selectState';
import SelectYear from '@/components/selects/selectYear';
import Button from '@/components/ui/button';
import { sessionContext } from '@/contexts/session';
import useConstants from '@/hooks/useConstants';
import { useTranslation } from '@/hooks/useLocalTranslation';
import { Types, getHistoryYears } from '@/services/vacations';
import formatDate from '@/utils/formatDate';

import styles from './styles.module.scss';

type TProps = {
  handleSearch: (form: Types.TGetVacationsForm, page?: number) => void;
};

const Filter = ({ handleSearch }: TProps) => {
  const {
    state: { data },
  } = useContext(sessionContext);
  const [selectYear, setSelectYear] = useState<number | undefined>(-1);
  const [selectMonth, setSelectMonth] = useState<number | undefined>(-1);
  const [selectedState, setSelectedState] = useState<Types.TVacationStates | 'ALL'>('ALL');
  const { getStatesVacations } = useConstants();
  const { t } = useTranslation('selects');

  const states: Types.TVacationStates[] = ['APROBADA', 'LIQUIDADA', 'RECHAZADA', 'CANCELADA'];

  return (
    <div className={styles['filter']}>
      <SelectState
        states={getStatesVacations(['ALL', ...states])}
        onChange={(state) => setSelectedState(state as Types.TVacationStates)}
        value={selectedState}
        searchable={false}
      />
      <SelectYear
        years={async () => getHistoryYears(data?.correoEmpresarial || '')}
        onChange={(year) => {
          setSelectYear(year);
          if (year !== undefined) setSelectMonth(-1);
        }}
        value={selectYear}
        setAllOption
        searchable={false}
      />
      <SelectMonth
        onChange={(month) => setSelectMonth(month)}
        value={selectMonth}
        setAllOption
        isDisabled={selectYear === -1}
        searchable={false}
      />
      <Button
        name="vac-history_button-search"
        onClick={() =>
          handleSearch({
            estados: selectedState === 'ALL' ? states : [selectedState],
            ...formatDate(selectMonth, selectYear),
          })
        }
        className={styles['search']}
      >
        {t('button.search')}
      </Button>
    </div>
  );
};

export default Filter;
