/**
 * Solicitudes Pragma SA
 */

import React, { useEffect, useContext } from 'react';

import Card from '@/components/card';
import InfiniteScrollList from '@/components/infiniteScroll';
import StateLabel from '@/components/stateLabel';
import Button from '@/components/ui/button';
import { utilsContext } from '@/contexts/utils';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import useList from '@/hooks/useList';
import useLocalTranslation, { Resource, useTranslation } from '@/hooks/useLocalTranslation';
import useVacationsData from '@/hooks/useVacationsData';
import { Types, getVacations } from '@/services/vacations';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const PendingOwn = () => {
  const {
    actions: { addToast },
  } = useContext(utilsContext);
  const cancellablePromise = useCancellablePromise();
  const [list, setList, setListLoading] = useList<Types.TVacationItem>();
  const { t } = useTranslation('vacations');
  const l = useLocalTranslation(i18n as Resource);
  const { cardInfo } = useVacationsData();

  const requestList = (page = 0) => {
    setListLoading(true, !page);
    cancellablePromise(
      getVacations({
        estados: ['PENDIENTE'],
        numeroPagina: page,
        totalElementos: 5,
        solicitante: true,
        fechaCreacionAscendente: false,
      })
        .finally(() => setListLoading(false))
        .then((res) => {
          setList(res.dato || [], !!page, res.cantidadPaginas, res.ultimaPagina, page);
        })
        .catch((e) => addToast(e.message, 'danger')),
    );
  };

  useEffect(() => {
    requestList();
  }, []);

  return (
    <>
      <div className={styles['refresh']}>
        <Button
          className={styles['button']}
          name="ant-search_button_search"
          onClick={() => requestList()}
        >
          {l('actionsRefresh.refresh')}
        </Button>
      </div>
      <InfiniteScrollList list={list} handleNext={requestList}>
        {list.list.map((item) => (
          <Card
            key={item.id}
            _visible={item._visible}
            header={{
              id: item.id,
              photo: item.fotografiaUsuarioSolicitante?.contenido,
              name: item.usuarioNombre,
              email: item.usuarioCorreo,
              date: new Date(item.fechaCreacion),
              tag: item.fechaPago && t('card.header.tag'),
            }}
            data={cardInfo(item)}
            state={
              <div className={styles['state']}>
                {t('state')}
                <StateLabel state={item.estado} />
              </div>
            }
          />
        ))}
      </InfiniteScrollList>
    </>
  );
};

export default PendingOwn;
