/**
 * Solicitudes Pragma SA
 */

import React, { Ref } from 'react';

import ReactSelect, { Props, components } from 'react-select';

import { ChevronDownIcon } from '@/assets/icons/icons';

import styles from './styles.module.scss';

import './select.scss';

type TProps = {
  name: string;
  label?: string;
  error?: string;
  helper?: string;
  labelRef?: Ref<HTMLLabelElement>;
  noSort?: boolean;
};

const Select = <Option, IsMulti extends boolean = false>(
  props: Props<Option, IsMulti> & TProps,
) => {
  const {
    name,
    label,
    error,
    helper,
    isLoading,
    className,
    isDisabled,
    options,
    labelRef,
    noSort,
    ...rest
  } = props;

  if (options && !noSort && Array.isArray(options) && options[0]?.nombre) {
    // TODO: Poder ordernar de acuerdo a la propiedad que uno le pase y no siempre con "nombre"
    options.sort((a: { nombre: string }, b: { nombre: string }) => {
      const { nombre: nameFirstPerson } = a;
      const { nombre: nameSecondPerson } = b;
      if (nameFirstPerson) {
        return nameFirstPerson.localeCompare(nameSecondPerson);
      }

      return -1;
    });
  }

  const ChevronDown = () => {
    return <ChevronDownIcon width={15} height={15} />;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronDown />
      </components.DropdownIndicator>
    );
  };

  return (
    <label
      ref={labelRef}
      htmlFor={name}
      className={`react-select ${styles.select} ${className || ''}`}
      data-label={label ? '' : undefined}
      data-disabled={isDisabled ? '' : undefined}
      data-error={error ? '' : undefined}
      data-loading={isLoading ? '' : undefined}
    >
      {label && label}
      <ReactSelect
        instanceId={name}
        isDisabled={isDisabled}
        classNamePrefix="react-select"
        options={options}
        isLoading={isLoading}
        loadingMessage={() => '...'}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator,
        }}
        {...rest}
      />
      {error ? (
        <div className={styles.helper}>{error}</div>
      ) : (
        helper && <div className={styles.helper}>{helper}</div>
      )}
    </label>
  );
};

export default Select;
