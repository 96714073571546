/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import { useMatch } from 'react-router-dom';

import RejectURL from '@/app/vacations/paramsDeny';

const RejectURLPage = () => {
  const match = useMatch('/reject/:id');

  return <RejectURL id={Number(match?.params.id) || null} />;
};

export default RejectURLPage;
