/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect, useState } from 'react';

import { useLocation, Link } from 'react-router-dom';

import { sessionContext } from '@/contexts/session';
import { utilsContext } from '@/contexts/utils';
import { useTranslation } from '@/hooks/useLocalTranslation';
import { PRAGMA } from '@/pages';
import { TIcono } from '@/services/session/types';

import styles from './styles.module.scss';

type TLink = {
  id: string;
  path: string;
  active: boolean;
  icon: TIcono[];
  titlePath: string;
  totalNot?: number;
};

const Menu = () => {
  const { t } = useTranslation('pages');
  const { state } = useContext(sessionContext);
  const [items, setItems] = useState<TLink[]>([]);
  const [sectionTitle, setSectionTitle] = useState('');
  const location = useLocation();
  const {
    actions: { setCurrentTab },
  } = useContext(utilsContext);
  const [hover, setHover] = useState<boolean>(false);

  useEffect(() => {
    PRAGMA.forEach((app) => {
      if (app.routes.find((page) => page.path?.split('/')[1] === location.pathname.split('/')[1])) {
        setSectionTitle(app.name);
        const itemsMenu = state.menu.data[app.name]?.map((route) => ({
          id: route.id,
          path: route.path,
          active: location.pathname === route.path,
          icon: route.iconos,
          titlePath: `${app.name}.title.menu.${route.nombre}`,
          totalNot: state.notifications[app.name][route.nombre]?.total,
        }));
        setItems(itemsMenu);
      }
    });
  }, [state.menu.data, location, state.notifications, state.language]);

  return (
    <div className={styles.menu}>
      <div className={`${styles.section} ${styles.option} ${styles.section1}`}>
        {t(`${sectionTitle}.title.abbr`)}
      </div>
      <div className={styles.options}>
        {PRAGMA.map((section) =>
          Object.keys(state.menu.data).find(
            (key) => key === section.name && state.menu.data[key].length > 0,
          ) ? (
            <Link
              key={section.name}
              to={section.index}
              className={`${styles.section} 
                      ${
                        section.routes.find((page) => page.path === location.pathname)
                          ? `${styles.selected}`
                          : ''
                      }`}
            >
              {t(`${section.name}.title.abbr`)}
            </Link>
          ) : null,
        )}
      </div>
      {items.map((link, index) => (
        <Link
          key={link.id}
          to={link.path}
          className={`${styles.link} ${link.active && styles['active-link']} ${
            hover && link.active && styles['active-custom']
          }`}
          onClick={() => {
            setCurrentTab(0);
            setHover(false);
          }}
          onMouseOver={() => !link.active && setHover(true)}
          onMouseLeave={() => !link.active && setHover(false)}
        >
          <div className={`${styles.item} ${items.length - 1 === index && styles['last-item']}`}>
            <span className={link.active ? styles['lighting'] : styles['lighting-off']}></span>
            {link.active ? (
              <figure className={styles['icon-active']}>
                <img src={link.icon[0]?.link} alt="." />
              </figure>
            ) : (
              <figure className={styles['icon-disabled']}>
                <img src={link.icon[1]?.link} alt="." />
              </figure>
            )}
            {t(link.titlePath)}
            {!!link.totalNot && link.totalNot > 0 && (
              <span className={styles.notifications}>{link.totalNot}</span>
            )}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default Menu;
