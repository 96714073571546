/**
 * Solicitudes Pragma SA
 */

import React, { useState, useEffect, useContext } from 'react';

import Card from '@/components/card';
import InfiniteScrollList from '@/components/infiniteScroll';
import StateLabel from '@/components/stateLabel';
import { utilsContext } from '@/contexts/utils';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import useList from '@/hooks/useList';
import { useTranslation } from '@/hooks/useLocalTranslation';
import useVacationsData from '@/hooks/useVacationsData';
import { Types, getVacations } from '@/services/vacations';

import Filter from './filter';
import styles from './styles.module.scss';

const VacationHistoryContent = () => {
  const {
    actions: { addToast },
  } = useContext(utilsContext);
  const cancellablePromise = useCancellablePromise();
  const [list, setList, setListLoading] = useList<Types.TVacationItem>();
  const [form, setForm] = useState<Types.TGetVacationsForm>({
    estados: ['APROBADA', 'LIQUIDADA', 'RECHAZADA'],
    fechaInicio: '',
    fechaFinal: '',
  });
  const { t } = useTranslation('vacations');
  const { cardInfo } = useVacationsData();

  const requestList = (query?: Types.TGetVacationsForm, page = 0) => {
    setListLoading(true, !page);
    cancellablePromise(
      getVacations({
        ...(query || form),
        numeroPagina: page,
        totalElementos: 5,
        solicitante: false,
        fechaCreacionAscendente: true,
      })
        .finally(() => setListLoading(false))
        .then((res) => {
          setList(res.dato || [], !!page, res.cantidadPaginas, res.ultimaPagina, page);
        })
        .catch((e) => addToast(e.message, 'danger')),
    );
    if (query) setForm(query);
  };

  useEffect(() => {
    requestList();
  }, []);

  return (
    <section className={styles['history']}>
      <Filter handleSearch={requestList} />
      <InfiniteScrollList list={list} handleNext={(page) => requestList(form, page)}>
        {list.list.map((item) => (
          <Card
            key={item.id}
            _visible={item._visible}
            header={{
              id: item.id,
              photo: item.fotografiaUsuarioSolicitante?.contenido,
              name: item.usuarioNombre,
              email: item.usuarioCorreo,
              date: new Date(item.fechaCreacion),
              tag: item.fechaPago && t('card.header.tag'),
            }}
            data={cardInfo(item)}
            state={
              <div className={styles['state']}>
                {t('state')}
                <StateLabel state={item.estado} />
              </div>
            }
          />
        ))}
      </InfiniteScrollList>
    </section>
  );
};

export default VacationHistoryContent;
