import React, { useRef, useState } from 'react';

import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { WhatsAppIcon } from '@/assets/icons/icons';
import styles from './styles.module.scss';

import i18n from './i18n.json';

type TProps = {
  phoneNumberPragma: string;
};

const WhatsAppButton = ({ phoneNumberPragma }: TProps) => {
  const t = useLocalTranslation(i18n as Resource);
  return (
    <>
      <div className={styles['box']}>
        <div className={styles['wpp-buton']}>
          <a
            href={`https://wa.me/57${phoneNumberPragma}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <WhatsAppIcon width={35} height={35} />
          </a>
        </div>
        <div className={styles['box__info']}>
          {t('tooltipLink')}
          <div className={styles['box__arrow']}></div>
        </div>
      </div>
    </>
  );
};

export default WhatsAppButton;
