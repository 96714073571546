/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import styles from './styles.module.scss';

type TProps = {
  isRow?: boolean;
  repetitions?: number;
  height?: number;
};

const Shimmer = ({ isRow = false, repetitions = 1, height = 150 }: TProps) =>
  isRow ? (
    <>
      {Array.from({ length: repetitions }, (_, i) => (
        <tr key={i} className={styles.row}>
          <td colSpan={100}>
            <div className={styles.shimmer} style={{ height: 30 }} />
          </td>
        </tr>
      ))}
    </>
  ) : (
    <div className={styles.shimmers}>
      {Array.from({ length: repetitions }, (_, i) => (
        <div key={i} className={styles.shimmer} style={{ height }} />
      ))}
    </div>
  );

export default React.memo(Shimmer);
