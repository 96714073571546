/* eslint-disable import/no-duplicates */
/**
 * Solicitudes Pragma SA
 */

import { useContext } from 'react';

import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';

import { sessionContext } from '@/contexts/session';

const useFormatDate = () => {
  const {
    state: { language },
  } = useContext(sessionContext);

  return (date: Date, pattern = 'PPP') =>
    format(date, pattern, language === 'es' ? { locale: esLocale } : undefined);
};

export default useFormatDate;
