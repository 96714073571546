/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import styles from './styles.module.scss';

type TProps = {
  children: React.ReactNode;
  label: string;
};

const Tooltip = ({ children, label }: TProps) => {
  return (
    <div className={`${styles['tooltip']} ${styles['top']}`}>
      {children}
      <span className={styles['tooltip-label']}>{label}</span>
    </div>
  );
};

export default Tooltip;
