/**
 * Solicitudes Pragma SA
 */

import React, { useState, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import DetailsCostCenter from '@/app/advances/details/costCenter';
import DetailsInfo from '@/app/advances/details/info';
import ElectronicInvoices from '@/app/advances/legalization/electronicInvoices';
import user from '@/assets/images/user.png';
import ApproveModal, { TApprove } from '@/components/approveModal';
import DenyModal, { TDeny } from '@/components/denyModal';
import Salute from '@/components/salute';
import Supports from '@/components/supports';
import Button from '@/components/ui/button';
import Checkbox from '@/components/ui/checkbox';
import Modal from '@/components/ui/modal';
import { sessionContext } from '@/contexts/session';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Apps } from '@/pages';
import type { Types } from '@/services/advances';
import endpoints from '@/services/endpoints';

import LegalizationDetails from './details';
import i18n from './i18n.json';
import styles from './styles.module.scss';

type TProps = {
  data: Types.TAdvanceDetailsExt | null;
  isCounter?: boolean;
  handleApprove:
    | ((id: number, reason: string) => Promise<string>)
    | ((id: number) => Promise<string>);
  handleDeny: (id: number, reason: string) => Promise<string>;
};

const LegalizationReview = ({ data, handleApprove, handleDeny, isCounter = false }: TProps) => {
  const t = useLocalTranslation(i18n as Resource);
  const {
    actions: { getNotifications },
  } = useContext(sessionContext);
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const [denyModal, setDenyModal] = useState<TDeny>({ id: '', show: false });
  const [confirmModal, setConfirmModal] = useState(false);
  const [approveModal, setApproveModal] = useState<TApprove>({ id: '', show: false });
  const navigate = useNavigate();

  const deny = (id: number, reason: string) => {
    setLoading(true);
    handleDeny(id, reason)
      .finally(() => {
        setDenyModal({ id, show: false });
        setLoading(false);
      })
      .then((res) => {
        addToast(res, 'success');
        getNotifications(Apps.ADVANCES);
        navigate(isCounter ? '/contabilidad' : '/solicitudesanticipos');
      })
      .catch((e) => addToast(e.message, 'danger'));
  };

  const approve = (id: number, reason?: string) => {
    setLoading(true);
    handleApprove(id, reason || '')
      .finally(() => {
        setApproveModal({ id, show: false });
        setLoading(false);
      })
      .then((res) => {
        addToast(res, 'success');
        getNotifications(Apps.ADVANCES);
        navigate(isCounter ? '/contabilidad' : '/solicitudesanticipos');
      })
      .catch((e) => addToast(e.message, 'danger'));
  };

  if (data) {
    return (
      <div className={styles['review']}>
        <Salute
          msg={t('salute.msg')}
          photo={
            data.fotografiaUsuarioSolicitante
              ? `${endpoints.GET_MULTIMEDIA}/${data.fotografiaUsuarioSolicitante.contenido}`
              : user
          }
          name={data.nombreUsuario}
        >
          {!!data && (
            <div className={styles['container-request']}>
              <h4 className={styles['id-title']}>{t('salute.requestNo')}</h4>
              <div className={styles['container-request-id']}>
                <div className={styles['id-data']}>{data.id}</div>
              </div>
            </div>
          )}
        </Salute>
        {data.notaAprobador && (
          <div className={styles['approve-msg']}>
            <h4>{t('approveNote.title')}</h4>
            <p>{data.notaAprobador}</p>
          </div>
        )}
        {!!data.centroCostos && <DetailsCostCenter data={data} />}
        {!!data.fechaConsignacion && <DetailsInfo data={data} />}
        {!!data.totalSolicitado && <LegalizationDetails data={data} />}
        {!!data.totalSolicitado && <Supports data={data.soportes} readOnly />}
        {!!data.totalSolicitado && <ElectronicInvoices data={data.facturas} readOnly />}
        {data.descuentoNomina !== null && (
          <div className={styles['discount']}>
            <h3>{t('discount.title')}</h3>
            <div className={styles['checkbox']}>
              <Checkbox
                label={t('discount.options.true')}
                checked={data.descuentoNomina !== undefined ? !!data.descuentoNomina : false}
                readOnly
                read
              />
              <Checkbox
                label={t('discount.options.false')}
                checked={data.descuentoNomina !== undefined ? !data.descuentoNomina : false}
                readOnly
                read
              />
            </div>
          </div>
        )}
        <div className={styles['actions']}>
          <Button
            name="ant-review_btn-reject"
            theme="secondary"
            onClick={() => data && setDenyModal({ id: data.id, show: true })}
          >
            {t('actions.reject')}
          </Button>
          <Button
            name="ant-review_btn-approve"
            onClick={() =>
              data &&
              (isCounter ? setConfirmModal(true) : setApproveModal({ id: data.id, show: true }))
            }
          >
            {isCounter ? t('actions.finalize') : t('actions.approve')}
          </Button>
        </div>
        <DenyModal
          handleDeny={(id, reason) => deny(id as number, reason)}
          deny={denyModal}
          setDeny={setDenyModal}
        />
        <ApproveModal
          handleApprove={(id, reason) => approve(id as number, reason)}
          approve={approveModal}
          setApprove={setApproveModal}
          writeRequired={false}
        />
        <Modal
          title={t('modal.confirm.title')}
          successLabel={t('modal.confirm.actions.approve')}
          onClose={() => setConfirmModal(false)}
          show={!!confirmModal}
          onSuccess={() => {
            setConfirmModal(false);
            data && approve(data.id);
          }}
        >
          {t('modal.confirm.content')}
        </Modal>
      </div>
    );
  }

  return null;
};

export default LegalizationReview;
