/**
 * Solicitudes Pragma SA
 */

import React, { useContext } from 'react';

import user from '@/assets/images/user.png';
import { sessionContext } from '@/contexts/session';
import endpoints from '@/services/endpoints';

import styles from './styles.module.scss';

const AsideDataUser = () => {
  const { state } = useContext(sessionContext);

  return (
    <div className={styles.user}>
      <img
        src={`${endpoints.GET_MULTIMEDIA}/${state.data?.fotografias[0]?.contenido}` || user}
        alt="."
      />
      <div className={styles.info}>
        <h3>{`${state.data?.nombres} ${state.data?.apellidos}`}</h3>
        <h4>{state.data?.correoEmpresarial}</h4>
      </div>
    </div>
  );
};

export default AsideDataUser;
