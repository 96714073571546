/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import Checkbox from '@/components/ui/checkbox';
import Radio from '@/components/ui/radio';
import TextField from '@/components/ui/textField';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import type { Types } from '@/services/advances';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const DetailsMoney = ({ data }: { data: Types.TAdvanceDetails }) => {
  const t = useLocalTranslation(i18n as Resource);

  const total = data.costoTransporte + data.costoAlimentacion + data.costoOtro;

  return total ? (
    <section className={styles['money']}>
      <h3>{t('title')}</h3>
      <form>
        <TextField
          label={t('transport.label')}
          leading="$"
          trailing={data.codigoMoneda}
          className={styles['transport']}
          value={data.costoTransporte}
          readOnly
          readLeading
        />
        <TextField
          label={t('feeding.label')}
          leading="$"
          trailing={data.codigoMoneda}
          className={styles['food']}
          value={data.costoAlimentacion}
          readOnly
          readLeading
        />
        <TextField
          label={t('others.label')}
          leading="$"
          trailing={data.codigoMoneda}
          className={styles['others']}
          value={data.costoOtro}
          readOnly
          readLeading
        />
        <TextField
          label={t('currency.label')}
          leading={data.codigoMoneda}
          className={styles['currency']}
          value={data.nombreMoneda}
          readOnly
          readLeading
        />
        {data.solicitaEfectivo && (
          <Checkbox
            name="ant-request-cash"
            label={t('cash.label')}
            checked={data.solicitaEfectivo}
            className={styles['cash']}
            readOnly
            read
          />
        )}
        <TextField
          label={t('total.label')}
          leading="$"
          trailing={data.codigoMoneda}
          className={styles['total']}
          value={total}
          readOnly
          readLeading
        />
      </form>
      {data.descuentoNomina !== null && (
        <div className={styles['payroll']}>
          <h4>{t('payroll.title')}</h4>
          <div className={styles['checkboxes']}>
            <Radio label={t('payroll.options.true')} checked={data.descuentoNomina} readOnly read />
            <Radio
              label={t('payroll.options.false')}
              checked={!data.descuentoNomina}
              readOnly
              read
            />
          </div>
        </div>
      )}
    </section>
  ) : null;
};

export default DetailsMoney;
