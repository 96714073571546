/**
 * Solicitudes Pragma SA
 */

import React, { useEffect, useState, useContext } from 'react';

import Table from '@/components/table';
import Button from '@/components/ui/button';
import { utilsContext } from '@/contexts/utils';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import useList from '@/hooks/useList';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import useVacationsData from '@/hooks/useVacationsData';
import { Types, getPragmaticInformation, getInformationReport } from '@/services/vacations';
import { createFileBase64, getFileName } from '@/utils/downloadFile';

import Filter from './filter';
import i18n from './i18n.json';
import styles from './styles.module.scss';

const InformationPragmatic = () => {
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const cancellablePromise = useCancellablePromise();
  const [list, setList, setListLoading] = useList<Types.TInformationListItem>();
  const [form, setForm] = useState<Types.TGetInformationForm>({
    nombres: '',
    identificacion: '',
  });
  const t = useLocalTranslation(i18n as Resource);
  const { tableHeader } = useVacationsData();

  const requestList = (query?: Types.TGetInformationForm, page = 0) => {
    setListLoading(true, !page);
    cancellablePromise(
      getPragmaticInformation({
        ...(query || form),
        numeroPagina: page,
        totalElementos: 20,
        fechaCreacionAscendente: true,
      })
        .finally(() => setListLoading(false))
        .then((res) => {
          setList(res.dato || [], !!page, res.cantidadPaginas, res.ultimaPagina, page);
        })
        .catch((e) => addToast(e.message, 'danger')),
    );
    if (query) setForm(query);
  };

  const handleDownload = () => {
    setLoading(true);
    getInformationReport(validatorInput())
      .then((base64) => {
        createFileBase64(base64, getFileName('reporte_information'))
          .then(() => addToast(t('toast.documentSuccess'), 'success'))
          .catch((e) => addToast(e.message, 'danger'));
      })
      .catch((e) => addToast(e.message, 'danger'))
      .finally(() => setLoading(false));
  };

  const validatorInput = () => {
    const customForm = {
      numeroPagina: 0,
      totalElementos: 0,
      fechaOrdenAscendente: true,
    };
    if (!!form?.nombres && !!form?.identificacion) {
      return customForm;
    } else if (form?.nombres) {
      return { ...customForm, nombres: form?.nombres };
    } else {
      return { ...customForm, identificacion: form?.identificacion };
    }
  };

  useEffect(() => {
    requestList();
  }, [form]);

  return (
    <div className={styles['information']}>
      <Filter handleSearch={requestList} />
      <div className={styles['actions']}>
        <Button
          name="vac-information_button-download"
          disabled={!list.list.length}
          onClick={handleDownload}
        >
          {t('actions.download')}
        </Button>
      </div>
      <Table
        name="vac-information_table"
        header={[
          { name: 'identificacion', label: tableHeader('identification') },
          { name: 'nombres', label: tableHeader('userName') },
          { name: 'fechaInicioContrato', label: tableHeader('lastDate') },
          { name: 'diasGanados', label: tableHeader('daysWon') },
          { name: 'diasDisponibles', label: tableHeader('daysAvailable') },
          { name: 'diasTomados', label: tableHeader('daysTaken') },
          { name: 'diasApartados', label: tableHeader('daysSeparated') },
          { name: 'tipoDocumento', label: tableHeader('documentType') },
          { name: 'tipoContrato', label: tableHeader('typeContract') },
        ]}
        data={list.list.map((item) => ({
          _visible: item._visible,
          _key: item.id,
          identificacion: item.identificacion,
          tipoDocumento: item.tipoDocumento,
          nombres: `${item.nombres} ${item.apellidos}`,
          tipoContrato: `${item.tipoContrato[0].toUpperCase()}${item.tipoContrato.substring(1)}`,
          fechaInicioContrato: item.fechaInicioContrato,
          diasGanados: item.diasGanados,
          diasTomados: item.diasTomados,
          diasApartados: item.diasApartados,
          diasDisponibles: item.diasDisponibles,
        }))}
        advanced={{ ...list, handleSearch: (page) => requestList(form, page) }}
        emptyLabel={`${t('table.noData')} 🚀`}
      />
    </div>
  );
};

export default InformationPragmatic;
