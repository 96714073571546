/**
 * Solicitudes Pragma SA
 */

import React, { useState, useEffect, useContext } from 'react';

import Card from '@/components/card';
import DenyModal, { TDeny } from '@/components/denyModal';
import InfiniteScrollList from '@/components/infiniteScroll';
import StateLabel from '@/components/stateLabel';
import Button from '@/components/ui/button';
import { sessionContext } from '@/contexts/session';
import { utilsContext } from '@/contexts/utils';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import useList from '@/hooks/useList';
import useLocalTranslation, { Resource, useTranslation } from '@/hooks/useLocalTranslation';
import useVacationsData from '@/hooks/useVacationsData';
import { Apps } from '@/pages';
import {
  Types,
  approveRequest,
  denyRequest,
  getVacations,
  cancelRequest,
} from '@/services/vacations';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const PendingTeam = () => {
  const {
    actions: { getNotifications, getPermissions },
  } = useContext(sessionContext);
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const cancellablePromise = useCancellablePromise();
  const [list, setList, setListLoading, setVisible] = useList<Types.TVacationItem>();
  const [denyModal, setDenyModal] = useState<TDeny>({ id: '', show: false });
  const { t } = useTranslation('vacations');
  const l = useLocalTranslation(i18n as Resource);
  const { cardInfo } = useVacationsData();

  const requestList = (page = 0) => {
    setListLoading(true, !page);
    cancellablePromise(
      getVacations({
        estados: ['PENDIENTE'],
        numeroPagina: page,
        totalElementos: 5,
        solicitante: false,
        fechaCreacionAscendente: false,
      })
        .finally(() => setListLoading(false))
        .then((res) => {
          setList(res.dato || [], !!page, res.cantidadPaginas, res.ultimaPagina, page);
        })
        .catch((e) => addToast(e.message, 'danger')),
    );
  };

  const handleDeny = (id: number, reason: string) => {
    setLoading(true);
    denyRequest(id, reason)
      .finally(() => {
        setLoading(false);
        setDenyModal({ id, show: false });
      })
      .then((res) => {
        addToast(res, 'success');
        setVisible({ id }, false);
        getNotifications(Apps.VACATIONS);
      })
      .catch((e) => addToast(e.message, 'danger'));
  };

  const handleApprove = async (id: number) => {
    setLoading(true);
    approveRequest(id)
      .finally(() => setLoading(false))
      .then((res) => {
        addToast(res, 'success');
        setVisible({ id }, false);
        getNotifications(Apps.VACATIONS);
      })
      .catch((e) => addToast(e.message, 'danger'));
  };

  const handleCancel = async (id: number) => {
    setLoading(true);
    cancelRequest(id)
      .finally(() => setLoading(false))
      .then((res) => {
        addToast(res, 'success');
        setVisible({ id }, false);
        getNotifications(Apps.VACATIONS);
      })
      .catch((e) => addToast(e.message, 'danger'));
  };

  useEffect(() => {
    requestList();
  }, []);

  useEffect(() => {
    if (!list.lastPage && !list.isAnyVisible) {
      requestList();
    }
  }, [list.isAnyVisible, list.lastPage]);

  return (
    <>
      <div className={styles['refresh']}>
        <Button
          className={styles['button']}
          name="ant-search_button_search"
          onClick={() => requestList()}
        >
          {l('actionsRefresh.refresh')}
        </Button>
      </div>
      <InfiniteScrollList list={list} handleNext={requestList}>
        {list.list.map((item) => (
          <Card
            key={item.id}
            _visible={item._visible}
            header={{
              id: item.id,
              photo: item.fotografiaUsuarioSolicitante?.contenido,
              name: item.usuarioNombre,
              email: item.usuarioCorreo,
              date: new Date(item.fechaCreacion),
              tag: item.fechaPago && t('card.header.tag'),
            }}
            data={cardInfo(item)}
            state={
              <div className={styles['state']}>
                {t('state')}
                <StateLabel state={item.estado} />
              </div>
            }
            actions={
              getPermissions('poraprobardemiequipo').actualizar
                ? [
                    <Button
                      key="vac-to-approve_button-reject"
                      name="vac-to-approve_button-reject"
                      theme="secondary"
                      onClick={() => setDenyModal({ id: item.id, show: true })}
                    >
                      {l('actions.reject')}
                    </Button>,
                    <Button
                      key="vac-to-approve_button-approve"
                      name="vac-to-approve_button-approve"
                      onClick={() => handleApprove(item.id)}
                    >
                      {l('actions.approve')}
                    </Button>,
                    ...(item.estado === 'OCULTAR'
                      ? [
                          <Button
                            key="vac-to-approve_button-cancel"
                            name="vac-to-approve_button-cancel"
                            onClick={() => handleCancel(item.id)}
                          >
                            {t('vacations.card.actions.cancel')}
                          </Button>,
                        ]
                      : []),
                  ]
                : []
            }
          />
        ))}
      </InfiniteScrollList>
      <DenyModal
        handleDeny={(id, reason) => handleDeny(id as number, reason)}
        deny={denyModal}
        setDeny={setDenyModal}
      />
    </>
  );
};

export default PendingTeam;
