/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect, useState } from 'react';

import Toggle from '@/components/ui/toggle';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { getEnableVacations, toggleEnableVacations } from '@/services/vacations';

import i18n from './i18n.json';
import styles from './styles.module.scss';

enum Params {
  ENABLE_VACATIONS = 1,
}

const Options = () => {
  const t = useLocalTranslation(i18n as Resource);
  const { actions } = useContext(utilsContext);
  const [enableVacations, setEnableVacations] = useState<boolean>();

  useEffect(() => {
    actions.setLoading(true);
    getEnableVacations(Params.ENABLE_VACATIONS)
      .finally(() => actions.setLoading(false))
      .then((res) => setEnableVacations(res?.estadoBandera))
      .catch((err) => actions.addToast(err.message, 'danger'));
  }, []);

  return (
    <section className={styles['options']}>
      <Toggle
        label={t('question')}
        disabled={enableVacations === undefined}
        checked={enableVacations}
        onChange={() => {
          actions.setLoading(true);
          toggleEnableVacations(Params.ENABLE_VACATIONS)
            .finally(() => actions.setLoading(false))
            .then((res) => setEnableVacations(res?.estadoBandera))
            .catch((err) => actions.addToast(err.message, 'danger'));
        }}
      />
    </section>
  );
};

export default Options;
