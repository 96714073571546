/**
 * Solicitudes Pragma SA
 */

import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Types as AdvTypes } from '@/services/advances';
import { Types as VacTypes } from '@/services/vacations';

export type TMonths = { id: number; name: string };
export type TVacationsStates = { id: VacTypes.TVacationStates | 'ALL'; name: string };
export type TAdvancesStates = { id: AdvTypes.TAdvanceStates | 'ALL'; name: string };

const i18n = {
  es: {
    states: {
      advances: {
        all: 'Todos',
        approved: 'Aprobada',
        pending: 'Pendiente',
        managed: 'Gestionada',
        rejected: 'Rechazada',
        toLegalize: 'Por legalizar',
        inReview: 'En revisión',
        finished: 'Finalizado',
        canceled: 'Cancelada',
      },
      vacations: {
        all: 'Todos',
        approved: 'Aprobada',
        pending: 'Pendiente',
        liquidated: 'Liquidada',
        rejected: 'Rechazada',
        liquidationProcess: 'Proceso Liquidación',
        canceled: 'Cancelada',
      },
    },
    months: {
      all: 'Todos',
      january: 'Enero',
      february: 'Febrero',
      march: 'Marzo',
      april: 'Abril',
      may: 'Mayo',
      june: 'Junio',
      july: 'Julio',
      august: 'Agosto',
      september: 'Septiembre',
      october: 'Octubre',
      november: 'Noviembre',
      december: 'Diciembre',
    },
  },
  en: {
    states: {
      advances: {
        all: 'All',
        approved: 'Approved',
        pending: 'Pending',
        managed: 'Managed',
        rejected: 'Rejected',
        toLegalize: 'To legalize',
        inReview: 'In review',
        finished: 'Finished',
        canceled: 'Canceled',
      },
      vacations: {
        all: 'All',
        approved: 'Approved',
        pending: 'Pending',
        liquidated: 'Liquidated',
        rejected: 'Rejected',
        liquidationProcess: 'Liquidation process',
        canceled: 'Canceled',
      },
    },
    months: {
      all: 'All',
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },
  },
};

/**
 * Hook to get constantes with translation
 */
const useConstants = (): {
  months: TMonths[];
  vacationsStates: TVacationsStates[];
  advancesStates: TAdvancesStates[];
  getStatesAdvances: (id: (AdvTypes.TAdvanceStates | 'ALL')[]) => TAdvancesStates[];
  getStatesVacations: (id: (VacTypes.TVacationStates | 'ALL')[]) => TVacationsStates[];
} => {
  const t = useLocalTranslation(i18n as Resource);

  const vacationsStates: TVacationsStates[] = [
    { id: 'ALL', name: t('states.vacations.all') },
    { id: 'APROBADA', name: t('states.vacations.approved') },
    { id: 'PENDIENTE', name: t('states.vacations.pending') },
    { id: 'LIQUIDADA', name: t('states.vacations.liquidated') },
    { id: 'RECHAZADA', name: t('states.vacations.rejected') },
    { id: 'PROCESO_LIQUIDACION', name: t('states.vacations.liquidationProcess') },
    { id: 'CANCELADA', name: t('states.vacations.canceled') },
  ];
  const advancesStates: TAdvancesStates[] = [
    { id: 'ALL', name: t('states.advances.all') },
    { id: 'PENDIENTE', name: t('states.advances.pending') },
    { id: 'APROBADA', name: t('states.advances.approved') },
    { id: 'RECHAZADA', name: t('states.advances.rejected') },
    { id: 'POR_LEGALIZAR', name: t('states.advances.toLegalize') },
    { id: 'EN_REVISION', name: t('states.advances.inReview') },
    { id: 'GESTIONADA', name: t('states.advances.managed') },
    { id: 'FINALIZADO', name: t('states.advances.finished') },
    { id: 'CANCELADA', name: t('states.advances.canceled') },
  ];
  return {
    months: [
      { id: -1, name: t('months.all') },
      { id: 0, name: t('months.january') },
      { id: 1, name: t('months.february') },
      { id: 2, name: t('months.march') },
      { id: 3, name: t('months.april') },
      { id: 4, name: t('months.may') },
      { id: 5, name: t('months.june') },
      { id: 6, name: t('months.july') },
      { id: 7, name: t('months.august') },
      { id: 8, name: t('months.september') },
      { id: 9, name: t('months.october') },
      { id: 10, name: t('months.november') },
      { id: 11, name: t('months.december') },
    ],
    vacationsStates,
    advancesStates,
    getStatesAdvances: (id) => {
      return advancesStates.filter((state) => id?.some((item) => item === state?.id)) || [];
    },
    getStatesVacations: (id) => {
      return vacationsStates.filter((state) => id?.some((item) => item === state?.id)) || [];
    },
  };
};

export default useConstants;
