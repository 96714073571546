/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const DetailsNotes = ({ title, notes }: { title: string; notes: string[] }) => {
  const t = useLocalTranslation(i18n as Resource);

  return notes.length ? (
    <div className={styles['notes']}>
      <h4>{title}</h4>
      <ul>
        {notes.map((n, _key) => (
          <li key={_key}>{n}</li>
        ))}
      </ul>
      <div className={styles['help']}>
        <span>{t('help')} </span>
        <a
          href="https://www.pragma.com.co/centro-de-ayuda-administrativo"
          target="_blank"
          rel="noreferrer"
          className={styles['help-link']}
        >
          {t('link')}
        </a>
      </div>
    </div>
  ) : null;
};

export default DetailsNotes;
