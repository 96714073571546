/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect, useState } from 'react';

import Salute from '@/components/salute';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { getFacilitatorSupports, Types } from '@/services/advances';
import endpoints from '@/services/endpoints';

import i18n from './i18n.json';
import styles from './styles.module.scss';
import Supports from './supports';

const FacilitateSupports = ({ data }: { data: Types.TAdvanceDetails }) => {
  const t = useLocalTranslation(i18n as Resource);
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const [supports, setSupports] = useState<Types.TGetFacilitatorSupportsRes>();

  useEffect(() => {
    setLoading(true);
    getFacilitatorSupports(data.id)
      .finally(() => setLoading(false))
      .then((res) => setSupports(res))
      .catch((e) => addToast(e.message, 'danger'));
  }, []);

  return (
    <div className={styles['facilitate-supports']}>
      <Salute
        msg={t('salute.msg')}
        photo={`${endpoints.GET_MULTIMEDIA}/${data?.fotografiaUsuarioSolicitante?.contenido}`}
        name={data?.nombreUsuario}
      >
        {!!data && (
          <div className={styles['container-support']}>
            <h3 className={styles['id-title']}>{t('salute.requestNo')}</h3>
            <div className={styles['container-support-id']}>
              <div className={styles['id-data']}>{data.id}</div>
            </div>
          </div>
        )}
      </Salute>
      {!!data.tiquetes.length && supports && (
        <Supports isTickets data={data} info={supports.soportesTiquete} />
      )}
      {!!data.hospedajes.length && supports && (
        <Supports data={data} info={supports.soportesHospedaje} />
      )}
    </div>
  );
};

export default FacilitateSupports;
