/**
 * Solicitudes Pragma SA
 */

import { Apps, TApps } from '@/pages';
import axiosClient from '@/services/axiosClient';
import endpoints from '@/services/endpoints';
import type * as APITypes from '@/services/types';

import type * as Types from './types';

export { Types };

export const getUser = async (): Promise<Types.TUser | null> => {
  const [response, responseExt]: APITypes.TResponse<Types.TUser>[] = await Promise.all([
    axiosClient({
      url: endpoints.profile.GET_USER,
      method: 'GET',
    }),

    // Este endpoint trae información extra al anterior
    axiosClient({
      url: endpoints.profile.GET_EXT_USER,
      method: 'GET',
    }),
  ]);

  return Object.assign(response.dato || {}, responseExt.dato) || null;
};

export const getMenu = async (id: string): Promise<Types.TMenu[]> => {
  const response: APITypes.TResponse<Types.TMenu[]> = await axiosClient({
    url: endpoints.security.GET_MENU,
    method: 'GET',
    params: { idAplicacion: id },
  });

  return response.dato || [];
};

export const getNotifications = async (app: TApps): Promise<Types.TNotificationsMenu | null> => {
  let response: APITypes.TResponse<Types.TNotificationsMenu> = { mensaje: '' };
  switch (app) {
    case Apps.VACATIONS:
      response = await axiosClient({
        url: endpoints.vacations.GET_NOTIFICATIONS,
        method: 'GET',
      });
      break;

    case Apps.ADVANCES:
      response = await axiosClient({
        url: endpoints.advances.GET_NOTIFICATIONS,
        method: 'GET',
      });
      break;

    default:
      break;
  }

  return response.dato || null;
};
