/**
 * Solicitudes Pragma SA
 */

import React, { useEffect, useState } from 'react';

import { addDays, formatISO } from 'date-fns';
import { useFormContext } from 'react-hook-form';

import { CancelTreasuryIcon } from '@/assets/icons/icons';
import CardSize, { TSize } from '@/components/cardSize';
import SelectCity from '@/components/selects/selectCity';
import Table from '@/components/table';
import Button from '@/components/ui/button';
import calendar from '@/components/ui/calendar';
import Checkbox from '@/components/ui/checkbox';
import Toggle from '@/components/ui/toggle';
import useAdvancesData from '@/hooks/useAdvancesData';
import useFormatDate from '@/hooks/useFormatDate';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import type { Types as CatalogsTypes } from '@/services/catalogs';

import { TForm } from '..';

import i18n from './i18n.json';
import styles from './styles.module.scss';

export type TTiquets = {
  from?: CatalogsTypes.TCity;
  to?: CatalogsTypes.TCity;
};

type TTrip = 'ONEWAY' | 'ROUNDTRIP' | 'MULTI';

export type TSizes = {
  selected: boolean;
  size: TSize;
};

const Tickets = () => {
  const t = useLocalTranslation(i18n as Resource);
  const {
    register,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<TForm>();
  const [dateTravel, setDateTravel] = useState<Date | null>();
  const [dateTravelReturn, setDateTravelReturn] = useState<Date | null>();
  const [location, setLocation] = useState<TTiquets>({});
  const [typeTicket, setTypeTicket] = useState<TTrip>();
  const format = useFormatDate();
  const { tableHeader } = useAdvancesData();
  const [sizeXs, setSizeXs] = useState<TSizes>({
    selected: true,
    size: 'XS',
  });
  const [sizeS, setSizeS] = useState<TSizes>({
    selected: false,
    size: 'S',
  });
  const [sizeM, setSizeM] = useState<TSizes>({
    selected: false,
    size: 'M',
  });

  const listOptions = [
    {
      name: 'ant-request_checkbox-oneway',
      value: 'ONEWAY',
      label: t('options.oneway'),
    },
    {
      name: 'ant-request_checkbox-roundtrip',
      value: 'ROUNDTRIP',
      label: t('options.roundtrip'),
    },
    {
      name: 'ant-request_checkbox-multi',
      value: 'MULTI',
      label: t('options.multi'),
    },
  ];

  const tickets = watch('tiquetes') || [];
  const roaming = watch('necesitaRoaming');
  const transporte = watch('necesitaTransporte');

  const reset = () => {
    setDateTravel(null);
    setDateTravelReturn(null);
    setLocation({});
  };

  const handleAdd = () => {
    if (dateTravel) {
      setValue('tiquetes', [
        ...tickets,
        {
          origen: location.from?.ciudadId || 0,
          destino: location.to?.ciudadId || 0,
          nombreOrigen:
            `${location.from?.nombre}, ${location.from?.departamento} - ${location.from?.pais}` ||
            '-',
          nombreDestino:
            `${location.to?.nombre}, ${location.to?.departamento} - ${location.to?.pais}` || '-',
          fechaViaje: formatISO(dateTravel, { representation: 'date' }),
          horaViaje: `${('0' + dateTravel?.getHours()).slice(-2)}:00:00`,
        },
      ]);
      reset();
    }
  };

  const handleAddSize = (size: TSize) => {
    if (size === sizeXs.size) {
      setValue('tallaMaleta', sizeXs.size);
      setSizeXs({ size: 'XS', selected: true });
      setSizeS({ size: 'S', selected: false });
      setSizeM({ size: 'M', selected: false });
    } else if (size === sizeS.size) {
      setValue('tallaMaleta', sizeS.size);
      setSizeXs({ size: 'XS', selected: false });
      setSizeS({ size: 'S', selected: true });
      setSizeM({ size: 'M', selected: false });
    } else {
      setValue('tallaMaleta', sizeM.size);
      setSizeXs({ size: 'XS', selected: false });
      setSizeS({ size: 'S', selected: false });
      setSizeM({ size: 'M', selected: true });
    }
  };

  useEffect(() => {
    if (typeTicket === 'ONEWAY' || typeTicket === 'ROUNDTRIP') {
      if (location.from && location.to && dateTravel) {
        setValue('tiquetes', [
          {
            origen: location.from?.ciudadId || 0,
            destino: location.to?.ciudadId || 0,
            nombreOrigen:
              `${location.from?.nombre}, ${location.from?.departamento} - ${location.from?.pais}` ||
              '-',
            nombreDestino:
              `${location.to?.nombre}, ${location.to?.departamento} - ${location.to?.pais}` || '-',
            fechaViaje: formatISO(dateTravel, { representation: 'date' }),
            horaViaje: `${('0' + dateTravel?.getHours()).slice(-2)}:00:00`,
          },
          ...(typeTicket === 'ROUNDTRIP' && dateTravelReturn
            ? [
                {
                  origen: location.to?.ciudadId || 0,
                  destino: location.from?.ciudadId || 0,
                  nombreOrigen:
                    `${location.to?.nombre}, ${location.to?.departamento} - ${location.to?.pais}` ||
                    '-',
                  nombreDestino:
                    `${location.from?.nombre}, ${location.from?.departamento} - ${location.from?.pais}` ||
                    '-',
                  fechaViaje: formatISO(dateTravelReturn, { representation: 'date' }),
                  horaViaje: `${('0' + dateTravelReturn?.getHours()).slice(-2)}:00:00`,
                },
              ]
            : []),
        ]);
      }
    }
  }, [location, dateTravel, dateTravelReturn]);

  return (
    <section className={styles['tickets']}>
      <h3>{t('title')}</h3>
      <form>
        <div className={styles['type']}>
          {listOptions.map((type) => (
            <Checkbox
              key={type.name}
              name={type.name}
              label={type.label}
              checked={typeTicket === type.value}
              positionLabel="end"
              onChange={() => {
                setTypeTicket(typeTicket === type.value ? ('' as TTrip) : (type.value as TTrip));
                setValue('tiquetes', []);
                setValue('necesitaRoaming', false);
                setValue('necesitaTransporte', false);
                reset();
              }}
            />
          ))}
        </div>
        {typeTicket && (
          <>
            <div
              className={styles['inputs']}
              {...register('_tickets', {
                validate: () =>
                  (typeTicket &&
                    (typeTicket === 'ROUNDTRIP' ? tickets.length > 1 : !!tickets.length)) ||
                  t('formValidation.required'),
              })}
              {...register('_airportFrom', {
                validate: () =>
                  (location.from?.tieneAeropuerto || (typeTicket === 'MULTI' && !!tickets.length)
                    ? true
                    : false) || t('formValidation.airportFrom'),
              })}
              {...register('_airportTo', {
                validate: () =>
                  (location.to?.tieneAeropuerto || (typeTicket === 'MULTI' && !!tickets.length)
                    ? true
                    : false) || t('formValidation.airportTo'),
              })}
            >
              <SelectCity
                label={t('fields.origin.label')}
                onChange={(value) => {
                  clearErrors(['_airportFrom', '_tickets']);
                  setLocation({ ...location, from: value });
                }}
                value={location.from}
                error={
                  !location.from ? errors['_tickets']?.message : errors['_airportFrom']?.message
                }
              />
              <SelectCity
                label={t('fields.destination.label')}
                onChange={(value) => {
                  clearErrors(['_airportTo', '_tickets']);
                  setLocation({ ...location, to: value });
                }}
                value={location.to}
                error={!location.to ? errors['_tickets']?.message : errors['_airportTo']?.message}
              />
              <div className={styles.dates}>
                <div className={styles['date-hour']}>
                  <calendar.Input
                    name="ant-request_inputdate-travel"
                    label={
                      typeTicket === 'MULTI'
                        ? t('fields.travelDate.label')
                        : t('fields.departureDate.label')
                    }
                    placeholderText={t('fields.travelDate.placeholder')}
                    className={styles['start-date']}
                    selected={dateTravel}
                    minDate={addDays(new Date(), 1)}
                    onChange={(day) => {
                      if (day && day.getTime() > new Date().setHours(23)) {
                        clearErrors('_tickets');
                        setDateTravel(day);
                        setDateTravelReturn(undefined);
                      }
                    }}
                    showTimeSelect
                    timeIntervals={60}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    timeFormat="hh:mm:aa"
                    timeCaption={t('fields.time.label')}
                    error={errors['_tickets']?.message}
                  />
                </div>
                {typeTicket === 'ROUNDTRIP' && (
                  <div className={styles['date-hour']}>
                    <calendar.Input
                      name="ant-request_inputdate-travel-return"
                      label={t('fields.returnDate.label')}
                      placeholderText={t('fields.returnDate.placeholder')}
                      className={styles['return-date']}
                      selected={dateTravelReturn}
                      minDate={dateTravel || new Date()}
                      onChange={(day) => {
                        if (day && day.getTime() > new Date().setHours(23)) {
                          clearErrors('_tickets');
                          setDateTravelReturn(day);
                        }
                      }}
                      disabled={!dateTravel}
                      showTimeSelect
                      timeIntervals={60}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeFormat="hh:mm:aa"
                      timeCaption={t('fields.time.label')}
                      error={errors['_tickets']?.message}
                    />
                  </div>
                )}
              </div>
            </div>
            {typeTicket === 'MULTI' && (
              <Button
                name="ant-request_button-add-tickets"
                disabled={
                  !location.from ||
                  !location.to ||
                  !dateTravel ||
                  !location.from?.tieneAeropuerto ||
                  !location.to?.tieneAeropuerto
                }
                onClick={handleAdd}
              >
                {t('actions.add')}
              </Button>
            )}
            <div className={styles['sizes']}>
              <CardSize
                size="XS"
                titleCard={t('size.titleSizeXS')}
                selected={sizeXs.selected}
                onclick={handleAddSize}
              >
                <div className={styles['description-size']}>
                  <ul className={styles['list']}>
                    <li>
                      <strong>{`${t('size.important_one')}`}</strong>
                      <span>{t('size.description_one')}</span>
                    </li>
                  </ul>
                </div>
                <Button
                  name="ant-request_button-selected-size"
                  theme={sizeXs.selected ? 'secondary-selected' : 'secondary'}
                >
                  {sizeXs.selected ? <p>{t('size.chooseSelected')}</p> : <p>{t('size.choose')}</p>}
                </Button>
              </CardSize>
              <CardSize
                size="S"
                titleCard={t('size.titleSizeS')}
                selected={sizeS.selected}
                onclick={handleAddSize}
              >
                <div className={styles['description-size']}>
                  <ul className={styles['list']}>
                    <li>
                      <strong>{`${t('size.important_one')}`}</strong>
                      <span>{t('size.description_one')}</span>
                    </li>
                    <li>
                      <strong>{`${t('size.important_two')}`}</strong>
                      <span>{t('size.description_two')}</span>
                    </li>
                  </ul>
                </div>
                <Button
                  name="ant-request_button-selected-size"
                  theme={sizeS.selected ? 'secondary-selected' : 'secondary'}
                >
                  {sizeS.selected ? <p>{t('size.chooseSelected')}</p> : <p>{t('size.choose')}</p>}
                </Button>
              </CardSize>
              <CardSize
                size="M"
                titleCard={t('size.titleSizeM')}
                selected={sizeM.selected}
                onclick={handleAddSize}
              >
                <div className={styles['description-size']}>
                  <ul className={styles['list']}>
                    <li>
                      <strong>{`${t('size.important_one')}`}</strong>
                      <span>{t('size.description_one')}</span>
                    </li>
                    <li>
                      <strong>{`${t('size.important_two')}`}</strong>
                      <span>{t('size.description_two')}</span>
                    </li>
                    <li>
                      <strong>{`${t('size.important_three')}`}</strong>
                      <span>{t('size.description_three')}</span>
                    </li>
                  </ul>
                </div>
                <Button
                  name="ant-request_button-selected-size"
                  theme={sizeM.selected ? 'secondary-selected' : 'secondary'}
                >
                  {sizeM.selected ? <p>{t('size.chooseSelected')}</p> : <p>{t('size.choose')}</p>}
                </Button>
              </CardSize>
            </div>
          </>
        )}
      </form>
      {(location.from !== undefined && !location.from?.tieneAeropuerto) ||
      (location.to !== undefined && !location.to?.tieneAeropuerto) ? (
        <p className={styles['reminder']}>
          <strong>{t('reminder.important_one')} </strong>
          {t('reminder.description_one')}
          <strong>{t('reminder.important_two')}</strong>
          {t('reminder.description_two')}
          <strong>{`${t('reminder.important_three')} 😊`}</strong>
        </p>
      ) : null}
      {(!!tickets.length && location.from?.tieneAeropuerto && location.to?.tieneAeropuerto) ||
      (!!tickets.length && typeTicket === 'MULTI') ? (
        <>
          <Table
            name="ant-request-hosts_table"
            header={[
              { name: 'nombreOrigen', label: tableHeader('cityOrigin') },
              { name: 'nombreDestino', label: tableHeader('cityDestination') },
              { name: 'fechaViaje', label: tableHeader('travelDate') },
              { name: 'horaViaje', label: tableHeader('travelTime') },
              ...(typeTicket !== 'ONEWAY' && typeTicket !== 'ROUNDTRIP'
                ? [{ name: '_actions', label: t('table.actions') }]
                : []),
            ]}
            data={tickets.map((item, _key) => ({
              _key,
              ...item,
              horaViaje: item.horaViaje
                ? format(new Date(`${item.fechaViaje}T${item.horaViaje}`), 'hh:mm aa')
                : '-',
              _actions: typeTicket !== 'ONEWAY' && typeTicket !== 'ROUNDTRIP' && (
                <Button
                  name="ant-request_button-delete-tickets"
                  theme="error"
                  className={styles['delete']}
                  onClick={() => {
                    const tmp = [...tickets];
                    tmp.splice(_key, 1);
                    setValue('tiquetes', tmp);
                  }}
                >
                  <CancelTreasuryIcon width={12} height={12} />
                </Button>
              ),
            }))}
          />
          <div className={styles['other-services']}>
            <div className={styles['toggle-description']}>
              <Toggle
                checked={roaming}
                label={t('roaming.question')}
                {...register('necesitaRoaming')}
              />
              <p>{t('roaming.description')}</p>
            </div>
            {roaming && (
              <p className={styles['reminder']}>
                <strong>{t('roamingReminder.important_one')} </strong>
                {t('roamingReminder.description_one')}
                <strong>{t('roamingReminder.important_two')}</strong>
                {`${t('roamingReminder.description_two')} 😊`}
              </p>
            )}
            <div className={styles['toggle-description']}>
              <Toggle
                checked={transporte}
                label={t('transport.question')}
                {...register('necesitaTransporte')}
              />
              <p>{t('transport.description')}</p>
            </div>
          </div>
        </>
      ) : null}
    </section>
  );
};

export default Tickets;
