/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import ElectronicInvoices from '@/app/advances/legalization/electronicInvoices';
import Supports from '@/components/supports';
import Button from '@/components/ui/button';
import Checkbox from '@/components/ui/checkbox';
import Modal from '@/components/ui/modal';
import Radio from '@/components/ui/radio';
import TextArea from '@/components/ui/textArea';
import TextField from '@/components/ui/textField';
import { sessionContext } from '@/contexts/session';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Apps } from '@/pages';
import { Types, legalize, getLegalization } from '@/services/advances';
import numComas from '@/utils/numComas';

import i18n from './i18n.json';
import styles from './styles.module.scss';

type TForm = Types.TLegalizeForm & {
  descuentoNomina: string | null; // Manejo diferente en formulario
  facturas: Types.TElectronicInvoice[];
  observacionLegalizacion: string;
  soportes: Types.TPhysicalInvoice[];
};

const AdvanceLegalization = ({ id }: { id: number }) => {
  const t = useLocalTranslation(i18n as Resource);
  const {
    actions: { getNotifications },
  } = useContext(sessionContext);
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const navigate = useNavigate();
  const [data, setData] = useState<Types.TLegalization>();
  const [confirmModal, setConfirmModal] = useState(false);
  const { formState, setValue, watch, register, handleSubmit, getValues } = useForm<TForm>({
    defaultValues: {
      id,
      costoTransporteLegalizado: undefined,
      costoAlimentacionLegalizado: undefined,
      costoOtroLegalizado: undefined,
      descuentoNomina: null,
      soportes: [],
      facturas: [],
      observacionLegalizacion: '',
    },
  });
  const [sendSupport, setSendSupport] = useState(false);
  const [sendInfo, setSendInfo] = useState(false);
  const [isError, setIsError] = useState(false);

  const total = watch([
    'costoTransporteLegalizado',
    'costoAlimentacionLegalizado',
    'costoOtroLegalizado',
  ]).reduce((prev, next) => (prev || 0) + (next || 0));

  const handleLegalize = (form: TForm) => {
    if (data) {
      setLoading(true);
      legalize({
        ...form,
        descuentoNomina:
          form.descuentoNomina && data.totalSolicitado - total !== 0
            ? form.descuentoNomina === 'true' || !(form.descuentoNomina === 'false')
            : null,
      })
        .finally(() => setLoading(false))
        .then((res) => {
          addToast(res, 'success');
          navigate('/solicitudesanticipos');
          getNotifications(Apps.ADVANCES);
        })
        .catch((e) => addToast(e.message, 'danger'));
    }
  };

  useEffect(() => {
    setLoading(true);
    getLegalization(id)
      .finally(() => setLoading(false))
      .then((res) => res && setData(res))
      .catch((e) => addToast(e.message, 'danger'));
  }, []);

  useEffect(() => {
    if (watch('soportes').length || watch('facturas').length) {
      setIsError(false);
    }
  }, [watch('soportes'), watch('facturas')]);

  if (data) {
    return (
      <div className={styles['legalization']}>
        {data.motivo && (
          <div className={styles['reject-msg']}>
            <h4>{t('reject.title')}</h4>
            <p>{data.motivo}</p>
          </div>
        )}
        <span className={styles['subtitle']}>
          <strong>{`${t('details.title')} (${data.tipoMoneda})`}</strong>
          <div>
            {t('details.request')} <strong>{id}</strong>
          </div>
        </span>
        <div className={styles['details']}>
          <div className={styles['resume']}>
            <div className={styles['data']}>
              {t('details.amount.label')}
              <span className={styles['value']}>
                {`$ ${numComas(data.totalSolicitado)} `}
                <span className={styles['currency']}>{data.tipoMoneda}</span>
              </span>
            </div>
            <div className={styles['data']}>
              {t('details.expenses.label')}
              <span className={styles['value']}>
                {`$ ${numComas(total)} `}
                <span className={styles['currency']}>{data.tipoMoneda}</span>
              </span>
            </div>
            <div className={styles['data']}>
              {t('details.balance.label')}
              <span
                className={styles['value']}
                data-theme={data.totalSolicitado - total < 0 ? 'red' : 'green'}
              >
                {`$ ${numComas(data.totalSolicitado - total)} `}
                <span className={styles['currency']}>{data.tipoMoneda}</span>
              </span>
            </div>
          </div>
          <div className={styles['separator']} />
          <div className={styles['fields']}>
            <TextField
              label={t('details.transport.label')}
              placeholder={t('details.transport.placeholder')}
              leading="$"
              trailing={data.tipoMoneda}
              error={formState.errors['costoTransporteLegalizado']?.message}
              keyPattern={/^[0-9]*\.?[0-9]*$/}
              {...register('costoTransporteLegalizado', {
                valueAsNumber: true,
                required: t('formValidation.required'),
              })}
            />
            <TextField
              label={t('details.feeding.label')}
              placeholder={t('details.feeding.placeholder')}
              leading="$"
              trailing={data.tipoMoneda}
              error={formState.errors['costoAlimentacionLegalizado']?.message}
              keyPattern={/^[0-9]*\.?[0-9]*$/}
              {...register('costoAlimentacionLegalizado', {
                valueAsNumber: true,
                required: t('formValidation.required'),
              })}
            />
            <TextField
              label={t('details.others.label')}
              placeholder={t('details.others.placeholder')}
              leading="$"
              trailing={data.tipoMoneda}
              error={formState.errors['costoOtroLegalizado']?.message}
              keyPattern={/^[0-9]*\.?[0-9]*$/}
              {...register('costoOtroLegalizado', {
                valueAsNumber: true,
                required: t('formValidation.required'),
              })}
            />
          </div>
          <TextArea
            label={t('details.observations.label')}
            placeholder={t('details.observations.placeHolder')}
            className={styles['observations']}
            {...register('observacionLegalizacion')}
          />
        </div>
        <h3>{t('options.title')}</h3>
        <p className={styles['reminder']}>
          <strong>{t('reminder.title')} </strong>
          {t('reminder.description_one')}
          <strong>{t('reminder.bill')}</strong>
          {t('reminder.description_two')}
          <strong> {`${t('reminder.important')} 😊`}</strong>
        </p>
        <div className={styles['checkbox']}>
          <Checkbox
            label={t('options.bill')}
            className={isError ? styles['checkbox-error'] : ''}
            onChange={() => {
              setSendSupport(!sendSupport);
            }}
            positionLabel="end"
          />
          <Checkbox
            label={t('options.electronic')}
            className={isError ? styles['checkbox-error'] : ''}
            onChange={() => {
              setSendInfo(!sendInfo);
            }}
            positionLabel="end"
          />
        </div>
        {sendInfo && (
          <ElectronicInvoices
            tipoMoneda={data?.tipoMoneda}
            setData={(value) => setValue('facturas', value)}
            data={watch('facturas')}
            showTitle={true}
          />
        )}
        {sendSupport && (
          <Supports
            setData={(value) => setValue('soportes', value)}
            data={watch('soportes')}
            hideTitle={true}
            hideReminder={true}
            title={t('options.bill')}
          />
        )}
        {data.totalSolicitado - total !== 0 && (
          <div className={styles['payroll']}>
            <h3>{t('authorization.title')}</h3>
            <div className={styles['checkboxes']}>
              <Radio
                label={t('authorization.options.true')}
                value="true"
                error={!!formState.errors['descuentoNomina']}
                {...register('descuentoNomina', {
                  validate: (value) =>
                    (total !== 0 && value !== null) || !!value || t('formValidation.required'),
                })}
              />
              <Radio
                label={t('authorization.options.false')}
                value="false"
                error={!!formState.errors['descuentoNomina']}
                {...register('descuentoNomina')}
              />
              <span className={styles['error']}>
                {formState.errors['descuentoNomina']?.message}
              </span>
            </div>
            <p>{t('authorization.description')}</p>
          </div>
        )}
        <Button
          name="ant-legalization_button-send"
          className={styles['legalize']}
          onClick={handleSubmit((form) => {
            if (form.soportes.length || form.facturas.length) handleLegalize(form);
            else {
              setConfirmModal(true);
            }
          })}
        >
          {t('actions.send')}
        </Button>
        <Modal
          title={t('modal.title')}
          successLabel={t('modal.successLabel')}
          onClose={() => setConfirmModal(false)}
          show={confirmModal}
          onSuccess={() => {
            setConfirmModal(false);
            handleLegalize(getValues());
          }}
        >
          {t('modal.description')}
        </Modal>
      </div>
    );
  }

  return null;
};

export default AdvanceLegalization;
