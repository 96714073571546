/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import Login from '@/app/login';

const LoginPage = () => <Login />;

export default LoginPage;
