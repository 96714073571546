/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import user from '@/assets/images/user.png';

import styles from './styles.module.scss';

type TProps = {
  msg?: string;
  extra?: string;
  name?: string;
  photo?: string;
  distribution?: boolean;
};

const Salute = ({
  children,
  msg,
  extra,
  photo,
  name,
  distribution = false,
}: React.PropsWithChildren<TProps>) => (
  <div className={styles.salute}>
    <div className={styles.left}>
      <img src={photo || user} alt="." />
      <div className={styles.user}>
        <h2>
          {msg && `${msg}, `}
          <div className={distribution ? styles.name : styles.custom}>{name || 'Pragmático'}</div>
        </h2>
        <span className={styles.extra}>{extra || ''}</span>
      </div>
    </div>
    {children && <div className={styles.right}>{children}</div>}
  </div>
);

export default Salute;
