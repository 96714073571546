/**
 * Solicitudes Pragma SA
 */

import React, { useEffect } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import SelectApprover from '@/components/selects/selectApprover';
import SelectBusiness from '@/components/selects/selectBusiness';
import SelectCountry from '@/components/selects/selectCountry';
import TextArea from '@/components/ui/textArea';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Apps } from '@/pages';
import { ROL } from '@/services/catalogs';
import type { Types } from '@/services/vacations';

import i18n from './i18n.json';
import styles from './styles.module.scss';

type TProps = {
  idPais?: number;
  isDisabled?: boolean;
};

const VacationRequestForm = ({ idPais, isDisabled }: TProps) => {
  const {
    register,
    watch,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext<Types.TAddVacationForm>();
  const l = useLocalTranslation(i18n as Resource);

  useEffect(() => {
    if (idPais) {
      clearErrors('paisId');
      setValue('paisId', String(idPais));
    }
  }, [idPais]);

  return (
    <div className={styles['form']}>
      <Controller
        control={control}
        name="paisId"
        rules={{ required: l('formValidation.required') }}
        render={({ field: { ref, name } }) => (
          <SelectCountry
            name={name}
            ref={ref}
            className={styles['country']}
            onChange={(country) => {
              if (country) {
                clearErrors('paisId');
                setValue('paisId', String(country));
              }
            }}
            value={Number(idPais)}
            error={errors['paisId']?.message}
            isDisabled={isDisabled}
          />
        )}
      />
      <Controller
        control={control}
        name="vicepresidenciaId"
        rules={{ required: l('formValidation.required') }}
        render={({ field: { ref, name } }) => (
          <SelectBusiness
            name={name}
            ref={ref}
            className={styles['business']}
            onChange={(business) => {
              if (business) {
                clearErrors('vicepresidenciaId');
                setValue('vicepresidenciaId', business);
              }
            }}
            value={watch('vicepresidenciaId')}
            error={errors['vicepresidenciaId']?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="aprobadorId"
        rules={{ required: l('formValidation.required') }}
        render={({ field: { ref, name } }) => (
          <SelectApprover
            name={name}
            ref={ref}
            className={styles['approver']}
            onChange={(approvers) => {
              if (approvers) {
                clearErrors('aprobadorId');
                setValue('aprobadorId', approvers);
              }
            }}
            value={watch('aprobadorId')}
            app={Apps.VACATIONS}
            rol={ROL.vacationsApprover}
            error={errors['aprobadorId']?.message}
          />
        )}
      />
      <TextArea
        label={l('notes.label')}
        helper={`${(watch('observaciones') || '').length}/500 ${l('notes.characters')}`}
        className={styles['notes']}
        placeholder={l('notes.placeholder')}
        error={errors['observaciones']?.message}
        {...register('observaciones', {
          required: l('formValidation.required'),
        })}
      />
    </div>
  );
};

export default VacationRequestForm;
