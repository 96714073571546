/**
 * Solicitudes Pragma SA
 */
import React from 'react';

import styles from './styles.module.scss';

type TProps = {
  msg: string;
  isRow?: boolean;
};

const EmptyState = ({ msg, isRow }: TProps) => {
  if (isRow) {
    return (
      <tr>
        <td colSpan={100}>
          <div className={styles['empty-state']}>{msg}</div>
        </td>
      </tr>
    );
  }

  return <div className={styles['empty-state']}>{msg}</div>;
};

export default EmptyState;
