/**
 * Solicitudes Pragma SA
 */

import React, { useState } from 'react';

import { formatISO, lastDayOfMonth, startOfMonth } from 'date-fns';

import SelectMonth from '@/components/selects/selectMonth';
import SelectYear from '@/components/selects/selectYear';
import Button from '@/components/ui/button';
import TextField from '@/components/ui/textField';
import useLocalTranslation, { Resource, useTranslation } from '@/hooks/useLocalTranslation';
import { Types, getYears } from '@/services/vacations';
import formatDate from '@/utils/formatDate';

import i18n from './i18n.json';
import styles from './styles.module.scss';

type TProps = {
  handleSearch: (form: Types.TGetVacationsFormExt, page?: number) => void;
};

const Filter = ({ handleSearch }: TProps) => {
  const [selectYear, setSelectYear] = useState<number | undefined>(-1);
  const [selectMonth, setSelectMonth] = useState<number | undefined>(-1);
  const [idFilter, setIdFilter] = useState('');
  const { t } = useTranslation('selects');
  const l = useLocalTranslation(i18n as Resource);

  const handleClick = () => {
    const params: Types.TGetVacationsFormExt = {
      ...formatDate(selectMonth, selectYear),
      idSolicitud: undefined,
      usuarioSolicitante: '',
      correoUsuario: '',
      estados: ['APROBADA', 'LIQUIDADA', 'PROCESO_LIQUIDACION'],
    };
    if (selectYear === -1) {
      params.fechaInicio = formatISO(startOfMonth(new Date()), { representation: 'date' });
      params.fechaFinal = formatISO(lastDayOfMonth(new Date()), { representation: 'date' });
    }
    if (isNaN(Number(idFilter))) {
      params.usuarioSolicitante = idFilter;
      params.correoUsuario = idFilter;
    } else if (Number(idFilter)) {
      params.idSolicitud = Number(idFilter);
    }
    handleSearch(params);
  };

  return (
    <div className={styles['filter']}>
      <TextField
        className={styles['filter-id']}
        label={l('filter.id.label')}
        placeholder={l('filter.id.placeholder')}
        onChange={({ currentTarget }) => setIdFilter(currentTarget.value)}
        value={idFilter}
      />
      <SelectYear
        years={getYears}
        onChange={(year) => {
          setSelectYear(year);
          if (year !== undefined) setSelectMonth(-1);
        }}
        value={selectYear}
      />
      <SelectMonth
        onChange={(month) => setSelectMonth(month)}
        value={selectMonth}
        setAllOption
        isDisabled={selectYear === -1}
        searchable={false}
      />
      <Button
        name="vac-history_button-search"
        onClick={handleClick}
        className={styles['search']}
        theme="primary-white"
      >
        {t('button.search')}
      </Button>
    </div>
  );
};

export default Filter;
