/**
 * Solicitudes Pragma SA
 */

import React, { useContext } from 'react';

import { ChangeApprover } from '@/app/vacations/administrator/changeApprover';
import Options from '@/app/vacations/administrator/options';
import Tabs from '@/components/ui/tab';
import { sessionContext } from '@/contexts/session';
import { useTranslation } from '@/hooks/useLocalTranslation';

const AdministratorPage = () => {
  const { t } = useTranslation('pages');
  const { state } = useContext(sessionContext);
  const vacListRoute = state.menu.data.vacations?.find((route) => route.path === '/administrador');

  return vacListRoute?.hijos ? (
    <Tabs
      subMenus={[
        {
          name: 'habilitarvacaciones',
          title: t('vacations.title.subMenu.habilitarvacaciones'),
          element: <Options />,
        },
        {
          name: 'reasignaraprobador',
          title: t('vacations.title.subMenu.reasignaraprobador'),
          element: <ChangeApprover />,
        },
      ].filter((route) => vacListRoute.hijos.find((subMenu) => subMenu.nombre === route.name))}
    />
  ) : null;
};

export default AdministratorPage;
