/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import History from '@/app/advances/history';

const HistoryContent = () => <History />;

export default HistoryContent;
