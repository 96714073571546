/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import CatalogsProvider from './catalogs';
import SessionProvider from './session';
import UtilsProvider from './utils';

const Contexts = ({ children }: { children: React.ReactNode }) => (
  <SessionProvider>
    <CatalogsProvider>
      <UtilsProvider>{children}</UtilsProvider>
    </CatalogsProvider>
  </SessionProvider>
);

export default Contexts;
