/**
 * Solicitudes Pragma SA
 */

import React, { useEffect, useContext, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import Card from '@/components/card';
import InfiniteScrollList from '@/components/infiniteScroll';
import StateLabel from '@/components/stateLabel';
import Button from '@/components/ui/button';
import TextField from '@/components/ui/textField';
import { utilsContext } from '@/contexts/utils';
import useAdvancesData from '@/hooks/useAdvancesData';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import useList from '@/hooks/useList';
import useLocalTranslation, { Resource, useTranslation } from '@/hooks/useLocalTranslation';
import { Types, getAccountingList } from '@/services/advances';
import { TStateLocation } from '@/template';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const List = () => {
  const [paramSearched, setParamSearched] = useState('');
  const {
    actions: { addToast, setScroll },
    state: { scroll },
  } = useContext(utilsContext);
  const cancellablePromise = useCancellablePromise();
  const [list, setList, setListLoading] = useList<Types.TAdvanceItem>();
  const { t } = useTranslation('advances');
  const l = useLocalTranslation(i18n as Resource);
  const { cardInfo } = useAdvancesData();
  const navigate = useNavigate();
  const [localScroll, setlocalScroll] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (!(location.state as TStateLocation)?.previusPath) {
      setScroll(0);
    }
  }, [location]);

  const requestList = (page = 0) => {
    setListLoading(true, !page);
    const form = validatorInput(page);
    cancellablePromise(
      getAccountingList(form)
        .finally(() => setListLoading(false))
        .then((res) => {
          setList(res.dato || [], !!page, res.cantidadPaginas, res.ultimaPagina, page);
        })
        .catch((e) => addToast(e.message, 'danger')),
    );
  };

  useEffect(() => {
    requestList();
  }, []);

  useEffect(() => {
    if (!paramSearched) {
      requestList();
    }
  }, [paramSearched]);

  useEffect(() => {
    if (!list.lastPage && !list.isAnyVisible) {
      requestList();
    }
  }, [list.isAnyVisible, list.lastPage]);

  const validatorInput = (page: number) => {
    const form = {
      numeroPagina: page,
      totalElementos: scroll > 0 ? Math.ceil(scroll / 150) + 2 : 5,
      fechaOrdenAscendente: true,
    };
    if (!paramSearched) {
      return form;
    } else if (isNaN(Number(paramSearched))) {
      return { ...form, nombreUsuario: paramSearched };
    } else {
      return { ...form, idSolicitud: Number(paramSearched) };
    }
  };

  return (
    <>
      <section className={styles['search']}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            requestList();
          }}
        >
          <TextField
            placeholder={l('searcher.placeholder')}
            keyPattern={/^[0-9, a-z, A-Z]*$/}
            onChange={(e) => setParamSearched(e.currentTarget.value)}
            value={paramSearched}
          />
          <Button name="ant-search_button_search" onClick={() => requestList()}>
            {l('actionsSearcher.search')}
          </Button>
        </form>
      </section>
      <InfiniteScrollList list={list} handleNext={requestList} setLocalScroll={setlocalScroll}>
        {list.list.map((item) => (
          <Card
            key={item.id}
            _visible={item._visible}
            header={{
              id: item.id,
              photo: item.fotografiaUsuarioSolicitante?.contenido,
              name: item.nombreUsuario,
              email: item.correoUsuario,
              date: new Date(item.fechaCreacion),
            }}
            data={cardInfo(item)}
            state={
              <div className={styles['state-info']}>
                {t('state')}
                <StateLabel state={item.estado} />
              </div>
            }
            actions={
              item.estado === 'EN_REVISION'
                ? [
                    <Button
                      key="ant-accounting_button-review"
                      name="ant-accounting_button-review"
                      onClick={() => {
                        setScroll(localScroll);
                        navigate('/revisarlegalizacionanticipo', {
                          state: { id: item.id, previusPath: location.pathname, isCounter: true },
                        });
                      }}
                    >
                      {l('actions.review')}
                    </Button>,
                    <Button
                      name="ant-accounting_button-details"
                      key="ant-accounting_button-details"
                      className={styles['details']}
                      onClick={() => {
                        setScroll(localScroll);
                        navigate('/detallesanticipo', {
                          state: { id: item.id, previusPath: location.pathname },
                        });
                      }}
                      theme="tertiary"
                    >
                      {l('actions.seeDetails')}
                    </Button>,
                  ]
                : []
            }
          />
        ))}
      </InfiniteScrollList>
    </>
  );
};

export default List;
