/**
 * Solicitudes Pragma SA
 */

import React, { forwardRef, useId, Ref, useContext, useState } from 'react';

import Select from '@/components/ui/select';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { getCities, Types } from '@/services/catalogs';

import i18n from './i18n.json';
import styles from './styles.module.scss';

type TProps = {
  name?: string;
  label?: string;
  value?: Types.TCity;
  onChange?: (value?: Types.TCity) => void;
  isClearable?: boolean;
  className?: string;
  isDisabled?: boolean;
  error?: string;
};

const SelectCity = (
  { name, label, value, onChange, isClearable, className, isDisabled, error }: TProps,
  ref: Ref<HTMLLabelElement>,
) => {
  const {
    actions: { addToast },
  } = useContext(utilsContext);
  const t = useLocalTranslation(i18n as Resource, 'selects');
  const uName = useId();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Types.TCity[]>([]);

  const handleInputChange = (inputValue: string) => {
    if (inputValue.length > 2) {
      setLoading(true);
      getCities(inputValue)
        .finally(() => setLoading(false))
        .then((res) => setOptions(res))
        .catch((e) => {
          setOptions([]);
          addToast(e.message, 'danger');
        });
    } else {
      setOptions([]);
    }
  };

  return (
    <Select
      labelRef={ref}
      name={name || uName}
      label={label || t('selectCity.label')}
      placeholder={t('selectCity.placeholder')}
      noOptionsMessage={() => t('selectCity.noData')}
      className={`${styles['city']} ${className || ''}`}
      options={options}
      onInputChange={handleInputChange}
      onChange={(option, actionMeta) => {
        if (actionMeta.action === 'clear') onChange && onChange(undefined);
        else if (option) onChange && onChange(option);
      }}
      value={value || null}
      getOptionLabel={(option) => `${option.nombre}, ${option.departamento} - ${option.pais}`}
      openMenuOnClick={false}
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      isClearable={isClearable}
      isLoading={loading}
      isDisabled={isDisabled}
      error={error}
    />
  );
};

export default forwardRef(SelectCity);
