/**
 * Solicitudes Pragma SA
 */

import React, { useState, useContext, useEffect } from 'react';

import { CancelTreasuryIcon, MinusIcon, MoneyIcon } from '@/assets/icons/icons';
import DenyModal, { TDeny } from '@/components/denyModal';
import Table from '@/components/table';
import Button from '@/components/ui/button';
import Checkbox from '@/components/ui/checkbox';
import { utilsContext } from '@/contexts/utils';
import useAdvancesData from '@/hooks/useAdvancesData';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import useList from '@/hooks/useList';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Apps } from '@/pages';
import {
  Types,
  getTreasuryList,
  generateTreasuryReport,
  setPaid,
  denyAdvanceTreasury,
} from '@/services/advances';
import { getNotifications } from '@/services/session';
import { createFileBase64, getFileName } from '@/utils/downloadFile';

import Filter from './filter';
import i18n from './i18n.json';
import styles from './styles.module.scss';

const TreasuryPending = () => {
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const cancellablePromise = useCancellablePromise();
  const [list, setList, setListLoading, setVisible] = useList<Types.TTreasuryHistoryItem>();
  const [form, setForm] = useState<Types.TTreasuryHistoryListForm>({
    fechaInicio: '',
    fechaFin: '',
  });
  const [paidCheck, setPaidCheck] = useState<{ id: number }[]>([]);
  const t = useLocalTranslation(i18n as Resource);
  const { tableHeader } = useAdvancesData();

  const [denyModal, setDenyModal] = useState<TDeny>({ id: '', show: false });

  const handleCheck = (checked: boolean, id: number) => {
    if (checked) setPaidCheck([...paidCheck, { id }]);
    else {
      const advanceFilter = paidCheck.filter((item) => item.id !== id);
      setPaidCheck(advanceFilter);
    }
  };

  const handleDownload = () => {
    setLoading(true);
    generateTreasuryReport({
      ...form,
      fechaOrdenAscendente: true,
    })
      .then((base64) => {
        createFileBase64(base64, getFileName('reporte_tesoreria'))
          .then(() => addToast(t('toast.documentSuccess'), 'success'))
          .catch((e) => addToast(e.message, 'danger'));
      })
      .catch((e) => addToast(e.message, 'danger'))
      .finally(() => setLoading(false));
  };

  const handlePaid = () => {
    setLoading(true);
    setPaid(paidCheck)
      .finally(() => setLoading(false))
      .then((res) => {
        addToast(res, 'success');
        setPaidCheck([]);
        setVisible(paidCheck, false);
        getNotifications(Apps.ADVANCES);
      })
      .catch((e) => addToast(e.message, 'danger'));
  };

  const requestList = (query?: Types.TTreasuryHistoryListForm, page = 0) => {
    setListLoading(true, !page);
    cancellablePromise(
      getTreasuryList({
        ...(query || form),
        numeroPagina: page,
        totalElementos: 10,
        fechaOrdenAscendente: true,
      })
        .finally(() => setListLoading(false))
        .then((res) => {
          setList(res.dato || [], !!page, res.cantidadPaginas, res.ultimaPagina, page);
        })
        .catch((e) => addToast(e.message, 'danger')),
    );
    if (query) setForm(query);
  };

  useEffect(() => {
    requestList();
  }, []);

  const handleCancel = (id: number, reason: string) => {
    setLoading(true);
    denyAdvanceTreasury(id, reason)
      .finally(() => {
        setDenyModal({ id, show: false });
        setLoading(false);
      })
      .then((res) => {
        addToast(res, 'success');
        setVisible({ id }, false);
        getNotifications(Apps.ADVANCES);
      })
      .catch((e) => addToast(e.message, 'danger'));
  };

  return (
    <div className={styles['history']}>
      <Filter handleSearch={requestList} />
      <div className={styles['actions']}>
        <Button name="ant-treasury_button-download" onClick={handleDownload}>
          {t('actions.download')}
        </Button>
        <Button
          name="ant-treasury_button-payment"
          disabled={!paidCheck.length}
          onClick={handlePaid}
          theme="secondary"
        >
          {t('actions.pay')}
        </Button>
      </div>
      <Table
        name="ant-treasury-pending_table"
        header={[
          { name: '_checkbox', label: tableHeader('payment') },
          { name: 'id', label: tableHeader('id') },
          { name: 'documentoUsuario', label: tableHeader('idUser') },
          { name: 'nombreUsuario', label: tableHeader('username') },
          { name: 'montoTotal', label: tableHeader('amountRequest') },
          { name: 'centroDeCostos', label: tableHeader('costCenter') },
          { name: 'requestCash', label: tableHeader('requestCash') },
          { name: 'actions', label: tableHeader('action') },
        ]}
        data={list.list.map((item) => ({
          _key: item.id,
          _checkbox: (
            <Checkbox
              name={`ant-treasury_checkbox-payment-${item.id}`}
              onChange={(e) => handleCheck(e.currentTarget.checked, item.id)}
            />
          ),
          ...item,
          correoUsuario: item.correoUsuario?.split('@')[0],
          correoUsuarioAutorizador: item.correoUsuarioAutorizador?.split('@')[0],
          montoTotal: `$ ${new Intl.NumberFormat('es-CO').format(item.montoTotal)} ${
            item.codigoMoneda
          }`,
          centroDeCostos: item.centroDeCostos?.join(', '),
          requestCash: item.solicitaEfectivo ? (
            <MoneyIcon width={25} height={25} className={styles['icon']} />
          ) : (
            <MinusIcon width={25} height={2} className={styles['icon']} />
          ),
          actions: (
            <Button
              name="ant-treasury_button-cancel"
              className={`${styles['cancel']} ${styles['icon']}`}
              theme="error"
              onClick={() => setDenyModal({ id: item.id, show: true })}
            >
              <CancelTreasuryIcon width={12} height={12} />
            </Button>
          ),
        }))}
        rowStyle={{ montoTotal: { whiteSpace: 'nowrap' } }}
        advanced={{ ...list, handleSearch: (page) => requestList(form, page) }}
        emptyLabel={`${t('table.noData')} 🚀`}
      />
      <DenyModal
        handleDeny={(id, reason) => handleCancel(id as number, reason)}
        deny={denyModal}
        setDeny={setDenyModal}
      />
    </div>
  );
};

export default TreasuryPending;
