/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useRef } from 'react';

import { FiLogOut } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import Translation from '@/components/translation';
import Button from '@/components/ui/button';
import { sessionContext } from '@/contexts/session';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import useOutside from '@/hooks/useOutside';

import i18n from './i18n.json';
import Menu from './menu';
import styles from './styles.module.scss';
import AsideDataUser from './user';

type TProps = {
  showMenu: boolean;
  setShowMenu: (show: boolean) => void;
};

const SidebarComponent = ({ showMenu, setShowMenu }: TProps) => {
  const t = useLocalTranslation(i18n as Resource);
  const { actions } = useContext(sessionContext);
  const navigate = useNavigate();
  const ref = useRef(null);

  useOutside(ref, () => {
    setShowMenu(false);
  });

  return (
    <>
      <div className={styles.screen} data-show={showMenu ? '' : undefined} />
      <aside className={styles.sidebar} data-show={showMenu ? '' : undefined} ref={ref}>
        <Translation theme="dark" className={styles.translation} align="left" size="small" />
        <AsideDataUser />
        <Menu />
        <div className={styles.footer}>
          <Button
            name="sidebar_button-logout"
            trailing={<FiLogOut />}
            ghost
            className={styles.logout}
            onClick={() => {
              actions.logout().finally(() => {
                navigate('/login', { replace: true });
              });
            }}
          >
            {t('actions.logout')}
          </Button>
        </div>
      </aside>
    </>
  );
};

export default SidebarComponent;
