/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect, useState } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import LegalizationReview from '@/app/advances/legalizationReview';
import { utilsContext } from '@/contexts/utils';
import {
  Types,
  getDetailsExt,
  denyAccounting,
  denyLegalization,
  approveAccounting,
  approveLegalization,
} from '@/services/advances';

const LegalizationReviewPage = () => {
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const [details, setDetails] = useState<Types.TAdvanceDetailsExt | null>(null);
  const location = useLocation();
  const { id, isCounter } = (location.state as { id: number; isCounter: boolean }) || { id: null };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getDetailsExt(id)
        .finally(() => setLoading(false))
        .then((res) => setDetails(res))
        .catch((e) => addToast(e.message, 'danger'));
    }
  }, []);

  return id ? (
    <LegalizationReview
      data={details}
      handleDeny={isCounter ? denyAccounting : denyLegalization}
      handleApprove={isCounter ? approveAccounting : approveLegalization}
      isCounter={isCounter}
    />
  ) : (
    <Navigate to="/404" replace />
  );
};

export default LegalizationReviewPage;
