/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import styles from './styles.module.scss';

const SpinnerLoader = () => (
  <div className={styles.loader}>
    <div />
    <div />
  </div>
);

export default SpinnerLoader;
