/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import History from '@/app/vacations/history';

const HistoryPage = () => <History />;

export default HistoryPage;
