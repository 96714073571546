/**
 * Solicitudes Pragma SA
 */

import React, { forwardRef, useId, Ref, useContext, useEffect, useState } from 'react';

import Select from '@/components/ui/select';
import { catalogsContext } from '@/contexts/catalogs';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { TApps } from '@/pages';
import { ROL } from '@/services/catalogs';

import i18n from './i18n.json';
import styles from './styles.module.scss';

type TProps = {
  name?: string;
  onChange?: (value?: string) => void;
  isClearable?: boolean;
  value?: string;
  className?: string;
  isDisabled?: boolean;
  app: TApps;
  rol: ROL;
  error?: string;
};

const SelectApprover = (
  { name, onChange, isClearable, value, className, isDisabled, app, rol, error }: TProps,
  ref: Ref<HTMLLabelElement>,
) => {
  const {
    state: { approvers },
    actions: { getApprovers },
  } = useContext(catalogsContext);
  const {
    actions: { addToast },
  } = useContext(utilsContext);
  const t = useLocalTranslation(i18n as Resource, 'selects');
  const uName = useId();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getApprovers(app, rol)
      .finally(() => setLoading(false))
      .catch((e) => addToast(e.message, 'danger'));
  }, []);

  return (
    <Select
      labelRef={ref}
      name={name || uName}
      label={t('selectApprover.label')}
      placeholder={t('selectApprover.placeholder')}
      noOptionsMessage={() => t('selectApprover.noData')}
      className={`${styles['approver']} ${className || ''}`}
      options={approvers[app][rol]}
      onChange={(option, actionMeta) => {
        if (actionMeta.action === 'clear') onChange && onChange(undefined);
        else if (option) onChange && onChange(option.id);
      }}
      getOptionLabel={(option) => option.nombre}
      value={value ? approvers[app][rol]?.find((option) => option.id === value) : null}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={loading}
      error={error}
    />
  );
};

export default forwardRef(SelectApprover);
