/**
 * Solicitudes Pragma SA
 */

import React, { CSSProperties, useState, Ref } from 'react';

import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

import styles from './styles.module.scss';

import 'react-datepicker/dist/react-datepicker.css';
import './dayPicker.scss';

const InlineRange = (
  props: ReactDatePickerProps<never, true> & {
    helper?: string;
    error?: string;
    labelRef?: Ref<HTMLLabelElement>;
  },
) => {
  const { helper, error, labelRef, className, onChange, ...rest } = props;

  const [selected, setSelected] = useState<{ from?: Date | null; to?: Date | null }>({});

  const handleCalendar = ([from, to]: [Date | null, Date | null], e?: React.SyntheticEvent) => {
    if (!props.disabled && props.selectsRange) {
      setSelected({ from, to });
      onChange([from, to], e);
    }
  };

  return (
    <label
      ref={labelRef}
      htmlFor={props.name}
      className={`${styles['calendar-inline']} ${className || ''}`}
      data-error={error ? '' : undefined}
      data-disabled={props.disabled ? '' : undefined}
    >
      <DatePicker
        data-disabled={props.disabled ? '' : undefined}
        calendarStartDay={0}
        disabledKeyboardNavigation
        startDate={!props.selectsRange ? props.startDate : selected.from}
        endDate={!props.selectsRange ? props.startDate : selected.to}
        onChange={!props.selectsRange ? onChange : handleCalendar}
        {...rest}
      />
      {error ? (
        <div className={styles.helper}>{error}</div>
      ) : (
        helper && <div className={styles.helper}>{helper}</div>
      )}
    </label>
  );
};

const Input = ({
  style,
  className,
  label,
  error,
  helper,
  labelRef,
  ...props
}: ReactDatePickerProps & {
  style?: CSSProperties;
  label?: string;
  error?: string;
  helper?: string;
  labelRef?: Ref<HTMLLabelElement>;
}) => {
  return (
    <label
      ref={labelRef}
      htmlFor={props.name}
      className={`${styles['calendar-input']} ${className || ''}`}
      style={style}
      data-label={label && ''}
      data-error={error ? '' : undefined}
      data-disabled={props.disabled ? '' : undefined}
    >
      {label && label}
      <DatePicker
        calendarStartDay={0}
        disabledKeyboardNavigation
        className={styles.input}
        calendarClassName={styles.popper}
        {...props}
      />
      {error ? (
        <div className={styles.helper}>{error}</div>
      ) : (
        helper && <div className={styles.helper}>{helper}</div>
      )}
    </label>
  );
};

export default {
  InlineRange,
  Input,
};
