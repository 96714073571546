/**
 * Solicitudes Pragma SA
 */

import React, { useEffect, useState } from 'react';

import { TSizes } from '@/app/advances/request/form/tickets';
import CardSize from '@/components/cardSize';
import Table from '@/components/table';
import Button from '@/components/ui/button';
import Toggle from '@/components/ui/toggle';
import useAdvancesData from '@/hooks/useAdvancesData';
import useFormatDate from '@/hooks/useFormatDate';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import type { Types } from '@/services/advances';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const DetailsTickets = ({
  data,
  hideServices,
}: {
  data: Types.TAdvanceDetails;
  hideServices?: boolean;
}) => {
  const t = useLocalTranslation(i18n as Resource);
  const { tableHeader } = useAdvancesData();
  const formatDate = useFormatDate();
  const [sizeXs, setSizeXs] = useState<TSizes>({
    selected: false,
    size: 'XS',
  });
  const [sizeS, setSizeS] = useState<TSizes>({
    selected: false,
    size: 'S',
  });
  const [sizeM, setSizeM] = useState<TSizes>({
    selected: false,
    size: 'M',
  });

  useEffect(() => {
    if (data?.tallaMaleta === 'XS') {
      setSizeXs({ size: 'XS', selected: true });
    } else if (data?.tallaMaleta === 'S') {
      setSizeS({ size: 'S', selected: true });
    } else if (data?.tallaMaleta === 'M') {
      setSizeM({ size: 'M', selected: true });
    }
  }, []);

  return data.tiquetes.length ? (
    <section className={styles['tickets']}>
      <h2>{t('title')}</h2>
      {data?.tallaMaleta && (
        <div className={styles['sizes']}>
          <CardSize size="XS" titleCard={t('size.titleSizeXS')} selected={sizeXs.selected} readOnly>
            <div className={styles['description-size']}>
              <ul className={styles['list']}>
                <li>
                  <strong>{`${t('size.important_one')}`}</strong>
                  <span>{t('size.description_one')}</span>
                </li>
              </ul>
            </div>
            <Button
              name="ant-request_button-selected-size"
              theme={sizeXs.selected ? 'secondary-selected' : 'secondary'}
              disabled
            >
              {sizeXs.selected ? <p>{t('size.chooseSelected')}</p> : <p>{t('size.choose')}</p>}
            </Button>
          </CardSize>
          <CardSize size="S" titleCard={t('size.titleSizeS')} selected={sizeS.selected} readOnly>
            <div className={styles['description-size']}>
              <ul className={styles['list']}>
                <li>
                  <strong>{`${t('size.important_one')}`}</strong>
                  <span>{t('size.description_one')}</span>
                </li>
                <li>
                  <strong>{`${t('size.important_two')}`}</strong>
                  <span>{t('size.description_two')}</span>
                </li>
              </ul>
            </div>
            <Button
              name="ant-request_button-selected-size"
              theme={sizeS.selected ? 'secondary-selected' : 'secondary'}
              disabled
            >
              {sizeS.selected ? <p>{t('size.chooseSelected')}</p> : <p>{t('size.choose')}</p>}
            </Button>
          </CardSize>
          <CardSize size="M" titleCard={t('size.titleSizeM')} selected={sizeM.selected} readOnly>
            <div className={styles['description-size']}>
              <ul className={styles['list']}>
                <li>
                  <strong>{`${t('size.important_one')}`}</strong>
                  <span>{t('size.description_one')}</span>
                </li>
                <li>
                  <strong>{`${t('size.important_two')}`}</strong>
                  <span>{t('size.description_two')}</span>
                </li>
                <li>
                  <strong>{`${t('size.important_three')}`}</strong>
                  <span>{t('size.description_three')}</span>
                </li>
              </ul>
            </div>
            <Button
              name="ant-request_button-selected-size"
              theme={sizeM.selected ? 'secondary-selected' : 'secondary'}
              disabled
            >
              {sizeM.selected ? <p>{t('size.chooseSelected')}</p> : <p>{t('size.choose')}</p>}
            </Button>
          </CardSize>
        </div>
      )}
      <Table
        name="ant-details-hosts_table"
        header={[
          { name: 'nombreOrigen', label: tableHeader('cityOrigin') },
          { name: 'nombreDestino', label: tableHeader('cityDestination') },
          { name: 'fechaViaje', label: tableHeader('travelDate') },
        ]}
        data={data.tiquetes.map((item, _key) => ({
          _key,
          ...item,
          fechaViaje: formatDate(
            new Date(`${item.fechaViaje}T${item.horaViaje || '00:00:00'}`),
            'PP - hh:mm aa',
          ),
        }))}
      />
      {!hideServices && (
        <div className={styles['other-services']}>
          <Toggle label={t('roaming.question')} checked={data.necesitaRoaming} readOnly />
          <Toggle label={t('transport.question')} checked={data.necesitaTransporte} readOnly />
        </div>
      )}
    </section>
  ) : null;
};

export default DetailsTickets;
