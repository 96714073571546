/**
 * Solicitudes Pragma SA
 */

import React, { forwardRef, useId, Ref, useState, useEffect, useContext } from 'react';

import Select from '@/components/ui/select';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';

import i18n from './i18n.json';
import styles from './styles.module.scss';

type TProps = {
  name?: string;
  years: () => Promise<number[]>;
  onChange?: (value?: number) => void;
  isClearable?: boolean;
  value?: number;
  className?: string;
  error?: string;
  setAllOption?: boolean;
  isDisabled?: boolean;
  searchable?: boolean;
};

const SelectYear = (
  {
    name,
    years,
    onChange,
    isClearable,
    value,
    className,
    error,
    setAllOption,
    isDisabled,
    searchable = true,
  }: TProps,
  ref: Ref<HTMLLabelElement>,
) => {
  const { actions } = useContext(utilsContext);
  const t = useLocalTranslation(i18n as Resource, 'selects');
  const uName = useId();
  const [options, setOptions] = useState<{ id: number; name: string }[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    years()
      .finally(() => setLoading(false))
      .then((list) => {
        setOptions([
          ...(setAllOption ? [{ id: -1, name: t('selectYear.options.all') }] : []),
          ...list.map((year) => ({ id: year, name: String(year) })),
        ]);
      })
      .catch((e) => actions.addToast(e.message, 'danger'));
  }, []);

  return (
    <Select
      isSearchable={searchable}
      labelRef={ref}
      name={name || uName}
      label={t('selectYear.label')}
      placeholder={t('selectYear.placeholder')}
      noOptionsMessage={() => t('selectYear.noData')}
      error={error}
      className={`${styles['year']} ${className || ''}`}
      options={options}
      onChange={(option, actionMeta) => {
        if (actionMeta.action === 'clear') onChange && onChange(undefined);
        else if (option) onChange && onChange(option.id);
      }}
      getOptionLabel={(option) => option.name}
      value={value ? options.find((option) => option.id === value) : null}
      isClearable={isClearable}
      isLoading={loading}
      isDisabled={isDisabled}
    />
  );
};

export default forwardRef(SelectYear);
