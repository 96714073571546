/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect, useState } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import Details from '@/app/advances/details';
import AttachedFiles from '@/app/advances/details/attachedFiles';
import Tabs from '@/components/ui/tab';
import type { Types as SessionTypes } from '@/contexts/session';
import { utilsContext } from '@/contexts/utils';
import { useTranslation } from '@/hooks/useLocalTranslation';
import { Types, getDetails } from '@/services/advances';

type TLocationState = {
  id: number | null;
  permissions: SessionTypes.TPermissions;
  isApplicant: boolean;
};

const DetailsPage = () => {
  const { t } = useTranslation('pages');
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const location = useLocation();
  const { id, permissions, isApplicant } = (location.state as TLocationState) || { id: null };
  const [details, setDetails] = useState<Types.TAdvanceDetails | null>(null);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getDetails(id)
        .finally(() => setLoading(false))
        .then((res) => setDetails(res))
        .catch((e) => addToast(e.message, 'danger'));
    }
  }, []);

  return id ? (
    <Tabs
      subMenus={[
        {
          name: 'information',
          title: t('advances.title.subMenu.information'),
          element: <Details data={details} permissions={permissions} isApplicant={isApplicant} />,
        },
        ...(details?.tiquetes.length || details?.hospedajes.length
          ? [
              {
                name: 'supports',
                title: t('advances.title.subMenu.supports'),
                element: <AttachedFiles data={details} />,
              },
            ]
          : []),
      ]}
    />
  ) : (
    <Navigate to="/404" replace />
  );
};

export default DetailsPage;
