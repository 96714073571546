/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import DetailsCostCenter from '@/app/advances/details/costCenter';
import DetailsInfo from '@/app/advances/details/info';
import LegalizationDetails from '@/app/advances/legalizationReview/details';
import Supports from '@/components/supports';
import Checkbox from '@/components/ui/checkbox';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import type { Types } from '@/services/advances';

import i18n from './i18n.json';
import styles from './styles.module.scss';
import ElectronicInvoices from '@/app/advances/legalization/electronicInvoices';

const AdvanceHistory = ({ data }: { data: Types.TAdvanceDetailsExt }) => {
  const t = useLocalTranslation(i18n as Resource);

  return (
    data && (
      <>
        <h2 className={styles['title']}>
          {`${t('title')} ${data.id}`}
          <span className={styles['state']}>{data.estado}</span>
        </h2>
        {!!data.centroCostos && <DetailsCostCenter data={data} />}
        {!!data.fechaConsignacion && <DetailsInfo data={data} />}
        {!!data.totalSolicitado && (
          <div className={styles['discount']}>
            <LegalizationDetails data={data} />
            <Supports data={data.soportes} readOnly />
            <ElectronicInvoices data={data.facturas} readOnly  />
            {data.descuentoNomina !== null && (
              <div className={styles['discount']}>
                <h3>{`${t('discount.title')}*`}</h3>
                <div className={styles['checkbox']}>
                  <Checkbox
                    label={t('discount.options.true')}
                    checked={data.descuentoNomina !== undefined ? !!data.descuentoNomina : false}
                    readOnly
                    read
                  />
                  <Checkbox
                    label={t('discount.options.false')}
                    checked={data.descuentoNomina !== undefined ? !data.descuentoNomina : false}
                    readOnly
                    read
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </>
    )
  );
};

export default AdvanceHistory;
