/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import TextField from '@/components/ui/textField';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import type { Types } from '@/services/advances';
import numComas from '@/utils/numComas';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const LegalizationDetails = ({ data }: { data: Types.TAdvanceDetailsExt }) => {
  const t = useLocalTranslation(i18n as Resource);
  const total =
    (data.costoTransporteLegalizado || 0) +
    (data.costoAlimentacionLegalizado || 0) +
    (data.costoOtroLegalizado || 0);

  return (
    <div className={styles['details']}>
      <h3>{`${t('title')} (${data.codigoMoneda})`}</h3>
      <div className={styles['resume']}>
        <div className={styles['data']}>
          {t('amount.label')}
          <span className={styles['value']}>
            {`$ ${numComas(data.totalSolicitado)} `}
            <span className={styles['currency']}>{data.codigoMoneda}</span>
          </span>
        </div>
        <div className={styles['data']}>
          {t('expenses.label')}
          <span className={styles['value']}>
            {`$ ${numComas(total)} `}
            <span className={styles['currency']}>{data.codigoMoneda}</span>
          </span>
        </div>
        <div className={styles['data']}>
          {t('balance.label')}
          <span
            className={styles['value']}
            data-theme={data.totalSolicitado - total < 0 ? 'red' : 'green'}
          >
            {`$ ${numComas(data.totalSolicitado - total)} `}
            <span className={styles['currency']}>{data.codigoMoneda}</span>
          </span>
        </div>
      </div>
      <div className={styles['separator']} />
      <div className={styles['fields']}>
        <TextField
          label={t('transport.label')}
          leading="$"
          trailing={data.codigoMoneda}
          value={data.costoTransporteLegalizado}
          readOnly
          readLeading
        />
        <TextField
          label={t('feeding.label')}
          leading="$"
          trailing={data.codigoMoneda}
          value={data.costoAlimentacionLegalizado}
          readOnly
          readLeading
        />
        <TextField
          label={t('others.label')}
          leading="$"
          trailing={data.codigoMoneda}
          value={data.costoOtroLegalizado}
          readOnly
          readLeading
        />
      </div>
    </div>
  );
};

export default LegalizationDetails;
