/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { utilsContext } from '@/contexts/utils';
import { approveRequest } from '@/services/vacations';

import styles from './styles.module.scss';

const Approve = ({ id }: { id: number | null }) => {
  const {
    actions: { setLoading },
  } = useContext(utilsContext);
  const [msg, setMsg] = useState('Aprobando...');
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setLoading(true);
      approveRequest(id)
        .finally(() => setLoading(false))
        .then((res) => setMsg(res))
        .catch((e) => setMsg(e.message));
    } else navigate('/404', { replace: true });
  }, []);

  return <div className={styles.container}>{msg}</div>;
};

export default Approve;
