/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useState } from 'react';

import { formatISO } from 'date-fns';

import { SearchIcon } from '@/assets/icons/icons';
import SelectApprover from '@/components/selects/selectApprover';
import SelectDocType from '@/components/selects/selectDocType';
import Button from '@/components/ui/button';
import calendar from '@/components/ui/calendar';
import TextField from '@/components/ui/textField';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Apps } from '@/pages';
import { getUserByDocument, ROL } from '@/services/catalogs';
import { Types, createPaidRequest } from '@/services/vacations';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const PaidRequest = () => {
  const {
    actions: { setLoading, addToast },
  } = useContext(utilsContext);
  const [loaders, setLoaders] = useState({ documents: false, approvers: false, getUser: false });
  const [form, setForm] = useState<Types.TPaidRequestForm>({
    idSolicitante: '',
    idAutorizador: '',
    dias: 0,
    fechaPago: '',
  });
  const [documentForm, setDocumentForm] = useState<{
    idTipoDocumento?: number;
    documento: string;
    nombre: string;
  }>({ idTipoDocumento: undefined, documento: '', nombre: '' });
  const [payDay, setPayDate] = useState<Date | null>();
  const t = useLocalTranslation(i18n as Resource);

  return (
    <section className={styles['paid-request']}>
      <h3>{t('title')}</h3>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className={styles['form']}>
          <SelectDocType
            className={styles['doc-type']}
            onChange={(value) =>
              value && setDocumentForm({ ...documentForm, idTipoDocumento: value })
            }
            value={documentForm.idTipoDocumento}
          />
          <div className={styles['container-document']}>
            <TextField
              name="vac-paid_textfield-document"
              label={t('document.label')}
              placeholder={t('document.placeholder')}
              className={`${styles['document']} ${styles['document-focus']} ${
                (loaders.getUser ||
                  !documentForm.idTipoDocumento ||
                  documentForm.documento === '') &&
                styles['document-custom']
              }`}
              onChange={(e) =>
                setDocumentForm({ ...documentForm, documento: e.currentTarget.value })
              }
              value={documentForm.documento}
            />
            <Button
              name="vac-paid_button-search"
              type="submit"
              className={`${styles['search']}`}
              disabled={
                loaders.getUser || !documentForm.idTipoDocumento || documentForm.documento === ''
              }
              onClick={() => {
                setLoaders({ ...loaders, getUser: true });
                getUserByDocument(documentForm.idTipoDocumento || 0, Number(documentForm.documento))
                  .finally(() => setLoaders({ ...loaders, getUser: false }))
                  .then((res) => {
                    setDocumentForm({ ...documentForm, nombre: res?.nombreSolicitante || '-' });
                    setForm({ ...form, idSolicitante: res?.idSolicitante || '-' });
                  })
                  .catch((err) => {
                    setDocumentForm({ ...documentForm, nombre: '' });
                    addToast(err.message, 'danger');
                  });
              }}
            >
              <SearchIcon width={16} height={16} />
            </Button>
          </div>
          <TextField
            name="vac-paid_textfield-name-requester"
            label={t('requester.label')}
            placeholder={t('requester.placeholder')}
            className={styles['name']}
            value={documentForm.nombre}
            readOnly
            read
          />
        </div>
      </form>
      {documentForm.nombre !== '' && (
        <>
          <div className={styles['line']} />
          <div className={styles['form']}>
            <SelectApprover
              className={styles['approver']}
              app={Apps.VACATIONS}
              rol={ROL.vacationsApprover}
              onChange={(value) => value && setForm({ ...form, idAutorizador: value })}
              value={form.idAutorizador}
              isDisabled={documentForm.nombre === ''}
            />
            <TextField
              name="vac-paid_textfield-days"
              label={t('days.label')}
              placeholder={t('days.placeholder')}
              trailing={t('days.trailing')}
              className={styles['days']}
              keyPattern={/^$|^[0-9]\d{0,2}$/}
              onChange={(e) => setForm({ ...form, dias: Number(e.currentTarget.value) })}
              value={form.dias}
              disabled={documentForm.nombre === ''}
            />
            <calendar.Input
              name="vac-paid_inputdate-payment"
              label={t('payDate.label')}
              placeholderText={t('payDate.placeholder')}
              className={styles['pay-date']}
              onChange={(day) => setPayDate(day)}
              selected={payDay}
              minDate={new Date()}
              disabled={documentForm.nombre === ''}
            />
          </div>
          <Button
            name="vac-paid_button-create-request"
            className={styles['create']}
            disabled={
              form.idSolicitante === '' ||
              form.idAutorizador === '' ||
              form.dias <= 0 ||
              form.dias > 999 ||
              !payDay
            }
            onClick={() => {
              setLoading(true);
              createPaidRequest({
                ...form,
                fechaPago: payDay ? formatISO(payDay, { representation: 'date' }) : '',
              })
                .finally(() => setLoading(false))
                .then((res) => {
                  addToast(res, 'success');
                  setForm({
                    ...form,
                    idSolicitante: '',
                    idAutorizador: '',
                    dias: 0,
                  });
                  setPayDate(undefined);
                  setDocumentForm({ ...documentForm, documento: '', nombre: '' });
                })
                .catch((err) => addToast(err.message, 'danger'));
            }}
          >
            {t('actions.send')}
          </Button>
        </>
      )}
    </section>
  );
};

export default PaidRequest;
