/**
 * Solicitudes Pragma SA
 */

import React, { useEffect, useContext } from 'react';

import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { AiOutlineGoogle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/ui/button';
import { sessionContext } from '@/contexts/session';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const Login = () => {
  const t = useLocalTranslation(i18n as Resource);
  const { state } = useContext(sessionContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (state.logged) navigate('/', { replace: true });
  }, [state.logged]);

  return (
    <div className={styles['login']}>
      <div className={styles['wrapper']}>
        <div className={`${styles.particle} ${styles.first}`}></div>
        <div className={`${styles.particle} ${styles.second}`}></div>
        <div className={`${styles.particle} ${styles.third}`}></div>
      </div>
      <div className={styles['logo']}>
        <div className={styles['shadow']}></div>
        <span className={styles['ball']}></span>
        <span className={styles['one']}></span>
        <span className={styles['two']}></span>
        <span className={styles['three']}></span>
      </div>
      <div className={styles['container']}>
        <h1>{t('title')}</h1>
        <Button
          name="button_login"
          theme="quaternary"
          onClick={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })}
        >
          <AiOutlineGoogle />
          {t('actions.googleLogin')}
        </Button>
      </div>
    </div>
  );
};

export default Login;
