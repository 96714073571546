/**
 * Solicitudes Pragma SA
 */

import { formatISO } from 'date-fns';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: Blob, defaultName?: string) => boolean;
  }
}

export const getFileName = (name: string, extension = 'xlsx') =>
  `${name}_${formatISO(new Date(), { representation: 'date' })}.${extension}`;

/**
 * Trigger download navigator from url
 *
 * @param fileUrl String of the url
 */
export default (fileUrl: string) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = fileUrl as string;
  downloadLink.click();
};

export const convertBase64toBlob = (content: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = window.atob(content);

  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, {
    type: contentType,
  });
};

/**
 * Create File From Base64
 *
 * @param base64 String of base64
 * @param name String of the file name
 * @param typeFile String of the file type
 */
export const createFileBase64 = async (
  base64: string,
  name: string,
  typeFile = 'application/vnd.ms-excel',
) => {
  const blob = convertBase64toBlob(base64, typeFile);

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, name);
  } else {
    const link = document.createElement('a');

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', name);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      throw new Error();
    }
  }
};
