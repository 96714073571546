/**
 * Solicitudes Pragma SA
 */

import React, { useContext } from 'react';

import { MdTranslate } from 'react-icons/md';

import Button from '@/components/ui/button';
import { sessionContext } from '@/contexts/session';

import styles from './styles.module.scss';

type TProps = {
  theme?: 'dark' | null;
  align?: 'left' | 'right' | null;
  size?: 'small' | null;
  className?: string;
};

const Translation = ({ theme, align, size, className }: TProps) => {
  const { state, actions } = useContext(sessionContext);
  return (
    <div
      className={`${styles.translation} ${className || ''}`}
      // Custom attributes
      data-theme={theme}
      data-align={align || 'left'}
      data-size={size}
    >
      <MdTranslate className={styles.icon} />
      <Button
        name="translation_button_es"
        text
        className={`${styles.option} ${state.language === 'es' ? styles.active : ''}`}
        onClick={() => actions.setLanguage('es')}
      >
        ES
      </Button>
      <Button
        name="translation_button_en"
        text
        className={`${styles.option} ${state.language === 'en' ? styles.active : ''}`}
        onClick={() => actions.setLanguage('en')}
      >
        EN
      </Button>
    </div>
  );
};

export default Translation;
