/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import { motion, AnimatePresence } from 'framer-motion';
import InfiniteScroll from 'react-infinite-scroll-component';

import EmptyState from '../emptyState';
import Shimmer from '../ui/shimmer';

import styles from './styles.module.scss';

type TProps = {
  name: string;
  header: { name: string; label: React.ReactNode }[];
  data: ({ _key: React.Key } & Record<string, React.ReactNode>)[];
  emptyLabel?: string;
  rowClassName?: Record<string, string | undefined>;
  rowStyle?: Record<string, React.CSSProperties | undefined>;
  headerClassName?: Record<string, string | undefined>;
  headerStyle?: Record<string, React.CSSProperties | undefined>;
  advanced?: {
    count: number;
    page: number;
    lastPage?: boolean;
    isNewSearch?: boolean;
    isLoading?: boolean;
    isAnyVisible?: boolean;
    handleSearch: (page: number) => void;
  };
};

const Table = ({
  name,
  header,
  data,
  emptyLabel,
  rowClassName,
  rowStyle,
  headerClassName,
  headerStyle,
  advanced,
}: TProps) => {
  if (advanced) {
    return (
      <div id={name} className={styles.table}>
        <InfiniteScroll
          scrollableTarget="template"
          dataLength={advanced.count}
          next={() => advanced.handleSearch(advanced.page + 1)}
          hasMore={!advanced.lastPage}
          loader={null}
          scrollThreshold={0.9}
          style={{ overflow: 'visible' }}
        >
          <table>
            <thead>
              <tr>
                {header.map((th) => (
                  <th
                    key={th.name}
                    style={headerStyle && headerStyle[th.name]}
                    className={headerClassName && headerClassName[th.name]}
                  >
                    {th.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {advanced.isNewSearch && advanced.isLoading ? (
                <Shimmer isRow repetitions={3} />
              ) : advanced.count > 0 && advanced.isAnyVisible ? (
                <>
                  {data.map((d) => (
                    <AnimatePresence key={d._key}>
                      {d._visible && (
                        <motion.tr
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          {header.map((th) => (
                            <td
                              key={th.name}
                              style={rowStyle && rowStyle[th.name]}
                              className={rowClassName && rowClassName[th.name]}
                            >
                              {d[th.name]}
                            </td>
                            // <td key={th.name}><input type="text" name='test_text'/></td>
                          ))}
                        </motion.tr>
                      )}
                    </AnimatePresence>
                  ))}
                  {advanced.isLoading && <Shimmer isRow />}
                </>
              ) : (
                emptyLabel && <EmptyState isRow msg={emptyLabel} />
              )}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    );
  }

  return (
    <div id={name} className={styles.table}>
      <table>
        <thead>
          <tr>
            {header.map((th) => (
              <th
                key={th.name}
                style={headerStyle && headerStyle[th.name]}
                className={headerClassName && headerClassName[th.name]}
              >
                {th.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length
            ? data.map((d) => (
                <tr key={d._key}>
                  {header.map((th) => (
                    <td
                      key={th.name}
                      style={rowStyle && rowStyle[th.name]}
                      className={rowClassName && rowClassName[th.name]}
                    >
                      {d[th.name]}
                    </td>
                  ))}
                </tr>
              ))
            : emptyLabel && <EmptyState isRow msg={emptyLabel} />}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
