/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import Error from '@/app/error';

const ErrorPage = () => <Error />;

export default ErrorPage;
