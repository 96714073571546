/**
 * Solicitudes Pragma SA
 */

import { useId } from 'react';

import { Resource } from 'i18next';
import { useTranslation } from 'react-i18next';

export { useTranslation };
export type { Resource };

const useLocalTranslation = (resource: Resource, namespace?: string) => {
  const uniqueId = useId();
  const { t, i18n } = useTranslation(namespace || uniqueId);

  Object.keys(resource).forEach((res) => {
    i18n.addResourceBundle(res, namespace || uniqueId, resource[res]);
  });

  return t;
};

export default useLocalTranslation;
