/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import Table from '@/components/table';
import useAdvancesData from '@/hooks/useAdvancesData';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import type { Types } from '@/services/advances';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const DetailsHosts = ({ data }: { data: Types.TAdvanceDetails }) => {
  const t = useLocalTranslation(i18n as Resource);
  const { tableHeader } = useAdvancesData();

  return data.hospedajes.length ? (
    <div className={styles['hosts']}>
      <h2>{t('title')}</h2>
      <Table
        name="ant-details-hosts_table"
        header={[
          { name: 'nombreCiudad', label: tableHeader('cityName') },
          { name: 'fechaLlegada', label: tableHeader('arrivalDate') },
          { name: 'fechaSalida', label: tableHeader('departureDate') },
        ]}
        data={data.hospedajes.map((item, _key) => ({ _key, ...item }))}
      />
    </div>
  ) : null;
};

export default DetailsHosts;
