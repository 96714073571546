/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import Legalize from '@/app/advances/legalization';

const LegalizePage = () => {
  const location = useLocation();
  const { id } = location.state as { id: number };

  return id ? <Legalize id={id} /> : <Navigate to="/404" replace />;
};

export default LegalizePage;
