/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect, useRef, useState } from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { BackButtonIcon, ScrollIcon } from '@/assets/icons/icons';
import Button from '@/components/ui/button';
import SpinnerLoader from '@/components/ui/spinnerLoader';
import { sessionContext } from '@/contexts/session';
import { useTranslation } from '@/hooks/useLocalTranslation';
import { PRAGMA } from '@/pages';

import Header from './header';
import SideBar from './sidebar';
import styles from './styles.module.scss';

export type TStateLocation = {
  id: number;
  previusPath: string;
};

const Template = () => {
  const { t } = useTranslation('pages');
  const { state } = useContext(sessionContext);
  const [authPage, setAuthPage] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [translationPath, setTranslationPath] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const [scroll, setscroll] = useState(0);
  const myRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (Object.keys(state.menu.data).length > 0 && state.menu.loaded) {
      let redirect = true;
      PRAGMA.forEach((app) => {
        // Validamos si la ruta es oculta
        app.routes.forEach((route) => {
          // Sacamos la primera página del pathname; ej. /approve/124 -> /approve
          // Aquí solo sirve a un nivel cuando 124 es dinámico, no serviría en
          // caso de una ruta oculta en /ruta/rutaOculta por ejemplo.
          if (route.path?.split('/')[1] === location.pathname.split('/')[1] && route.hidden) {
            setTranslationPath(`${app.name}.title.menu.${route.name}`);
            setAuthPage(true);
            redirect = false;
          }
        });

        // Validamos si la ruta está dentro del menu de usuario
        state.menu.data[app.name]?.forEach((menu) => {
          if (menu.path === location.pathname) {
            setTranslationPath(`${app.name}.title.menu.${menu.nombre}`);
            setAuthPage(true);
            redirect = false;
          }
        });
      });

      // Si ninguna de las validaciones se cumple redirecciona al 404
      if (redirect) navigate('/404', { replace: true });
    }
  }, [state.menu.data, state.menu.loaded, location, state.language]);

  if (state.login || state.menu.loading) return <SpinnerLoader />;

  const handleScroll = () => {
    setscroll(myRef.current?.scrollTop || 0);
  };

  return state.logged && state.menu.loaded && state.data && authPage ? (
    <div id="template" className={styles.template} onScroll={handleScroll} ref={myRef}>
      <Header setShowMenu={setShowMenu} />
      <div className={styles.main}>
        <div className={styles.container}>
          <SideBar showMenu={showMenu} setShowMenu={setShowMenu} />
          <div className={styles.page}>
            <h1
              className={
                location.pathname === '/' ||
                location.pathname === '/historial' ||
                location.pathname === '/liquidaciones' ||
                location.pathname === '/vistageneralvacaciones' ||
                location.pathname === '/vacacionespagas' ||
                location.pathname === '/solicitaranticipo' ||
                location.pathname === '/historialdemisanticipos' ||
                location.pathname === '/compensacion'
                  ? styles.title
                  : styles['title-custom']
              }
            >
              {location.pathname === '/detallesanticipo' ||
              location.pathname === '/facilitar' ||
              location.pathname === '/revisarlegalizacionanticipo' ? (
                <Button
                  name="ant-details_button-back"
                  className={styles['back']}
                  onClick={() => {
                    (location.state as TStateLocation)?.previusPath
                      ? navigate(`${(location.state as TStateLocation).previusPath}`, {
                          state: { previusPath: location.pathname },
                        })
                      : navigate(-1);
                  }}
                >
                  <BackButtonIcon width={16} height={16} />
                </Button>
              ) : null}
              {t(translationPath)}
            </h1>
            <div className={styles.body}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <div
        className={styles['button-pragma']}
        onClick={() => {
          window.open('https://pragma.workplace.com/search/top/?q=%23Pragma7000', '_blank');
        }}
        aria-hidden={true}
      >
        <p>#Pragma7000</p>
      </div>
      {scroll > 200 && (
        <div
          className={styles['button-scroll']}
          onClick={() => {
            if (myRef?.current?.scrollTop) {
              myRef.current.scrollTop = 0;
            }
          }}
          aria-hidden={true}
        >
          <ScrollIcon width={20} height={20} />
        </div>
      )}
    </div>
  ) : null;
};

export default Template;
