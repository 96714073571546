/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import Table from '@/components/table';
import TextArea from '@/components/ui/textArea';
import useAdvancesData from '@/hooks/useAdvancesData';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import type { Types } from '@/services/advances';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const DetailsCostCenter = ({ data }: { data: Types.TAdvanceDetails }) => {
  const t = useLocalTranslation(i18n as Resource);
  const { tableHeader } = useAdvancesData();

  return (
    <section className={styles['cost-center']}>
      {data.observacionLegalizacion && (
        <TextArea
          label={t('observations.label')}
          className={styles['description']}
          value={data.observacionLegalizacion}
          readOnly
        />
      )}
      <h3>{t('title')}</h3>
      <TextArea
        label={t('reason.label')}
        className={styles['description']}
        value={data.observacionGeneral}
        readOnly
      />
      <Table
        name="ant-details-costcenter_table"
        header={[
          { name: 'nombreNegocio', label: tableHeader('business') },
          { name: 'nombreMarca', label: tableHeader('brand') },
          { name: 'porcentaje', label: tableHeader('percent') },
        ]}
        data={data.centroCostos.map((item, _key) => ({
          _key,
          ...item,
          porcentaje: `${item.porcentaje}%`,
        }))}
      />
    </section>
  );
};
export default DetailsCostCenter;
