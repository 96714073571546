/**
 * Solicitudes Pragma SA
 */

import React, { createContext, useMemo, useReducer } from 'react';

import {
  getApproverByBusiness,
  getApprovers,
  getBusiness,
  getClients,
  getClientsByBusiness,
  getCoins,
  getCountries,
  getDocumentTypes,
  getFacilitators,
  getNonWorkingDays,
} from '@/services/catalogs';

import type * as Types from './types';

export { Types };

/* Actions Types */
enum Actions {
  SET_COUNTRIES = 'CATALOGS.SET_COUNTRIES',
  SET_BUSINESS = 'CATALOGS.SET_BUSINESS',
  SET_APPROVERS = 'CATALOGS.SET_APPROVERS',
  SET_NONWORKINGDAYS = 'CATALOGS.SET_NONWORKINGDAYS',
  SET_DOCUMENT_TYPES = 'CATALOGS.SET_DOCUMENT_TYPES',
  SET_FACILITATORS = 'CATALOGS.SET_FACILITATORS',
  SET_COINS = 'CATALOGS.SET_COINS',
  SET_APPROVERS_BY_BUSINESS = 'CATALOGS.SET_APPROVERS_BY_BUSINESS',
  SET_CLIENTS_BY_BUSINESS = 'CATALOGS.SET_CLIENTS_BY_BUSINESS',
  SET_CLIENTS = 'CATALOGS.SET_CLIENTS',
}

/* Initial State */
const initialState: Types.TState = {
  countries: [],
  business: [],
  approvers: {
    vacations: {},
    advances: {},
  },
  nonWorkingDays: {},
  documentTypes: [],
  facilitators: [],
  coins: [],
  approversByBusiness: {},
  clientsByBusiness: {},
  clients: [],
};

/* Reducer */
const reducer = (state: Types.TState, action: Types.TActions): Types.TState => {
  switch (action.type) {
    case Actions.SET_COUNTRIES: {
      return { ...state, countries: action.payload };
    }

    case Actions.SET_BUSINESS: {
      return { ...state, business: action.payload };
    }

    case Actions.SET_APPROVERS: {
      return {
        ...state,
        approvers: {
          ...state.approvers,
          [action.payload.app]: {
            ...state.approvers[action.payload.app],
            [action.payload.idRol]: action.payload.data,
          },
        },
      };
    }

    case Actions.SET_NONWORKINGDAYS: {
      return {
        ...state,
        nonWorkingDays: {
          ...state.nonWorkingDays,
          [action.payload.id]: action.payload.data,
        },
      };
    }

    case Actions.SET_DOCUMENT_TYPES: {
      return { ...state, documentTypes: action.payload };
    }

    case Actions.SET_FACILITATORS: {
      return { ...state, facilitators: action.payload };
    }

    case Actions.SET_COINS: {
      return { ...state, coins: action.payload };
    }

    case Actions.SET_APPROVERS_BY_BUSINESS: {
      return {
        ...state,
        approversByBusiness: {
          ...state.approversByBusiness,
          [action.payload.id]: action.payload.data,
        },
      };
    }

    case Actions.SET_CLIENTS_BY_BUSINESS: {
      return {
        ...state,
        clientsByBusiness: {
          ...state.clientsByBusiness,
          [action.payload.id]: action.payload.data,
        },
      };
    }

    case Actions.SET_CLIENTS: {
      return {
        ...state,
        clients: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export const catalogsContext = createContext({} as Types.TContext);

const CatalogsProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions: Types.IActions = {
    getCountries: async (form) => {
      if (state.countries.length === 0) {
        const payload = (await getCountries(form)) || state.countries;
        dispatch({ type: Actions.SET_COUNTRIES, payload });
        return payload;
      }
      return state.countries;
    },

    getBusiness: async () => {
      if (state.business.length === 0) {
        const payload = (await getBusiness()) || state.business;
        dispatch({ type: Actions.SET_BUSINESS, payload });
        return payload;
      }
      return state.business;
    },

    getApprovers: async (app, idRol) => {
      if (!state.approvers[app][idRol]) {
        const data = await getApprovers(app, idRol);
        dispatch({ type: Actions.SET_APPROVERS, payload: { app, idRol, data } });
        return data;
      }
      return state.approvers[app][idRol] || [];
    },

    getNonWorkingDays: async (id) => {
      if (!state.nonWorkingDays[id]) {
        const data = await getNonWorkingDays(id);
        dispatch({ type: Actions.SET_NONWORKINGDAYS, payload: { id, data } });
        return data;
      }
      return state.nonWorkingDays[id];
    },

    getDocumentTypes: async () => {
      if (state.documentTypes.length === 0) {
        const payload = (await getDocumentTypes()) || state.documentTypes;
        dispatch({ type: Actions.SET_DOCUMENT_TYPES, payload });
        return payload;
      }
      return state.documentTypes;
    },

    getFacilitators: async () => {
      if (state.facilitators.length === 0) {
        const payload = (await getFacilitators()) || state.facilitators;
        dispatch({ type: Actions.SET_FACILITATORS, payload });
        return payload;
      }
      return state.facilitators;
    },

    getCoins: async () => {
      if (state.coins.length === 0) {
        const payload = (await getCoins()) || state.coins;
        dispatch({ type: Actions.SET_COINS, payload });
        return payload;
      }
      return state.coins;
    },

    getApproverByBusiness: async (id) => {
      if (!state.approversByBusiness[id]) {
        const data = await getApproverByBusiness(id);
        dispatch({ type: Actions.SET_APPROVERS_BY_BUSINESS, payload: { id, data } });
        return data;
      }
      return state.approversByBusiness[id];
    },

    getClientsByBusiness: async (id) => {
      if (!state.clientsByBusiness[id]) {
        const data = await getClientsByBusiness(id);
        dispatch({ type: Actions.SET_CLIENTS_BY_BUSINESS, payload: { id, data } });
        return data;
      }
      return state.clientsByBusiness[id];
    },

    getClients: async () => {
      if (state.clients.length === 0) {
        const payload = (await getClients()) || state.clients;
        dispatch({ type: Actions.SET_CLIENTS, payload });
        return payload;
      }
      return state.clients;
    },
  };

  return (
    <catalogsContext.Provider value={useMemo(() => ({ state, actions }), [state, actions])}>
      {children}
    </catalogsContext.Provider>
  );
};

export default CatalogsProvider;
