/**
 * Solicitudes Pragma SA
 */

import React, { forwardRef, useId, Ref, useContext, useEffect, useState } from 'react';

import Select from '@/components/ui/select';
import { catalogsContext } from '@/contexts/catalogs';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import type { Types } from '@/services/catalogs';

import i18n from './i18n.json';
import styles from './styles.module.scss';

type TProps = {
  name?: string;
  onChange?: (value?: string) => void;
  isClearable?: boolean;
  value?: string;
  className?: string;
  setAllOption?: boolean;
  isDisabled?: boolean;
  business?: string;
  error?: string;
};

const SelectClient = (
  {
    name,
    onChange,
    isClearable,
    value,
    className,
    setAllOption,
    isDisabled,
    business,
    error,
  }: TProps,
  ref: Ref<HTMLLabelElement>,
) => {
  const {
    actions: { getClientsByBusiness, getClients },
  } = useContext(catalogsContext);
  const {
    actions: { addToast },
  } = useContext(utilsContext);
  const t = useLocalTranslation(i18n as Resource, 'selects');
  const uName = useId();
  const [options, setOptions] = useState<Types.TClient[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (business && business !== '_all') {
      setLoading(true);
      getClientsByBusiness(business)
        .finally(() => setLoading(false))
        .then((list) =>
          setOptions([
            ...(setAllOption
              ? [
                  {
                    id: '_all',
                    nombre: t('selectClient.options.all'),
                    centroCosto: 0,
                    activa: false,
                  },
                ]
              : []),
            ...list,
          ]),
        )
        .catch((e) => addToast(e.message, 'danger'));
    } else if (business === '_all') {
      setLoading(true);
      getClients()
        .finally(() => setLoading(false))
        .then((list) =>
          setOptions([
            ...(setAllOption
              ? [
                  {
                    id: '_all',
                    nombre: t('selectClient.options.all'),
                    centroCosto: 0,
                    activa: false,
                  },
                ]
              : []),
            ...list,
          ]),
        )
        .catch((e) => addToast(e.message, 'danger'));
    } else setOptions([]);
  }, [business]);

  return (
    <Select
      labelRef={ref}
      name={name || uName}
      label={t('selectClient.label')}
      placeholder={t('selectClient.placeholder')}
      noOptionsMessage={() => t('selectClient.noData')}
      className={`${styles['client']} ${className || ''}`}
      options={options}
      onChange={(option, actionMeta) => {
        if (actionMeta.action === 'clear') onChange && onChange(undefined);
        else if (option) onChange && onChange(option.id);
      }}
      getOptionLabel={(option) => option.nombre}
      value={value && options.length ? options.find((option) => option.id === value) : null}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={loading}
      error={error}
      noSort
    />
  );
};

export default forwardRef(SelectClient);
