/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect, useState } from 'react';

import { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';

import { TReassign } from '@/components/reassignModal';
import Modal from '@/components/ui/modal';
import Select from '@/components/ui/select';
import { catalogsContext } from '@/contexts/catalogs';
import { sessionContext } from '@/contexts/session';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Apps } from '@/pages';
import { reassignApproverAdvances } from '@/services/advances';
import { Types as CatalogsTypes, ROL } from '@/services/catalogs';

import i18n from './i18n.json';
import styles from './styles.module.scss';

type TProps = {
  reassign: TReassign;
  setReassign: (value: TReassign) => void;
  searchable?: boolean;
  search: (id: number) => void;
};

const ReassignModalApproverAdvances = ({
  reassign,
  setReassign,
  search,
  searchable = true,
}: TProps) => {
  const t = useLocalTranslation(i18n as Resource);
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const {
    state: { approvers },
    actions: { getApprovers },
  } = useContext(catalogsContext);
  const {
    actions: { getNotifications },
  } = useContext(sessionContext);
  const [approver, setApprover] = useState<CatalogsTypes.TApprover | null>(null);
  const [listLoading, setListLoading] = useState(false);

  const formatOptionLabel = (
    { nombre }: CatalogsTypes.TApprover,
    meta: FormatOptionLabelMeta<CatalogsTypes.TApprover>,
  ) => {
    if (meta.context === 'value') return nombre;
    return (
      <div className={styles['menu-approver']}>
        <div className={styles.info}>
          <span>{nombre}</span>
        </div>
      </div>
    );
  };

  const handleReassign = () => {
    if (reassign.id && approver?.id) {
      setLoading(true);
      reassignApproverAdvances(reassign.id, approver.id)
        .finally(() => setLoading(false))
        .then((res) => {
          setReassign({ show: false });
          addToast(res, 'success');
          getNotifications(Apps.ADVANCES);
          setApprover(null);
          search(reassign.id || 0);
        })
        .catch((e) => addToast(e.message, 'danger'));
    }
  };

  useEffect(() => {
    if (reassign?.show) {
      setListLoading(true);
      getApprovers(Apps.ADVANCES, ROL.advancesApprover)
        .finally(() => setListLoading(false))
        .catch((e) => addToast(e.message, 'danger'));
    }
  }, [reassign.show]);

  return (
    <Modal
      title={t('title')}
      successLabel={t('successLabel')}
      onClose={() => setReassign({ show: false })}
      show={reassign.show}
      onSuccess={() => handleReassign()}
    >
      <Select
        formatOptionLabel={formatOptionLabel}
        isSearchable={searchable}
        name="ant-change-approver_select-available-approvers"
        label={t('approvers.label')}
        placeholder={t('approvers.placeholder')}
        noOptionsMessage={() => t('approvers.noData')}
        options={approvers.advances[ROL.advancesApprover]}
        onChange={(approver) => {
          if (approver) setApprover(approver);
        }}
        value={approver}
        isLoading={listLoading}
      />
    </Modal>
  );
};

export default React.memo(ReassignModalApproverAdvances);
