/**
 * Solicitudes Pragma SA
 */

import React, { useState } from 'react';

import { formatISO } from 'date-fns';

import Button from '@/components/ui/button';
import calendar from '@/components/ui/calendar';
import TextField from '@/components/ui/textField';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Types } from '@/services/advances';

import i18n from './i18n.json';
import styles from './styles.module.scss';

type TProps = {
  handleSearch: (value: Types.TCompensationListForm, page?: number) => void;
};

const Filter = ({ handleSearch }: TProps) => {
  const [dateStart, setDateStart] = useState<Date | null>();
  const [dateEnd, setDateEnd] = useState<Date | null>();
  const [filter, setFilter] = useState('');
  const t = useLocalTranslation(i18n as Resource);

  const handleClick = () => {
    const params = {
      fechaInicio: dateStart ? formatISO(dateStart, { representation: 'date' }) : '',
      fechaFin: dateEnd ? formatISO(dateEnd, { representation: 'date' }) : '',
      nombreUsuario: '',
      documento: '',
    };
    if (isNaN(Number(filter))) {
      params.nombreUsuario = filter;
    } else {
      params.documento = filter;
    }
    handleSearch(params);
  };

  return (
    <div className={styles['filter']}>
      <TextField
        name="ant-compensation-reports_filter-document"
        label={t('name.label')}
        placeholder={t('name.placeholder')}
        onChange={({ currentTarget }) => setFilter(currentTarget.value)}
        value={filter}
      />
      <calendar.Input
        name="ant-compensation_inputdate-since"
        label={t('since.label')}
        placeholderText="mm/dd/yyyy"
        onChange={(day) => {
          setDateStart(day);
          setDateEnd(null);
        }}
        selected={dateStart}
      />
      <calendar.Input
        name="ant-compensation_inputdate-until"
        label={t('until.label')}
        placeholderText="mm/dd/yyyy"
        onChange={(day) => setDateEnd(day)}
        selected={dateEnd}
        minDate={dateStart}
        disabled={!dateStart}
      />
      <Button name="ant-compensation_button-search" onClick={handleClick} theme="primary-white">
        {t('actions.search')}
      </Button>
    </div>
  );
};

export default Filter;
