/**
 * Solicitudes Pragma SA
 */

import React, { useEffect, useState, useContext } from 'react';

import { formatISO } from 'date-fns';

import Table from '@/components/table';
import Button from '@/components/ui/button';
import Modal from '@/components/ui/modal';
import { utilsContext } from '@/contexts/utils';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import useList from '@/hooks/useList';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import useVacationsData from '@/hooks/useVacationsData';
import { Types, getVacationsExt, getOverviewReport } from '@/services/vacations';
import { getFileName, createFileBase64 } from '@/utils/downloadFile';

import Filter from './filter';
import i18n from './i18n.json';
import styles from './styles.module.scss';

const OverView = () => {
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const cancellablePromise = useCancellablePromise();
  const [confirmModal, setConfirmModal] = useState(false);
  const [list, setList, setListLoading] = useList<Types.TVacationItem>();
  const [form, setForm] = useState<Types.TGetVacationsFormExt>({
    estados: [],
    fechaInicio: '',
    fechaFinal: '',
    correoUsuario: '',
    idSolicitud: undefined,
    documentoSolicitante: '',
    correoAutorizador: '',
  });
  const t = useLocalTranslation(i18n as Resource);
  const { tableHeader } = useVacationsData();

  const requestList = (query?: Types.TGetVacationsFormExt, page = 0) => {
    setListLoading(true, !page);
    cancellablePromise(
      getVacationsExt({
        ...(query || form),
        numeroPagina: page,
        totalElementos: 20,
        fechaCreacionAscendente: true,
      })
        .finally(() => setListLoading(false))
        .then((res) => {
          setList(res.dato || [], !!page, res.cantidadPaginas, res.ultimaPagina, page);
        })
        .catch((e) => addToast(e.message, 'danger')),
    );
    if (query) setForm(query);
  };

  const handleDownload = () => {
    setLoading(true);
    getOverviewReport(form)
      .then((base64) => {
        createFileBase64(
          base64,
          getFileName(`reporte_vacaciones_${formatISO(new Date(), { representation: 'date' })}`),
        )
          .then(() => addToast(t('toast.documentSuccess'), 'success'))
          .catch((e) => addToast(e.message, 'danger'));
      })
      .catch((e) => addToast(e.message, 'danger'))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    requestList();
  }, [form]);

  return (
    <div className={styles['overview']}>
      <Filter handleSearch={requestList} />
      <div className={styles['actions']}>
        <Button
          name="vac-overview_button-download-file"
          disabled={list.count === 0}
          onClick={() => setConfirmModal(true)}
        >
          {t('actions.download')}
        </Button>
      </div>
      <Table
        name="vac-overview_table"
        header={[
          { name: 'id', label: tableHeader('numberRequest') },
          { name: 'cedula', label: tableHeader('identification') },
          { name: 'fechaPago', label: tableHeader('requestType') },
          { name: 'usuarioCorreo', label: tableHeader('requester') },
          { name: 'aprobadorCorreo', label: tableHeader('authorizer') },
          { name: 'fechaInicio', label: tableHeader('startDate') },
          { name: 'fechaFin', label: tableHeader('returnDate') },
          { name: 'cantidadDias', label: tableHeader('numberDays') },
          { name: 'estado', label: tableHeader('state') },
        ]}
        data={list.list.map((item) => ({
          _visible: item._visible,
          _key: item.id,
          id: item.id,
          cedula: item.cedula,
          fechaPago: item.fechaPago ? (
            <strong>{t('table.types.paid')}</strong>
          ) : (
            t('table.types.time')
          ),
          usuarioCorreo: item.usuarioCorreo?.split('@')[0],
          aprobadorCorreo: item.aprobadorCorreo?.split('@')[0],
          fechaInicio: item.fechaInicio || '-',
          fechaFin: item.fechaFin || '-',
          cantidadDias: item.cantidadDias,
          estado: item.estado.replace('_', ' '),
        }))}
        advanced={{ ...list, handleSearch: (page) => requestList(form, page) }}
        emptyLabel={`${t('table.noData')} 🚀`}
      />
      <Modal
        title={t('modal.title')}
        successLabel={t('modal.successLabel')}
        onClose={() => setConfirmModal(false)}
        show={confirmModal}
        onSuccess={() => {
          setConfirmModal(false);
          handleDownload();
        }}
      >
        {t('modal.description')}
        <ul className={styles['filters']}>
          {form.idSolicitud && (
            <li>
              <strong>{`${t('modal.filters.idRequest')}: `}</strong>
              {form.idSolicitud}
            </li>
          )}
          {form.documentoSolicitante !== '' && (
            <li>
              <strong>{`${t('modal.filters.document')}: `}</strong>
              {form.documentoSolicitante}
            </li>
          )}
          {form.correoAutorizador !== '' && (
            <li>
              <strong>{`${t('modal.filters.authorizer')}: `}</strong>
              {form.correoAutorizador}
            </li>
          )}
          {form.correoUsuario !== '' && (
            <li>
              <strong>{`${t('modal.filters.user')}: `}</strong>
              {form.correoUsuario || ''}
            </li>
          )}
          <li>
            <strong>{`${t('modal.filters.state')}: `}</strong>
            {form.estados[0] || 'Todos'}
          </li>
          {form.fechaInicio && (
            <li>
              <strong>{`${t('modal.filters.startDate')}: `}</strong>
              {form.fechaInicio}
            </li>
          )}
          {form.fechaFinal && (
            <li>
              <strong>{`${t('modal.filters.endDate')}: `}</strong>
              {form.fechaFinal}
            </li>
          )}
        </ul>
      </Modal>
    </div>
  );
};

export default OverView;
