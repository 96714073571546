/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useState } from 'react';

import Card from '@/components/card';
import { TReassign } from '@/components/reassignModal';
import ReassignModalApproverAdvances from '@/components/reassignModalApproverAdvances';
import StateLabel from '@/components/stateLabel';
import Button from '@/components/ui/button';
import TextField from '@/components/ui/textField';
import { utilsContext } from '@/contexts/utils';
import useAdvancesData from '@/hooks/useAdvancesData';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { getAdvanceItem, Types } from '@/services/advances';

import i18n from './i18n.json';
import styles from './styles.module.scss';

export const ChangeApproverAdvances = () => {
  const t = useLocalTranslation(i18n as Resource);
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const [idSearched, setIdSearched] = useState('');
  const [item, setItem] = useState<Types.TAdvanceItem[] | null>();
  const { cardInfo } = useAdvancesData();
  const [reassingModal, setReassingModal] = useState<TReassign>({ show: false });

  const handleSearch = (id: number) => {
    setLoading(true);
    getAdvanceItem({
      idAnticipo: Number(id || idSearched),
      totalElementos: 1,
      numeroPagina: 0,
    })
      .finally(() => setLoading(false))
      .then((res) => {
        if (res?.length === 0) {
          throw new Error('No existe esta solicitud');
        }
        setItem(res);
      })
      .catch((e) => {
        if (idSearched) {
          addToast(e.message, 'danger');
        }
        setItem(undefined);
      });
  };

  return (
    <section className={styles['search']}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch(0);
        }}
      >
        <TextField
          label={t('request.label')}
          placeholder={t('request.placeholder')}
          keyPattern={/^[0-9]*$/}
          onChange={(e) => setIdSearched(e.currentTarget.value)}
          value={idSearched}
        />
        <Button name="ant-search_button" onClick={() => handleSearch(0)}>
          {t('actions.search')}
        </Button>
      </form>
      {item &&
        item.map((advance, index) => (
          <div key={index} className={styles['content']}>
            <Card
              overflow
              key={advance.id}
              header={{
                id: advance.id,
                photo: advance.fotografiaUsuarioSolicitante?.contenido,
                name: advance.nombreUsuario,
                email: advance.correoUsuario,
                date: new Date(advance.fechaCreacion),
              }}
              data={cardInfo(advance)}
              state={
                <div className={styles['state']}>
                  {t('request.stateLabel')}
                  <StateLabel state={advance.estado} />
                </div>
              }
              actions={[
                <Button
                  key="ant-administrator_button-reassign"
                  name="ant-administrator_button-reassign"
                  onClick={() => setReassingModal({ id: advance.id, show: true })}
                >
                  {t('actions.reassign')}
                </Button>,
              ]}
            />
          </div>
        ))}
      <ReassignModalApproverAdvances
        reassign={reassingModal}
        setReassign={setReassingModal}
        searchable={false}
        search={handleSearch}
      />
    </section>
  );
};
