/**
 * Solicitudes Pragma SA
 */

import React, { useState, useContext, useEffect } from 'react';

import Table from '@/components/table';
import Button from '@/components/ui/button';
import { utilsContext } from '@/contexts/utils';
import useAdvancesData from '@/hooks/useAdvancesData';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import useList from '@/hooks/useList';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Types, getTreasuryHistoryList, generateTreasuryHistoryReport } from '@/services/advances';
import { createFileBase64, getFileName } from '@/utils/downloadFile';

import Filter from './filter';
import i18n from './i18n.json';
import styles from './styles.module.scss';

const TreasuryHistory = () => {
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const cancellablePromise = useCancellablePromise();
  const [list, setList, setListLoading] = useList<Types.TTreasuryHistoryItem>();
  const [form, setForm] = useState<Types.TTreasuryHistoryListForm>({
    fechaInicio: '',
    fechaFinal: '',
  });
  const t = useLocalTranslation(i18n as Resource);
  const { tableHeader } = useAdvancesData();

  const handleDownload = () => {
    setLoading(true);
    generateTreasuryHistoryReport({
      ...form,
      fechaOrdenAscendente: true,
    })
      .then((base64) => {
        createFileBase64(base64, getFileName('reporte_tesoreria'))
          .then(() => addToast(t('toast.documentSuccess'), 'success'))
          .catch((e) => addToast(e.message, 'danger'));
      })
      .catch((e) => addToast(e.message, 'danger'))
      .finally(() => setLoading(false));
  };

  const requestList = (query?: Types.TTreasuryHistoryListForm, page = 0) => {
    setListLoading(true, !page);
    cancellablePromise(
      getTreasuryHistoryList({
        ...(query || form),
        numeroPagina: page,
        totalElementos: 10,
        fechaOrdenAscendente: true,
      })
        .finally(() => setListLoading(false))
        .then((res) => {
          setList(res.dato || [], !!page, res.cantidadPaginas, res.ultimaPagina, page);
        })
        .catch((e) => addToast(e.message, 'danger')),
    );
    if (query) setForm(query);
  };

  useEffect(() => {
    requestList();
  }, []);

  return (
    <div className={styles['history']}>
      <Filter handleSearch={requestList} />
      <div className={styles['actions']}>
        <Button name="ant-treasury_button-download" onClick={handleDownload}>
          {t('actions.download')}
        </Button>
      </div>
      <Table
        name="ant-treasury-history_table"
        header={[
          { name: 'id', label: tableHeader('id') },
          { name: 'fechaConsignacion', label: tableHeader('consignmentDate') },
          { name: 'documentoUsuario', label: tableHeader('idUser') },
          { name: 'correoUsuario', label: tableHeader('applicantEmail') },
          { name: 'correoUsuarioAutorizador', label: tableHeader('authorizerEmail') },
          { name: 'montoTotal', label: tableHeader('amountRequest') },
          { name: 'centroDeCostos', label: tableHeader('costCenter') },
        ]}
        data={list.list.map((item) => ({
          _key: item.id,
          ...item,
          correoUsuario: item.correoUsuario?.split('@')[0],
          correoUsuarioAutorizador: item.correoUsuarioAutorizador?.split('@')[0],
          montoTotal: `$ ${new Intl.NumberFormat('es-CO').format(item.montoTotal)} ${
            item.codigoMoneda
          }`,
          centroDeCostos: item.centroDeCostos?.join(', '),
        }))}
        rowStyle={{ montoTotal: { whiteSpace: 'nowrap' } }}
        advanced={{ ...list, handleSearch: (page) => requestList(form, page) }}
        emptyLabel={`${t('table.noData')} 🚀`}
      />
    </div>
  );
};

export default TreasuryHistory;
