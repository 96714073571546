/**
 * Solicitudes Pragma SA
 */

import React, { useEffect, useContext, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import Form from '@/app/advances/request/form/costCenter/form';
import Button from '@/components/ui/button';
import TextArea from '@/components/ui/textArea';
import { catalogsContext } from '@/contexts/catalogs';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';

import { TForm } from '..';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const CostCenter = () => {
  const t = useLocalTranslation(i18n as Resource);
  const { state } = useContext(catalogsContext);
  const {
    register,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<TForm>();

  const costCenter = watch('centroCostos') || [];
  const totalPercent = watch('_costCenterPercent') || 0;
  const [isAccept, setIsAccept] = useState(false);

  const addNewBusiness = () => {
    const tmp = [...costCenter];
    const newObject = {
      idNegocio: '',
      nombreNegocio: '',
      idMarca: '',
      nombreMarca: '',
      porcentaje: 0,
    };
    tmp.push(newObject);
    setValue('centroCostos', tmp);
  };

  useEffect(() => {
    clearErrors('_costCenterPercent');
    if (costCenter.length) {
      setValue(
        '_costCenterPercent',
        costCenter.map((item) => item.porcentaje).reduce((prev = 0, next = 0) => prev + next),
      );
    } else {
      setValue('_costCenterPercent', 0);
      setIsAccept(false);
    }
  }, [costCenter]);

  useEffect(() => {
    const lastIndex = costCenter.length - 1;
    if (totalPercent !== 100) {
      setIsAccept(false);
    }
    if (totalPercent === 100 && costCenter.length > 1) {
      const newArray = [...costCenter];
      newArray.pop();
      setValue('centroCostos', newArray);
    } else if (totalPercent !== 100 && costCenter[lastIndex]?.idNegocio !== '') {
      addNewBusiness();
    }
  }, [totalPercent]);

  const handleEditPercent = (percent: number, index: number) => {
    const tmp = [...costCenter];
    tmp[index].porcentaje = percent;
    setValue('centroCostos', tmp);
  };

  const handleEditProject = (property: 'idMarca' | 'idNegocio', value: string, index: number) => {
    const tmp = [...costCenter];
    if (property === 'idNegocio') {
      tmp[index].idNegocio = value;
      tmp[index].nombreNegocio = state.business.find((x) => x.id === value)?.nombre || '-';
    } else {
      const equalBrand = tmp.find((element) => element.idMarca === value);
      if (!equalBrand) {
        tmp[index].idMarca = value;
        tmp[index].nombreMarca =
          state.clientsByBusiness[tmp[index].idNegocio].find((x) => x.id === value)?.nombre || '-';
      }
    }
    setValue('centroCostos', tmp);
  };

  const handleDelete = (index: number) => {
    const tmp = [...costCenter];
    tmp.splice(index, 1);
    tmp.push({
      idNegocio: '',
      nombreNegocio: '',
      idMarca: '',
      nombreMarca: '',
      porcentaje: 0,
    });
    setValue('centroCostos', tmp);
  };

  const handleAddProject = (business: string | undefined, client: string | undefined) => {
    if (business && client && totalPercent < 100) {
      addNewBusiness();
    }
  };

  return (
    <section className={styles['cost-center']}>
      <h3>{t('title')}</h3>
      <TextArea
        label={t('fields.reason.label')}
        placeholder={t('fields.reason.placeholder')}
        helper={`${(watch('observacionGeneral') || '').length}/500 ${t(
          'fields.reason.description',
        )}`}
        error={errors['observacionGeneral']?.message}
        className={styles.description}
        {...register('observacionGeneral', {
          required: t('formValidation.required'),
          maxLength: 500,
        })}
      />
      <form
        {...register('_costCenterPercent', {
          validate: (value) =>
            value === 100 ||
            (value === 0 ? t('formValidation.requiredError') : t('formValidation.validation')),
        })}
      >
        {costCenter.length ? (
          <div className={styles['column']}>
            {costCenter.map((element, index) => (
              <Form
                key={index}
                handleAddProject={handleAddProject}
                costCenter={costCenter}
                index={index}
                handleEditPercent={handleEditPercent}
                handleEditProject={handleEditProject}
                isAccept={isAccept}
                handleDelete={handleDelete}
                totalPercent={totalPercent}
              />
            ))}
            {costCenter[0].idNegocio !== '' && !isAccept && (
              <div className={styles['reminder-actions']}>
                <p className={styles['validation']}>{t('formValidation.validation')}</p>
                <div className={styles['actions']}>
                  <Button
                    name="ant-request_button-accept"
                    disabled={
                      costCenter[0].idMarca.length < 1 ||
                      (costCenter.length !== 1 ? totalPercent !== 100 : false)
                    }
                    onClick={() => {
                      const tmp = [...costCenter];
                      if (costCenter.length === 1) {
                        tmp[0].porcentaje = 100;
                        setValue('centroCostos', tmp);
                      }
                      const elementZero = tmp.findIndex((element) => element.porcentaje === 0);
                      if (elementZero !== -1) {
                        tmp.splice(elementZero, 1);
                        setValue('centroCostos', tmp);
                      }
                      setIsAccept(true);
                    }}
                  >
                    {t('actions.accept')}
                  </Button>
                  <Button
                    name="ant-request_button-delete"
                    theme="secondary"
                    onClick={() => {
                      setValue('centroCostos', [
                        {
                          idNegocio: '',
                          nombreNegocio: '',
                          idMarca: '',
                          nombreMarca: '',
                          porcentaje: 0,
                        },
                      ]);
                    }}
                  >
                    {t('actions.delete')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
      </form>
      {errors['_costCenterPercent'] && (
        <div className={styles['error']}>{errors['_costCenterPercent']?.message}</div>
      )}
    </section>
  );
};
export default CostCenter;
