/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useState } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/ui/button';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Types, addAdvance } from '@/services/advances';

import CostCenter from './costCenter';
import Hosts from './hosts';
import i18n from './i18n.json';
import Info from './info';
import Money from './money';
import styles from './styles.module.scss';
import Tickets from './tickets';

export type TForm = Types.TAddAdvanceForm & {
  _costCenterPercent: number; // Validacion % total
  _tickets: boolean;
  _airportFrom: boolean;
  _airportTo: boolean;
};

const AdvanceRequestForm = () => {
  const t = useLocalTranslation(i18n as Resource);
  const { actions } = useContext(utilsContext);
  const form = useForm<TForm>({
    defaultValues: {
      _costCenterPercent: 0,
      observacionGeneral: '',
      centroCostos: [
        {
          idNegocio: '',
          nombreNegocio: '',
          idMarca: '',
          nombreMarca: '',
          porcentaje: 0,
        },
      ],
      idUsuario: '',
      idUsuarioAutorizador: '',
      fechaConsignacion: '',
      ciudadResidencia: undefined,
      costoTransporte: 0,
      costoAlimentacion: 0,
      costoOtro: 0,
      tipoMoneda: undefined,
      tiquetes: [],
      hospedajes: [],
      necesitaRoaming: false,
      necesitaTransporte: false,
      solicitaEfectivo: false,
      tallaMaleta: 'XS',
    },
  });
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  const createRequest: SubmitHandler<TForm> = (data) => {
    if (
      data.costoAlimentacion + data.costoTransporte + data.costoOtro === 0 &&
      data.tiquetes.length === 0 &&
      data.hospedajes.length === 0
    ) {
      setError(true);
    } else setError(false);
    actions.setLoading(true);
    addAdvance(data)
      .finally(() => actions.setLoading(false))
      .then((res) => {
        actions.addToast(res, 'success');
        navigate('/solicitudesanticipos');
      })
      .catch((e) => actions.addToast(e.message, 'danger'));
  };

  return (
    <div className={styles['form']}>
      <FormProvider {...form}>
        <CostCenter />
        <Info />
        <Money />
        <Tickets />
        <Hosts />
        <p className={styles['reminder']}>
          <strong>{t('reminder.title')} </strong>
          {t('reminder.description_one')}
          <strong>{t('reminder.bill')}</strong>
          {t('reminder.description_two')}
          <strong> {`${t('reminder.important')} 😊`}</strong>
        </p>
        {error && <div className={styles['error']}>{t('error')}</div>}
        <Button
          name="ant-request_button-create-request"
          className={styles['action']}
          onClick={form.handleSubmit(createRequest)}
        >
          {t('actions.create')}
        </Button>
      </FormProvider>
    </div>
  );
};
export default AdvanceRequestForm;
