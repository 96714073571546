/**
 * Solicitudes Pragma SA
 */

import React, { useState } from 'react';

import Modal from '@/components/ui/modal';
import TextArea from '@/components/ui/textArea';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';

import i18n from './i18n.json';
import styles from './styles.module.scss';

export type TDeny = {
  id: string | number;
  show: boolean;
};

type TProps = {
  deny: TDeny;
  setDeny: (value: TDeny) => void;
  handleDeny: (id: string | number, reason: string) => void;
  disabledLoading?: boolean;
};

const DenyModal = ({ deny, setDeny, handleDeny, disabledLoading }: TProps) => {
  const [reason, setReason] = useState('');
  const t = useLocalTranslation(i18n as Resource, 'denyModal');

  return (
    <Modal
      title={t('title')}
      successLabel={t('actions.success')}
      onClose={() => setDeny({ id: deny.id, show: false })}
      show={deny.show}
      onSuccess={() => handleDeny(deny.id, reason)}
      successDisabled={reason.length > 500 || reason.length < 3 || disabledLoading}
    >
      <TextArea
        name="deny-modal_textarea-rejection-reason"
        className={styles['deny']}
        value={reason}
        helper={`${reason.length}/500 ${t('textArea.characters')}`}
        placeholder={t('textArea.placeholder')}
        onChange={(e) => setReason(e.currentTarget.value)}
      />
    </Modal>
  );
};

export default DenyModal;
