/**
 * Solicitudes Pragma SA
 */

import React, { Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';

import { utilsContext } from '@/contexts/utils';

import styles from './styles.module.scss';

import WhatsAppButton from '@/components/whatsAppButton';

import { phoneNumberPragma } from '@/services/advances';

export interface IPropsTabTitle {
  title: string;
  index: number;
  total: number;
  selectedTab: number;
  setSelectedTab: (index: number) => void;
  notifications?: number;
  setScroll: (value: number) => void;
  hover?: boolean;
  setHover?: Dispatch<SetStateAction<boolean>>;
}

const TabTitleComponent = ({
  title,
  setSelectedTab,
  index,
  selectedTab,
  notifications,
  setScroll,
  hover,
  setHover,
}: IPropsTabTitle) => {
  const onClick = useCallback(() => {
    setSelectedTab(index);
    setScroll(0);
    setHover && setHover(false);
  }, [setSelectedTab, index]);

  if (title === 'empty') {
    return null;
  }

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.container}>
          <button
            onFocus={() => selectedTab !== index && setHover && setHover(true)}
            onMouseOver={() => selectedTab !== index && setHover && setHover(true)}
            onMouseLeave={() => selectedTab !== index && setHover && setHover(false)}
            type="button"
            onClick={onClick}
            className={`${styles.title} ${selectedTab === index && styles.active} ${
              hover && selectedTab === index && styles['active-custom']
            }`}
          >
            {title}
          </button>
          {!!notifications && <span className={styles.notifications}>{notifications}</span>}
        </div>
        <span className={`${selectedTab === index && styles.lighting}`}></span>
      </div>
    </>
  );
};

export type TSubMenu = {
  name: string;
  title: string;
  element: React.ReactNode;
  notifications?: number;
};

const TabComponent = ({ subMenus, isMain }: { subMenus: TSubMenu[]; isMain?: boolean }) => {
  const {
    actions: { setCurrentTab, setScroll },
    state: { currentTab },
  } = useContext(utilsContext);
  const [hover, setHover] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <section className={styles.tab}>
      <WhatsAppButton phoneNumberPragma={phoneNumberPragma} />
      <div className={styles.header}>
        {subMenus.map((menu, index) => (
          <TabTitleComponent
            hover={hover}
            setHover={setHover}
            key={menu.name}
            title={menu.title}
            index={index}
            setScroll={setScroll}
            setSelectedTab={isMain ? setCurrentTab : setSelectedTab}
            total={subMenus.length}
            selectedTab={isMain ? currentTab : selectedTab}
            notifications={menu.notifications}
          />
        ))}
      </div>
      {isMain ? subMenus[currentTab]?.element : subMenus[selectedTab]?.element}
    </section>
  );
};

export default TabComponent;
