/**
 * Solicitudes Pragma SA
 */

import React, { useState } from 'react';

import SelectBusiness from '@/components/selects/selectBusiness';
import SelectClient from '@/components/selects/selectClient';
import SelectMonth from '@/components/selects/selectMonth';
import SelectYear from '@/components/selects/selectYear';
import Button from '@/components/ui/button';
import TextField from '@/components/ui/textField';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { getAdminReportYears, Types } from '@/services/advances';
import formatDate from '@/utils/formatDate';

import i18n from './i18n.json';
import styles from './styles.module.scss';

type TProps = {
  handleSearch: (value: Types.TAdminReportFilter, page?: number) => void;
};

type TParams = {
  year?: number;
  month?: number;
  document: string;
  business?: string;
  client?: string;
};

const Filter = ({ handleSearch }: TProps) => {
  const t = useLocalTranslation(i18n as Resource);
  const [params, setParams] = useState<TParams>({
    year: -1,
    month: -1,
    document: '',
    business: '_all',
    client: '_all',
  });

  return (
    <div className={styles['filter']}>
      <div className={styles['row']}>
        <SelectBusiness
          onChange={(business) => setParams({ ...params, business, client: '_all' })}
          value={params.business}
          setAllOption
        />
        <SelectClient
          onChange={(option) => setParams({ ...params, client: option })}
          value={params.client}
          business={params.business}
          setAllOption
        />
      </div>
      <div className={styles['row']}>
        <SelectYear
          years={getAdminReportYears}
          onChange={(year) => {
            if (year !== undefined) setParams({ ...params, year, month: -1 });
            else setParams({ ...params, year });
          }}
          value={params.year}
          setAllOption
          searchable={false}
        />
        <SelectMonth
          onChange={(month) => setParams({ ...params, month })}
          value={params.month}
          setAllOption
          isDisabled={params.year === -1}
          searchable={false}
        />
        <TextField
          name="ant-administrator-reports_filter-document"
          label={t('document.label')}
          placeholder={t('document.placeholder')}
          className={styles['document']}
          onChange={(e) => setParams({ ...params, document: e.currentTarget.value })}
          value={params.document}
        />
        <Button
          className={styles['button']}
          name="ant-administrator-reports_filter-search"
          theme="primary-white"
          onClick={() =>
            handleSearch({
              documentoSolicitante: params.document,
              idNegocio: params.business === '_all' ? '' : params.business,
              idMarca: params.client === '_all' ? '' : params.client,
              ...formatDate(params.month, params.year),
            })
          }
        >
          {t('actions.search')}
        </Button>
        <Button
          className={styles['button']}
          name="ant-administrator-reports_clear-search"
          onClick={() => {
            handleSearch({
              documentoSolicitante: '',
              idNegocio: '',
              idMarca: '',
              fechaInicio: '',
              fechaFinal: '',
            });
            setParams({
              year: -1,
              month: -1,
              document: '',
              business: '_all',
              client: '_all',
            });
          }}
          theme="tertiary"
        >
          {t('actions.clear')}
        </Button>
      </div>
    </div>
  );
};

export default Filter;
