/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import Overview from '@/app/vacations/overview';

const OverviewPage = () => <Overview />;

export default OverviewPage;
