/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect, useState } from 'react';

import { getDay, addDays, addYears, formatISO } from 'date-fns';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Salute from '@/components/salute';
import Button from '@/components/ui/button';
import calendar from '@/components/ui/calendar';
import { catalogsContext } from '@/contexts/catalogs';
import { sessionContext } from '@/contexts/session';
import { utilsContext } from '@/contexts/utils';
import useFormatDate from '@/hooks/useFormatDate';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { calculateWorkingDays } from '@/services/catalogs';
import endpoints from '@/services/endpoints';
import { Types, addVacations, getVacationsDays } from '@/services/vacations';

import VacationRequestForm from './form';
import i18n from './i18n.json';
import styles from './styles.module.scss';

const VacationRequestContent = () => {
  const navigate = useNavigate();
  const {
    state: { data },
  } = useContext(sessionContext);
  const {
    actions: { getNonWorkingDays },
  } = useContext(catalogsContext);
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const form = useForm<Types.TAddVacationForm>({
    defaultValues: {
      observaciones: '',
      fechaInicio: '',
      fechaFin: '',
      paisId: '',
      vicepresidenciaId: '',
      aprobadorId: '',
    },
  });
  const [days, setDays] = useState('0');
  const [disabledDays, setDisabledDays] = useState<Date[]>([]);
  const [disableCalendarClick, setDisableCalendarClick] = useState(true);
  const [availableDays, setAvailableDays] = useState('...');
  const [idPais, setIdPais] = useState<number>();
  const [weekends, setWeekends] = useState<{ sat: boolean; sun: boolean }>({
    sat: false,
    sun: false,
  });
  const [selected, setSelected] = useState<{ from?: Date | null; to?: Date | null }>({});
  const l = useLocalTranslation(i18n as Resource);
  const formatDate = useFormatDate();

  const country = form.getValues('paisId');
  const startDay = form.getValues('fechaInicio');
  const endDay = form.getValues('fechaFin');

  useEffect(() => {
    if (country !== '') {
      getNonWorkingDays(country)
        .then((res) => {
          if (res) {
            setWeekends({ sat: res.sabado, sun: res.domingo });
            const holidays = res.festivos.map((date) => {
              const day = new Date(date);
              return new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1); // UTC Fix
            });
            setDisabledDays([new Date(), ...holidays]);
            setDisableCalendarClick(false);
          }
        })
        .catch((err) => {
          setDisableCalendarClick(true);
          addToast(err.message, 'danger');
        });
    } else {
      setDisableCalendarClick(true);
    }
  }, [country]);

  useEffect(() => {
    if (startDay !== '' && endDay !== '') {
      setDays('Calculando...');
      setDisableCalendarClick(true);
      calculateWorkingDays({
        fechaInicial: startDay,
        fechaFinal: endDay,
        idPais: country,
      })
        .finally(() => setDisableCalendarClick(false))
        .then((res) => setDays(String(res)))
        .catch((err) => {
          setDays('ERROR');
          addToast(err.message, 'danger');
        });
    } else setDays('0');
  }, [startDay, endDay, country]);

  const createRequest: SubmitHandler<Types.TAddVacationForm> = (submitData) => {
    setLoading(true);
    addVacations(submitData)
      .finally(() => setLoading(false))
      .then((res) => {
        addToast(res, 'success');
        navigate('/solicitudesvacaciones');
      })
      .catch((err) => addToast(err.message, 'danger'));
  };

  useEffect(() => {
    getVacationsDays(data?.correoEmpresarial || '')
      .then((res) => {
        setAvailableDays(String(res?.diasVacaciones || '...'));
        setIdPais(res?.idPais);
      })
      .catch((err) => addToast(err.message, 'danger'));
  }, [data]);

  return (
    <section className={styles['vacations-request']}>
      <Salute
        msg={l('salute.msg')}
        extra={l('salute.extra')}
        photo={`${endpoints.GET_MULTIMEDIA}/${data?.fotografias[0]?.contenido}`}
        name={`${data?.nombres} ${data?.apellidos}`}
        distribution
      >
        <div className={styles['container-available-days']}>
          <h3 className={styles['days-title']}>{l('salute.availableDays')}</h3>
          <div className={styles['container-days']}>
            <div className={styles['days-data']}>{availableDays}</div>
          </div>
        </div>
      </Salute>
      <form>
        <FormProvider {...form}>
          <div className={styles['form']}>
            <h2>{l('form.title')}</h2>
            <VacationRequestForm idPais={idPais} isDisabled />
            <Button
              name="vac-request_button-create-request"
              className={styles['send']}
              onClick={form.handleSubmit(createRequest)}
            >
              {l('actions.send')}
            </Button>
          </div>
          <div className={styles['separator']} />
          <div className={styles['calendar']}>
            <h2>{l('calendar.title')}</h2>
            <Controller
              control={form.control}
              name="fechaFin"
              rules={{ required: l('formValidation.required') }}
              render={({ field: { ref, name } }) => (
                <calendar.InlineRange
                  name={name}
                  labelRef={ref}
                  helper={l('calendar.range')}
                  inline
                  selectsRange
                  disabled={disableCalendarClick}
                  minDate={addDays(new Date(), 1)}
                  maxDate={disableCalendarClick ? new Date() : addYears(new Date(), 1)}
                  excludeDates={disabledDays}
                  filterDate={(date) => {
                    const day = getDay(date);
                    if (weekends.sun && weekends.sat) {
                      return true;
                    } else if (weekends.sun && !weekends.sat) {
                      return day !== 6;
                    } else if (!weekends.sun && weekends.sat) {
                      return day !== 0;
                    } else {
                      return day !== 0 && day !== 6;
                    }
                  }}
                  onChange={([from, to]) => {
                    setSelected({ from, to });
                    form.clearErrors('fechaFin');
                    form.setValue(
                      'fechaInicio',
                      from ? formatISO(from, { representation: 'date' }) : '',
                    );
                    form.setValue('fechaFin', to ? formatISO(to, { representation: 'date' }) : '');
                  }}
                  error={form.formState.errors['fechaFin']?.message}
                />
              )}
            />
            <div className={styles.resume}>
              <span>{selected.to && `${l('calendar.until')}: `}</span>
              <strong>
                {selected.to && `${formatDate(selected.to)} (${days} ${l('calendar.days')})`}
              </strong>
            </div>
          </div>
        </FormProvider>
      </form>
    </section>
  );
};

export default VacationRequestContent;
