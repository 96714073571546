/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import Liquidations from '@/app/vacations/liquidations';

const LiquidationsPage = () => <Liquidations />;

export default LiquidationsPage;
