/**
 * Solicitudes Pragma SA
 */

/**
 * Transform object into URL search params
 *
 * @param params Object
 * @returns URLSearchParams
 */
export default (params: Record<string, unknown>): URLSearchParams => {
  const newParams = new URLSearchParams();

  Object.keys(params).forEach((key) => {
    if (
      params[key] !== undefined &&
      (params[key] as Record<string, unknown>).toString().trim().length > 0
    ) {
      if (Array.isArray(params[key])) {
        (params[key] as []).forEach((value: string) => {
          newParams.append(key, value);
        });
      } else {
        newParams.append(key, params[key] as string);
      }
    }
  });
  return newParams;
};
