/**
 * Solicitudes Pragma SA
 */

import React, { useEffect, useState, useContext } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import Card from '@/components/card';
import DenyModal, { TDeny } from '@/components/denyModal';
import InfiniteScrollList from '@/components/infiniteScroll';
import StateLabel from '@/components/stateLabel';
import Button from '@/components/ui/button';
import Modal from '@/components/ui/modal';
import { sessionContext } from '@/contexts/session';
import { utilsContext } from '@/contexts/utils';
import useAdvancesData from '@/hooks/useAdvancesData';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import useList from '@/hooks/useList';
import useLocalTranslation, { Resource, useTranslation } from '@/hooks/useLocalTranslation';
import { Apps } from '@/pages';
import { Types, approveAdvance, denyAdvance, getAdvancesList } from '@/services/advances';
import { TStateLocation } from '@/template';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const PendingTeam = () => {
  const {
    actions: { getNotifications, getPermissions },
  } = useContext(sessionContext);
  const {
    actions: { addToast, setLoading, setScroll },
    state: { scroll },
  } = useContext(utilsContext);
  const cancellablePromise = useCancellablePromise();
  const [list, setList, setListLoading, setVisible] = useList<Types.TAdvanceItem>();
  const [denyModal, setDenyModal] = useState<TDeny>({ id: '', show: false });
  const [confirmModal, setConfirmModal] = useState<number>();
  const { t } = useTranslation('advances');
  const l = useLocalTranslation(i18n as Resource);
  const { cardInfo } = useAdvancesData();
  const navigate = useNavigate();
  const [localScroll, setlocalScroll] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (!(location.state as TStateLocation)?.previusPath) {
      setScroll(0);
    }
  }, [location]);

  const requestList = (page = 0) => {
    setListLoading(true, !page);
    cancellablePromise(
      getAdvancesList({
        estados: ['PENDIENTE', 'EN_REVISION'],
        numeroPagina: page,
        totalElementos: scroll > 0 ? Math.ceil(scroll / 150) + 2 : 5,
        fechaOrdenAscendente: true,
        usuarioAprobador: true,
        revisionAprobador: false,
      })
        .finally(() => setListLoading(false))
        .then((res) => {
          setList(res.dato || [], !!page, res.cantidadPaginas, res.ultimaPagina, page);
        })
        .catch((e) => addToast(e.message, 'danger')),
    );
  };

  useEffect(() => {
    requestList();
  }, []);

  useEffect(() => {
    if (!list.lastPage && !list.isAnyVisible) {
      requestList();
    }
  }, [list.isAnyVisible, list.lastPage]);

  const handleApprove = (id: number) => {
    setLoading(true);
    approveAdvance(id)
      .finally(() => setLoading(false))
      .then((res) => {
        addToast(res, 'success');
        setVisible({ id }, false);
        getNotifications(Apps.ADVANCES);
      })
      .catch((e) => addToast(e.message, 'danger'));
  };

  const handleDeny = (id: number, reason: string) => {
    setLoading(true);
    denyAdvance(id, reason)
      .finally(() => {
        setLoading(false);
        setDenyModal({ id, show: false });
      })
      .then((res) => {
        addToast(res, 'success');
        setVisible({ id }, false);
        getNotifications(Apps.ADVANCES);
      })
      .catch((e) => addToast(e.message, 'danger'));
  };

  return (
    <>
      <div className={styles['refresh']}>
        <Button
          className={styles['button']}
          name="ant-search_button_search"
          onClick={() => requestList()}
        >
          {l('actionsRefresh.refresh')}
        </Button>
      </div>
      <InfiniteScrollList list={list} handleNext={requestList} setLocalScroll={setlocalScroll}>
        {list.list.map((item) => (
          <Card
            key={item.id}
            _visible={item._visible}
            header={{
              id: item.id,
              photo: item.fotografiaUsuarioSolicitante?.contenido,
              name: item.nombreUsuario,
              email: item.correoUsuario,
              date: new Date(item.fechaCreacion),
            }}
            data={cardInfo(item)}
            state={
              <div className={styles['state-info']}>
                {t('state')}
                <StateLabel state={item.estado} />
              </div>
            }
            actions={
              getPermissions('poraprobardemiequipoanticipos').actualizar &&
              item.estado !== 'EN_REVISION'
                ? [
                    <Button
                      key="ant-request-for-approval_button-reject"
                      name="ant-request-for-approval_button-reject"
                      theme="secondary"
                      onClick={() => setDenyModal({ id: item.id, show: true })}
                    >
                      {l('actions.reject')}
                    </Button>,
                    <Button
                      key="ant-request-for-approval_button-approve"
                      name="ant-request-for-approval_button-approve"
                      onClick={() => setConfirmModal(item.id)}
                    >
                      {l('actions.approve')}
                    </Button>,
                    <Button
                      name="ant-pending-team_button-details"
                      key="ant-pending-team_button-details"
                      className={styles['details']}
                      onClick={() => {
                        setScroll(localScroll);
                        navigate('/detallesanticipo', {
                          state: {
                            id: item.id,
                            permissions: getPermissions('poraprobardemiequipoanticipos'),
                            previusPath: location.pathname,
                          },
                        });
                      }}
                      theme="tertiary"
                    >
                      {l('actions.seeDetails')}
                    </Button>,
                  ]
                : item.estado === 'EN_REVISION'
                ? [
                    <Button
                      key="ant-request-for-approval_button-review"
                      name="ant-request-for-approval_button-review"
                      onClick={() =>
                        navigate('/revisarlegalizacionanticipo', {
                          state: { id: item.id },
                        })
                      }
                    >
                      {l('actions.review')}
                    </Button>,
                    <Button
                      name="ant-pending-team_button-details"
                      key="ant-pending-team_button-details"
                      className={styles['details']}
                      onClick={() => {
                        setScroll(localScroll);
                        navigate('/detallesanticipo', {
                          state: {
                            id: item.id,
                            permissions: getPermissions('poraprobardemiequipoanticipos'),
                            previusPath: location.pathname,
                          },
                        });
                      }}
                      theme="tertiary"
                    >
                      {l('actions.seeDetails')}
                    </Button>,
                  ]
                : []
            }
          />
        ))}
      </InfiniteScrollList>
      <DenyModal
        handleDeny={(id, reason) => handleDeny(id as number, reason)}
        deny={denyModal}
        setDeny={setDenyModal}
      />
      <Modal
        title={l('modal.confirm.title')}
        successLabel={l('modal.confirm.actions.approve')}
        onClose={() => setConfirmModal(undefined)}
        show={!!confirmModal}
        onSuccess={() => {
          setConfirmModal(undefined);
          confirmModal && handleApprove(confirmModal);
        }}
      >
        {l('modal.confirm.content', { id: confirmModal })}
      </Modal>
    </>
  );
};

export default PendingTeam;
