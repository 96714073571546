/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import TextField from '@/components/ui/textField';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import type { Types } from '@/services/advances';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const DetailsInfo = ({ data }: { data: Types.TAdvanceDetails }) => {
  const t = useLocalTranslation(i18n as Resource);

  return (
    <section className={styles['info']}>
      <h3>{t('title')}</h3>
      <form>
        <TextField
          label={t('user.label')}
          className={styles['user']}
          value={data.correoUsuario.split('@')[0]}
          readOnly
          read
        />
        <TextField
          label={t('documentType.label')}
          className={styles['type']}
          value={data.tipoDocumentoUsuario}
          readOnly
          read
        />
        <TextField
          label={t('document.label')}
          className={styles['document']}
          value={data.identificacionUsuario}
          readOnly
          read
        />
        <TextField
          label={t('creationDate.label')}
          className={styles['date']}
          value={data.fechaCreacion}
          readOnly
          read
        />
        <TextField
          label={t('city.label')}
          className={styles['city']}
          placeholder={t('city.label')}
          value={data.ciudadResidencia}
          readOnly
          read
        />
        <TextField
          label={t('consignmentDate.label')}
          className={styles['date-consignment']}
          value={data.fechaConsignacion}
          readOnly
          read
        />
        <TextField
          label={t('approver.label')}
          className={styles['approver']}
          value={data.nombreUsuarioAutorizador}
          readOnly
          read
        />
      </form>
    </section>
  );
};

export default DetailsInfo;
