/**
 * Solicitudes Pragma SA
 */

import React, { useContext } from 'react';

import History from '@/app/vacations/pending/history';
import InformationPragmatic from '@/app/vacations/pending/information';
import PendingOwn from '@/app/vacations/pending/own';
import PendingTeam from '@/app/vacations/pending/team';
import Tabs from '@/components/ui/tab';
import { sessionContext } from '@/contexts/session';
import { useTranslation } from '@/hooks/useLocalTranslation';

const PendingPage = () => {
  const { t } = useTranslation('pages');
  const { state } = useContext(sessionContext);
  const vacListRoute = state.menu.data.vacations?.find(
    (route) => route.path === '/solicitudesvacaciones',
  );

  return vacListRoute?.hijos ? (
    <Tabs
      subMenus={[
        {
          name: 'missolicitudes',
          title: t('vacations.title.subMenu.missolicitudes'),
          element: <PendingOwn />,
          notifications: state.notifications.vacations.aprobar?.missolicitudes,
        },
        {
          name: 'poraprobardemiequipo',
          title: t('vacations.title.subMenu.poraprobardemiequipo'),
          element: <PendingTeam />,
          notifications: state.notifications.vacations.aprobar?.poraprobardemiequipo,
        },
        {
          name: 'historialvacacionesaprobador',
          title: t('vacations.title.subMenu.historialvacacionesaprobador'),
          element: <History />,
        },
        {
          name: 'informaciondelpragmatico',
          title: t('vacations.title.subMenu.informaciondelpragmatico'),
          element: <InformationPragmatic />,
        },
      ].filter((route) => vacListRoute.hijos.find((subMenu) => subMenu.nombre === route.name))}
    />
  ) : null;
};

export default PendingPage;
