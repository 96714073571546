/**
 * Solicitudes Pragma SA
 */

import axiosClient from '@/services/axiosClient';
import endpoints from '@/services/endpoints';
import type * as APITypes from '@/services/types';
import formatParams from '@/utils/formatParams';

import type * as Types from './types';

export { Types };

export const createPaidRequest = async (form: Types.TPaidRequestForm): Promise<string> => {
  const response: APITypes.TResponse = await axiosClient({
    url: endpoints.vacations.POST_ADD_PAID_VACATIONS,
    method: 'POST',
    data: form,
  });

  return response.mensaje;
};

export const getYears = async (): Promise<number[]> => {
  const response: APITypes.TResponse<number[]> = await axiosClient({
    url: endpoints.vacations.GET_YEARS,
    method: 'GET',
  });

  return response.dato || [];
};

export const getVacations = async (
  form: APITypes.TListForm<Types.TGetVacationsForm>,
): Promise<APITypes.TResponseList<Types.TVacationItem>> => {
  const response: APITypes.TResponseList<Types.TVacationItem> = await axiosClient({
    url: endpoints.vacations.GET_LIST,
    method: 'GET',
    params: formatParams(form),
  });

  return response;
};

export const getLiquidationStatus = async (
  form: Types.TLiquidationStatusForm,
): Promise<boolean> => {
  const response: APITypes.TResponse<Types.TLiquidationStatusResponse> = await axiosClient({
    url: endpoints.vacations.GET_ENABLE_BUTTON_LIQUIDATE,
    method: 'GET',
    params: formatParams(form),
  });

  return response.dato?.habilitado || false;
};

export const cancelRequest = async (id: number, reason?: string): Promise<string> => {
  const response: APITypes.TResponse = await axiosClient({
    url: `${endpoints.vacations.PUT_UPDATE_STATE}/cancelar/${id}`,
    method: 'PUT',
    ...(reason ? { data: { motivo: reason } } : {}),
  });

  return response.mensaje;
};

export const liquidate = async (form: Types.TLiquidationStatusForm): Promise<string> => {
  const response: APITypes.TResponse = await axiosClient({
    url: endpoints.vacations.PUT_UPDATE_LIQUIDATE,
    method: 'PUT',
    params: formatParams(form),
  });

  return response.mensaje;
};

export const getLiquidationReport = async (form: Types.TLiquidationStatusForm): Promise<string> => {
  const response: APITypes.TResponse<string> = await axiosClient({
    url: endpoints.vacations.GET_GENERATE_REPORT,
    method: 'GET',
    params: formatParams(form),
  });

  return response.dato || '#';
};

export const addVacations = async (form: Types.TAddVacationForm): Promise<string> => {
  const response: APITypes.TResponse<string> = await axiosClient({
    url: endpoints.vacations.POST_ADD,
    method: 'POST',
    data: form,
  });

  return response.mensaje;
};

export const denyRequest = async (id: number, reason?: string): Promise<string> => {
  const response: APITypes.TResponse = await axiosClient({
    url: `${endpoints.vacations.PUT_UPDATE_STATE}/rechazar/${id}`,
    method: 'PUT',
    ...(reason ? { data: { motivo: reason } } : {}),
  });

  return response.mensaje;
};

export const approveRequest = async (id: number): Promise<string> => {
  const response: APITypes.TResponse = await axiosClient({
    url: `${endpoints.vacations.PUT_UPDATE_STATE}/aprobar/${id}`,
    method: 'PUT',
  });

  return response.mensaje;
};

export const getHistoryYears = async (email: string): Promise<number[]> => {
  const response: APITypes.TResponse<number[]> = await axiosClient({
    url: endpoints.vacations.GET_YEARS_AVAILABLE_HISTORY,
    method: 'GET',
    params: { correo: email },
  });

  return response.dato || [];
};

export const getVacationsExt = async (
  form: APITypes.TListForm<Types.TGetVacationsFormExt>,
): Promise<APITypes.TResponseList<Types.TVacationItem>> => {
  const response: APITypes.TResponseList<Types.TVacationItem> = await axiosClient({
    url: endpoints.vacations.GET_EXT_LIST,
    method: 'GET',
    params: formatParams(form),
  });

  return response;
};

export const getVacationsDays = async (email: string): Promise<Types.TVacationDays | null> => {
  const response: APITypes.TResponse<Types.TVacationDays> = await axiosClient({
    url: endpoints.vacations.GET_DAYS_AVAILABLE,
    method: 'GET',
    params: { correo: email },
  });

  return response.dato || null;
};

export const getOverviewReport = async (form: Types.TGetOverviewReportForm): Promise<string> => {
  const response: APITypes.TResponse<string> = await axiosClient({
    url: endpoints.vacations.GET_OVERVIEW_REPORT,
    method: 'GET',
    params: formatParams(form),
  });

  return response.dato || '#';
};

export const getEnableVacations = async (id: number): Promise<Types.TEnableVacations | null> => {
  const response: APITypes.TResponse<Types.TEnableVacations> = await axiosClient({
    url: `${endpoints.vacations.GET_PARAMETER_ID}/${id}`,
    method: 'GET',
  });

  return response.dato || null;
};

export const toggleEnableVacations = async (id: number): Promise<Types.TEnableVacations | null> => {
  const response: APITypes.TResponse<Types.TEnableVacations> = await axiosClient({
    url: `${endpoints.vacations.GET_PARAMETER_ID}/${id}`,
    method: 'PUT',
  });

  return response.dato || null;
};

export const getHistoryYearsApproverVacations = async (email: string): Promise<number[]> => {
  const response: APITypes.TResponse<number[]> = await axiosClient({
    url: endpoints.vacations.GET_APPROVER_HISTORY_YEARS,
    method: 'GET',
    params: { correo: email },
  });

  return response.dato || [];
};

export const getPragmaticInformation = async (
  form: APITypes.TListForm<Types.TGetInformationForm>,
): Promise<APITypes.TResponseList<Types.TInformationListItem>> => {
  const response: APITypes.TResponseList<Types.TInformationListItem> = await axiosClient({
    url: endpoints.vacations.GET_INFORMATION_PRAGMATIC,
    method: 'GET',
    params: formatParams(form),
  });

  return response;
};

export const getInformationReport = async (
  form: Types.TGetInformationReportForm,
): Promise<string> => {
  const response: APITypes.TResponse<string> = await axiosClient({
    url: endpoints.vacations.GET_INFORMATION_PRAGMATIC_REPORT,
    method: 'GET',
    params: formatParams(form),
  });

  return response.dato || '#';
};

export const getVacationItem = async (
  idSolicitud: number,
): Promise<APITypes.TResponseItem<Types.TVacationItem>> => {
  const response: APITypes.TResponseItem<Types.TVacationItem> = await axiosClient({
    url: `${endpoints.vacations.GET_LIST}/${idSolicitud}`,
    method: 'GET',
  });

  return response;
};

export const reassignApprover = async (
  idSolicitud: number,
  idAprobador: string,
): Promise<string> => {
  const response: APITypes.TResponse = await axiosClient({
    url: `${endpoints.vacations.PUT_CHANGE_APPROVER}/${idSolicitud}/${idAprobador}`,
    method: 'PUT',
  });

  return response.mensaje;
};

export const sendSatisfactionSurvey = async (opcion: Types.TStatesSurvey): Promise<string> => {
  const response: APITypes.TResponse = await axiosClient({
    url: `${endpoints.vacations.POST_SATISFACTION_SURVEY}`,
    method: 'POST',
    data: opcion,
  });

  return response.mensaje;
};
