const AttachButtonConstants = {
  NULL: null,
  EMPTY_STRING: '',

  SIGNS: {
    SLASH: '/',
    COMMA: ',',
  },

  NUMBERS: {
    ZERO: 0,
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
    SEVEN: 7,
    EIGHT: 8,
    NINE: 9,
  },

  MAX_SIZE: 1000000,

  EXTENCIONES: {
    MB: 'Mb',

    TYPESFILES: {
      pdf: 'JVBER',
      jpeg: '/9J/4',
      jpg: '/9J/4',
      png: 'IVBOR',
    },
  },
  TOAST_TYPE: {
    DANGER: 'danger' as const,
    SUCCESS: 'success' as const,
    WARNING: 'warning' as const,
    INFORMATION: 'information' as const,
  }

};

export default AttachButtonConstants;
