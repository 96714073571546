/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const NotFound = () => {
  const t = useLocalTranslation(i18n as Resource);

  return (
    <div className={styles['not-found']}>
      <h1>404</h1>
      <h2>{t('title')}</h2>
      <h3>{t('subtitle')}</h3>
      <h4>{t('description')}</h4>
    </div>
  );
};

export default NotFound;
