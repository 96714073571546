/**
 * Solicitudes Pragma SA
 */

import React, { createContext, useMemo, useReducer } from 'react';

import { Auth } from '@aws-amplify/auth';

import { PRAGMA } from '@/pages';
import { Types as SessionTypes, getMenu, getUser, getNotifications } from '@/services/session';

import type * as Types from './types';

export { Types };

/* Actions Types */
enum Actions {
  SET_DATA = 'SESSION.SET_DATA',
  SET_LOGGED = 'SESSION.SET_LOGGED',
  SET_IS_USER_PRAGMATIC = 'SESSION.SET_IS_USER_PRAGMATIC',
  SET_LOGIN = 'SESSION.SET_LOGIN',
  SET_MENU_DATA = 'SESSION.SET_MENU_DATA',
  SET_MENU_LOADING = 'SESSION.SET_MENU_LOADING',
  SET_MENU_LOADED = 'SESSION.SET_MENU_LOADED',
  SET_LANGUAGE = 'SESSION.SET_LANGUAGE',
  SET_NOTIFICATIONS = 'SESSION.SET_NOTIFICATIONS',
}

enum Constant {
  USER_PRAGMA = 'pragma.com.co',
}

/* Initial State */
const initialState: Types.TState = {
  data: null,
  logged: false,
  isUserPragmatic: false,
  login: false,
  menu: {
    data: {},
    loading: false,
    loaded: false,
  },
  language: 'es',
  notifications: {
    vacations: { total: 0 },
    advances: { total: 0 },
  },
};

/* Reducer */
const reducer = (state: Types.TState, action: Types.TActions): Types.TState => {
  switch (action.type) {
    case Actions.SET_DATA: {
      return { ...state, data: action.payload };
    }

    case Actions.SET_LOGGED: {
      return { ...state, logged: action.payload };
    }

    case Actions.SET_IS_USER_PRAGMATIC: {
      return { ...state, isUserPragmatic: action.payload };
    }

    case Actions.SET_LOGIN: {
      return { ...state, login: action.payload };
    }

    case Actions.SET_MENU_DATA: {
      return {
        ...state,
        menu: {
          ...state.menu,
          data: {
            ...state.menu.data,
            [action.payload.name]: action.payload.data || { total: 0 },
          },
        },
      };
    }

    case Actions.SET_MENU_LOADING: {
      return {
        ...state,
        menu: { ...state.menu, loading: action.payload },
      };
    }

    case Actions.SET_MENU_LOADED: {
      return {
        ...state,
        menu: {
          ...state.menu,
          loading: false,
          loaded: action.payload,
        },
      };
    }

    case Actions.SET_LANGUAGE: {
      return { ...state, language: action.payload };
    }

    case Actions.SET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [action.payload.name]: action.payload.data,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export const sessionContext = createContext({} as Types.TContext);

const SessionProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions: Types.IActions = {
    getUser: async () => {
      const data = await getUser();
      dispatch({ type: Actions.SET_DATA, payload: data });
      if (data?.correoEmpresarial.includes(Constant.USER_PRAGMA)) {
        dispatch({ type: Actions.SET_IS_USER_PRAGMATIC, payload: true });
      }
    },

    login: async () => {
      try {
        dispatch({ type: Actions.SET_LOGIN, payload: true });
        await Auth.currentSession();
        dispatch({ type: Actions.SET_LOGIN, payload: false });
        dispatch({ type: Actions.SET_LOGGED, payload: true });
        return true;
      } catch (error) {
        dispatch({ type: Actions.SET_LOGGED, payload: false });
        throw error;
      }
    },

    logout: async () => {
      await Auth.signOut();
      dispatch({ type: Actions.SET_DATA, payload: null });
      dispatch({ type: Actions.SET_LOGGED, payload: false });
    },

    getMenu: async (app) => {
      dispatch({ type: Actions.SET_MENU_LOADING, payload: true });
      const promises = app.map(async (item) => {
        const data = await getMenu(item.id);
        return { name: item.name, data };
      });
      const responses = await Promise.all(promises);
      responses.forEach((data) => {
        dispatch({
          type: Actions.SET_MENU_DATA,
          payload: { name: data.name, data: data.data },
        });
      });
      dispatch({ type: Actions.SET_MENU_LOADED, payload: true });
    },

    getLanguage: () => {
      const lng = localStorage.getItem('lang') as Types.TLanguage;
      if (!lng) localStorage.setItem('lang', 'es');
      dispatch({ type: Actions.SET_LANGUAGE, payload: lng || 'es' });
    },

    setLanguage: (value) => {
      localStorage.setItem('lang', value);
      dispatch({ type: Actions.SET_LANGUAGE, payload: value });
    },

    getNotifications: async (app) => {
      if (app) {
        const data = await getNotifications(app);
        dispatch({
          type: Actions.SET_NOTIFICATIONS,
          payload: { name: app, data },
        });
      } else {
        const promises = PRAGMA.map(async (item) => {
          const data = await getNotifications(item.name);
          return { name: item.name, data };
        });
        const responses = await Promise.all(promises);
        responses.forEach((data) => {
          dispatch({
            type: Actions.SET_NOTIFICATIONS,
            payload: { name: data.name, data: data.data },
          });
        });
      }
    },

    getPermissions: (subMenuFind) => {
      let menu: SessionTypes.TMenu<SessionTypes.TMenuNames> | undefined;

      PRAGMA.forEach((app) => {
        if (!menu) {
          menu = state.menu.data[app.name].find((route) => route.path === window.location.pathname);
        }
      });

      if (subMenuFind) {
        const subMenu = menu?.hijos.find((route) => route.nombre === subMenuFind);

        return {
          crear: !!subMenu?.accionPermisos.includes('CREAR'),
          leer: !!subMenu?.accionPermisos.includes('LEER'),
          actualizar: !!subMenu?.accionPermisos.includes('ACTUALIZAR'),
          eliminar: !!subMenu?.accionPermisos.includes('ELIMINAR'),
        };
      }

      return {
        crear: !!menu?.accionPermisos.includes('CREAR'),
        leer: !!menu?.accionPermisos.includes('LEER'),
        actualizar: !!menu?.accionPermisos.includes('ACTUALIZAR'),
        eliminar: !!menu?.accionPermisos.includes('ELIMINAR'),
      };
    },
  };

  return (
    <sessionContext.Provider value={useMemo(() => ({ state, actions }), [state, actions])}>
      {children}
    </sessionContext.Provider>
  );
};

export default SessionProvider;
