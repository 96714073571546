/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import DetailsIncidence from '@/app/advances/details/incidence';
import DenyModal, { TDeny } from '@/components/denyModal';
import Salute from '@/components/salute';
import Button from '@/components/ui/button';
import Modal from '@/components/ui/modal';
import { Types as SessionTypes, sessionContext } from '@/contexts/session';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Apps } from '@/pages';
import { Types, approveAdvance, denyAdvance } from '@/services/advances';
import endpoints from '@/services/endpoints';

import DetailsCostCenter from './costCenter';
import DetailsHosts from './hosts';
import i18n from './i18n.json';
import DetailsInfo from './info';
import DetailsMoney from './money';
import DetailsNotes from './notes';
import styles from './styles.module.scss';
import DetailsTickets from './tickets';

type TProps = {
  data: Types.TAdvanceDetails | null;
  permissions?: SessionTypes.TPermissions;
  isApplicant: boolean;
};

const AdvanceDetails = ({ data, permissions, isApplicant }: TProps) => {
  const t = useLocalTranslation(i18n as Resource);
  const {
    actions: { getNotifications },
  } = useContext(sessionContext);
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const [denyModal, setDenyModal] = useState<TDeny>({ id: '', show: false });
  const [confirmModal, setConfirmModal] = useState(false);
  const navigate = useNavigate();

  const deny = (id: number, reason: string) => {
    setLoading(true);
    denyAdvance(id, reason)
      .finally(() => {
        setDenyModal({ id, show: false });
        setLoading(false);
      })
      .then((res) => {
        addToast(res, 'success');
        getNotifications(Apps.ADVANCES);
        navigate('/solicitudesanticipos');
      })
      .catch((e) => addToast(e.message, 'danger'));
  };

  const approve = (id: number) => {
    setLoading(true);
    approveAdvance(id)
      .finally(() => setLoading(false))
      .then((res) => {
        addToast(res, 'success');
        getNotifications(Apps.ADVANCES);
        navigate('/solicitudesanticipos');
      })
      .catch((e) => addToast(e.message, 'danger'));
  };

  return data ? (
    <section className={styles['details']}>
      <Salute
        msg={t('salute.msg')}
        photo={`${endpoints.GET_MULTIMEDIA}/${data.fotografiaUsuarioSolicitante?.contenido}`}
        name={data.nombreUsuario}
      >
        {!!data && (
          <div className={styles['container-detail']}>
            <h4 className={styles['id-title']}>{t('salute.requestNo')}</h4>
            <div className={styles['container-detail-id']}>
              <div className={styles['id-data']}>{data.id}</div>
            </div>
          </div>
        )}
      </Salute>
      <DetailsNotes title={t('notes.title')} notes={data.motivo !== '' ? [data.motivo] : []} />
      <DetailsCostCenter data={data} />
      <DetailsInfo data={data} />
      <DetailsMoney data={data} />
      <DetailsTickets data={data} />
      <DetailsHosts data={data} />
      {data.esFacilitador ? <DetailsIncidence id={data.id} /> : null}
      {permissions?.actualizar && data.estado === 'PENDIENTE' && !isApplicant && (
        <div className={styles['actions']}>
          <Button
            name="ant-request-details_button-reject"
            theme="secondary"
            onClick={() => data && setDenyModal({ id: data.id, show: true })}
          >
            {t('actions.reject')}
          </Button>
          <Button name="ant-request-details_button-approve" onClick={() => setConfirmModal(true)}>
            {t('actions.approve')}
          </Button>
        </div>
      )}
      <DenyModal
        handleDeny={(id, reason) => deny(id as number, reason)}
        deny={denyModal}
        setDeny={setDenyModal}
      />
      <Modal
        title={t('modal.confirm.title')}
        successLabel={t('modal.confirm.actions.approve')}
        onClose={() => setConfirmModal(false)}
        show={!!confirmModal}
        onSuccess={() => {
          setConfirmModal(false);
          data && approve(data.id);
        }}
      >
        {t('modal.confirm.content', { id: data.id })}
      </Modal>
    </section>
  ) : null;
};

export default AdvanceDetails;
