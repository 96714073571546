/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect, useState } from 'react';

import { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';

import user from '@/assets/images/user.png';
import Modal from '@/components/ui/modal';
import Select from '@/components/ui/select';
import { catalogsContext } from '@/contexts/catalogs';
import { sessionContext } from '@/contexts/session';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Apps } from '@/pages';
import { reassignFacilitator } from '@/services/advances';
import { Types as CatalogsTypes } from '@/services/catalogs';
import endpoints from '@/services/endpoints';

import i18n from './i18n.json';
import styles from './styles.module.scss';

export type TReassign = {
  show: boolean;
  id?: number;
};

type TPhoto = {
  id: string;
  contenido: string;
  tipoFotografia: string;
};

type TFacilitator = {
  idUsuarioFacilitador: string;
  nombreUsuarioFacilitador: string;
  fotografiaUsuarioFacilitador: TPhoto | null;
  correoFacilitador: string;
};

type TProps = {
  reassign: TReassign;
  setReassign: (value: TReassign) => void;
  setParam: (match: { id: number }, value: TFacilitator) => void;
  searchable?: boolean;
};

const ReassignModal = ({ reassign, setReassign, setParam, searchable = true }: TProps) => {
  const t = useLocalTranslation(i18n as Resource);
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const {
    actions: { getFacilitators },
  } = useContext(catalogsContext);
  const {
    actions: { getNotifications },
  } = useContext(sessionContext);
  const [facilitator, setFacilitator] = useState<CatalogsTypes.TFacilitator | null>(null);
  const [facilitatorsList, setFacilitatorsList] = useState<CatalogsTypes.TFacilitator[]>([]);
  const [listLoading, setListLoading] = useState(false);

  const formatOptionLabel = (
    { nombre, fotografia, cantidadAnticiposAsociados }: CatalogsTypes.TFacilitator,
    meta: FormatOptionLabelMeta<CatalogsTypes.TFacilitator>,
  ) => {
    if (meta.context === 'value') return nombre;
    return (
      <div className={styles['menu-facilitator']}>
        <img
          src={fotografia?.contenido ? `${endpoints.GET_MULTIMEDIA}/${fotografia.contenido}` : user}
          alt="."
        />
        <div className={styles.info}>
          <span>{nombre}</span>
          <span className={styles.days}>
            {`${t('facilitators.formatOptionLabel')} ${cantidadAnticiposAsociados}`}
          </span>
        </div>
      </div>
    );
  };

  const handleReassign = () => {
    if (reassign.id && facilitator?.id) {
      setLoading(true);
      reassignFacilitator(reassign.id, facilitator.id)
        .finally(() => setLoading(false))
        .then((res) => {
          setReassign({ show: false });
          addToast(res, 'success');
          setParam(
            { id: reassign.id as number },
            {
              idUsuarioFacilitador: facilitator.id,
              nombreUsuarioFacilitador: facilitator.nombre,
              fotografiaUsuarioFacilitador: facilitator.fotografia,
              correoFacilitador: facilitator.correo,
            },
          );
          getNotifications(Apps.ADVANCES);
        })
        .catch((e) => addToast(e.message, 'danger'));
    }
  };

  useEffect(() => {
    if (reassign.show) {
      setListLoading(true);
      getFacilitators()
        .finally(() => setListLoading(false))
        .then((res) => setFacilitatorsList(res))
        .catch((e) => addToast(e.message, 'danger'));
    }
  }, [reassign.show]);

  return (
    <Modal
      title={t('title')}
      successLabel={t('successLabel')}
      onClose={() => setReassign({ show: false })}
      show={reassign.show}
      onSuccess={() => handleReassign()}
    >
      <Select
        isSearchable={searchable}
        name="ant-facilitate-to-manage_select-available-facilitators"
        label={t('facilitators.label')}
        placeholder={t('facilitators.placeholder')}
        noOptionsMessage={() => t('facilitators.noData')}
        options={facilitatorsList}
        onChange={(facilit) => {
          if (facilit) setFacilitator(facilit);
        }}
        formatOptionLabel={formatOptionLabel}
        value={facilitator}
        isLoading={listLoading}
      />
    </Modal>
  );
};

export default React.memo(ReassignModal);
