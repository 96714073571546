/**
 * Solicitudes Pragma SA
 */

import React, { useState } from 'react';

import { formatISO } from 'date-fns';

import Button from '@/components/ui/button';
import calendar from '@/components/ui/calendar';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Types } from '@/services/advances';

import i18n from './i18n.json';
import styles from './styles.module.scss';

type TProps = {
  handleSearch: (value: Types.TTreasuryHistoryListForm, page?: number) => void;
};

const Filter = ({ handleSearch }: TProps) => {
  const [dateStart, setDateStart] = useState<Date | null>();
  const [dateEnd, setDateEnd] = useState<Date | null>();
  const t = useLocalTranslation(i18n as Resource);

  return (
    <div className={styles['filter']}>
      <calendar.Input
        name="ant-treasury-history_inputdate-since"
        label={t('since.label')}
        placeholderText="mm/dd/yyyy"
        onChange={(day) => {
          setDateStart(day);
          setDateEnd(null);
        }}
        selected={dateStart}
      />
      <calendar.Input
        name="ant-treasury-history_inputdate-until"
        label={t('until.label')}
        placeholderText="mm/dd/yyyy"
        onChange={(day) => setDateEnd(day)}
        selected={dateEnd}
        minDate={dateStart}
        disabled={!dateStart}
      />
      <Button
        name="ant-treasury-history_button-search"
        theme="primary-white"
        onClick={() =>
          handleSearch({
            fechaInicio: dateStart ? formatISO(dateStart, { representation: 'date' }) : '',
            fechaFinal: dateEnd ? formatISO(dateEnd, { representation: 'date' }) : '',
          })
        }
      >
        {t('actions.search')}
      </Button>
    </div>
  );
};

export default Filter;
