/**
 * Solicitudes Pragma SA
 */

import React, { useEffect, useState } from 'react';

import { useFormContext, Controller } from 'react-hook-form';

import SelectCurrency from '@/components/selects/selectCurrency';
import Checkbox from '@/components/ui/checkbox';
import TextField from '@/components/ui/textField';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { getCityById } from '@/services/catalogs';

import { TForm } from '..';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const Money = () => {
  const t = useLocalTranslation(i18n as Resource);
  const {
    register,
    watch,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useFormContext<TForm>();

  const transport = watch('costoTransporte') || 0;
  const food = watch('costoAlimentacion') || 0;
  const others = watch('costoOtro') || 0;
  const city = watch('ciudadResidencia') || undefined;
  const money = watch('tipoMoneda');
  const total = transport + food + others;

  const [cityHasCash, setCityHasCash] = useState(false);

  useEffect(() => {
    const ID_DOLLAR = 25;
    if (city && money) {
      getCityById(city)
        .then((res) => {
          res?.cashDolar && money === ID_DOLLAR ? setCityHasCash(true) : setCityHasCash(false);
        })
        .catch(() => {
          setCityHasCash(false);
        });
    }
  }, [city, money]);

  useEffect(() => {
    if (!total) clearErrors(['costoTransporte', 'costoAlimentacion', 'costoOtro', 'tipoMoneda']);
  }, [total]);

  return (
    <section className={styles['money']}>
      <h3>{t('title')}</h3>
      <form>
        <TextField
          label={t('transport.label')}
          leading="$"
          placeholder={t('transport.placeholder')}
          keyPattern={/^[0-9]*\.?[0-9]*$/}
          className={styles['transport']}
          error={errors['costoTransporte']?.message}
          {...register('costoTransporte', {
            valueAsNumber: true,
            validate: (value) =>
              (total > 0 || watch('tipoMoneda') ? !isNaN(value) : true) ||
              t('formValidation.required'),
          })}
        />
        <TextField
          label={t('feeding.label')}
          leading="$"
          placeholder={t('feeding.placeholder')}
          keyPattern={/^[0-9]*\.?[0-9]*$/}
          className={styles['food']}
          error={errors['costoAlimentacion']?.message}
          {...register('costoAlimentacion', {
            valueAsNumber: true,
            validate: (value) =>
              (total > 0 || watch('tipoMoneda') ? !isNaN(value) : true) ||
              t('formValidation.required'),
          })}
        />
        <TextField
          label={t('others.label')}
          leading="$"
          placeholder={t('others.placeholder')}
          keyPattern={/^[0-9]*\.?[0-9]*$/}
          className={styles['others']}
          error={errors['costoOtro']?.message}
          {...register('costoOtro', {
            valueAsNumber: true,
            validate: (value) =>
              (total > 0 || watch('tipoMoneda') ? !isNaN(value) : true) ||
              t('formValidation.required'),
          })}
        />
        <Controller
          control={control}
          name="tipoMoneda"
          rules={{
            validate: (value) => (total > 0 ? !!value : true) || t('formValidation.required'),
          }}
          render={() => (
            <SelectCurrency
              className={styles['currency']}
              onChange={(value) => {
                clearErrors('tipoMoneda');
                setValue('tipoMoneda', value || NaN);
              }}
              value={watch('tipoMoneda')}
              error={errors['tipoMoneda']?.message}
              isClearable
            />
          )}
        />
        {cityHasCash && (
          <Checkbox
            name="ant-request-cash"
            label={t('cash.label')}
            className={styles['cash']}
            onChange={({ target }) => {
              setValue('solicitaEfectivo', target.checked);
            }}
          />
        )}
        <TextField
          label={t('total.label')}
          leading="$"
          className={styles['total']}
          value={String(total)}
          readOnly
          readLeading
        />
      </form>
    </section>
  );
};

export default Money;
