/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect, useState } from 'react';

import { formatISO, lastDayOfMonth, startOfMonth } from 'date-fns';

import { CancelTreasuryIcon } from '@/assets/icons/icons';
import DenyModal, { TDeny } from '@/components/denyModal';
import Table from '@/components/table';
import Button from '@/components/ui/button';
import { sessionContext } from '@/contexts/session';
import { utilsContext } from '@/contexts/utils';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import useList from '@/hooks/useList';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import useVacationsData from '@/hooks/useVacationsData';
import { Types, cancelRequest, getLiquidationReport, getVacationsExt } from '@/services/vacations';
import downloadFile from '@/utils/downloadFile';

import Filter from './filter';
import i18n from './i18n.json';
import styles from './styles.module.scss';

const Liquidations = () => {
  const {
    actions: { getPermissions },
  } = useContext(sessionContext);
  const {
    actions: { setLoading, addToast },
  } = useContext(utilsContext);
  const cancellablePromise = useCancellablePromise();
  const [form, setForm] = useState<Types.TGetVacationsFormExt>({
    fechaInicio: formatISO(startOfMonth(new Date()), { representation: 'date' }),
    fechaFinal: formatISO(lastDayOfMonth(new Date()), { representation: 'date' }),
    estados: ['APROBADA', 'LIQUIDADA', 'PROCESO_LIQUIDACION'],
  });
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [list, setList, setListLoading, setVisible] = useList<Types.TVacationItem>();
  const [denyModal, setDenyModal] = useState<TDeny>({ id: '', show: false });
  const t = useLocalTranslation(i18n as Resource);
  const { tableHeader } = useVacationsData();

  const requestList = (query?: Types.TGetVacationsFormExt, page = 0) => {
    setListLoading(true, !page);
    cancellablePromise(
      getVacationsExt({
        ...(query || form),
        estados: ['APROBADA', 'LIQUIDADA', 'PROCESO_LIQUIDACION'],
        numeroPagina: page,
        totalElementos: 20,
        fechaCreacionAscendente: false,
      })
        .finally(() => setListLoading(false))
        .then((res) => setList(res.dato || [], !!page, res.cantidadPaginas, res.ultimaPagina, page))
        .catch((err) => addToast(err.message, 'danger')),
    );
    if (query) setForm(query);
  };

  const handleCancel = (id: number, reason: string) => {
    setLoading(true);
    setDisabledButton(true);
    cancelRequest(id, reason)
      .finally(() => {
        setLoading(false);
        setDenyModal({ id, show: false });
        setDisabledButton(false);
      })
      .then((res) => {
        addToast(res, 'success');
        setVisible({ id }, false);
      })
      .catch((e) => addToast(e.message, 'danger'));
  };

  const handleReport = () => {
    setLoading(true);
    getLiquidationReport({
      fechaInicio: form.fechaInicio || '',
      fechaFinal: form.fechaFinal || '',
    })
      .finally(() => setLoading(false))
      .then((res) => downloadFile(res))
      .catch((err) => addToast(err.message, 'danger'));
  };

  useEffect(() => {
    requestList();
  }, []);

  useEffect(() => {
    if (!list.lastPage && !list.isAnyVisible) {
      requestList();
    }
  }, [list.isAnyVisible, list.lastPage]);

  return (
    <div className={styles['liquidations']}>
      <Filter handleSearch={requestList} />
      {getPermissions().actualizar && (
        <div className={styles['actions']}>
          <Button
            name="vac-liquidation_button-generate-report"
            theme="primary"
            disabled={!list.count || list.isLoading}
            onClick={handleReport}
          >
            {t('actions.report')}
          </Button>
        </div>
      )}
      <Table
        name="vac-liquidations_table"
        header={[
          { name: 'usuarioNombre', label: tableHeader('requester') },
          { name: 'id', label: tableHeader('numberRequest') },
          { name: 'fechaInicio', label: tableHeader('startDate') },
          { name: 'fechaLlegadaTrabajo', label: tableHeader('returnDate') },
          { name: 'cantidadDias', label: tableHeader('numberDays') },
          { name: 'fechaPago', label: tableHeader('paidDate') },
          { name: '_actions', label: t('table.header.actions') },
        ]}
        data={list.list.map((item) => ({
          _key: item.id,
          _visible: item._visible,
          id: item.id,
          usuarioNombre: item.usuarioNombre,
          fechaInicio: item.fechaInicio,
          fechaLlegadaTrabajo: item.fechaLlegadaTrabajo,
          cantidadDias: item.cantidadDias,
          fechaPago: item.fechaPago || '',
          _actions: (
            <Button
              name="vac-liquidation_button-cancel"
              className={styles['cancel']}
              theme="error"
              onClick={() => setDenyModal({ id: item.id, show: true })}
            >
              <CancelTreasuryIcon width={12} height={12} />
            </Button>
          ),
        }))}
        advanced={{ ...list, handleSearch: (page) => requestList(form, page) }}
        emptyLabel={`${t('table.noData')} 🚀`}
      />
      <DenyModal
        handleDeny={(id, reason) => handleCancel(id as number, reason)}
        deny={denyModal}
        setDeny={setDenyModal}
        disabledLoading={disabledButton}
      />
    </div>
  );
};

export default Liquidations;
