/**
 * Solicitudes Pragma SA
 */

import React, { useContext } from 'react';

import AdvancesSearch from '@/app/advances/search';
import TreasuryHistory from '@/app/advances/treasury/history';
import TreasuryPending from '@/app/advances/treasury/pending';
import AdvanceAdminPage from '@/app/advances/treasury/reports';
import Tabs from '@/components/ui/tab';
import { sessionContext } from '@/contexts/session';
import { useTranslation } from '@/hooks/useLocalTranslation';

const TreasuryPage = () => {
  const { t } = useTranslation('pages');
  const { state } = useContext(sessionContext);
  const treasurerRoute = state.menu.data.advances?.find((route) => route.path === '/tesoreria');

  return treasurerRoute?.hijos ? (
    <Tabs
      isMain
      subMenus={[
        {
          name: 'pendientesporpagar',
          title: t('advances.title.subMenu.pendientesporpagar'),
          element: <TreasuryPending />,
          notifications: state.notifications.advances.tesoreria?.pendientesporpagar,
        },
        {
          name: 'historialtesoreria',
          title: t('advances.title.subMenu.historialtesoreria'),
          element: <TreasuryHistory />,
          notifications: state.notifications.advances.tesoreria?.historialtesoreria,
        },
        {
          name: 'tesoreriabusqueda',
          title: t('advances.title.subMenu.tesoreriabusqueda'),
          element: <AdvancesSearch />,
          notifications: state.notifications.advances.tesoreria?.tesoreriabusqueda,
        },
        {
          name: 'tesoreriareportes',
          title: t('advances.title.subMenu.tesoreriareportes'),
          element: <AdvanceAdminPage />,
          notifications: state.notifications.advances.tesoreria?.tesoreriareportes,
        },
      ].filter((route) => treasurerRoute.hijos.find((subMenu) => subMenu.nombre === route.name))}
    />
  ) : null;
};

export default TreasuryPage;
