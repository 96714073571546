/**
 * Solicitudes Pragma SA
 */

import React, { useState, useEffect, useContext } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import Card from '@/components/card';
import InfiniteScrollList from '@/components/infiniteScroll';
import StateLabel from '@/components/stateLabel';
import Button from '@/components/ui/button';
import { utilsContext } from '@/contexts/utils';
import useAdvancesData from '@/hooks/useAdvancesData';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import useList from '@/hooks/useList';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Types, getAdvancesList } from '@/services/advances';
import { TStateLocation } from '@/template';
import WhatsAppButton from '@/components/whatsAppButton';
import { phoneNumberPragma } from '@/services/advances';

import Filter from './filter';
import i18n from './i18n.json';
import styles from './styles.module.scss';

const AdvancesHistory = () => {
  const {
    actions: { addToast, setScroll },
    state: { scroll },
  } = useContext(utilsContext);
  const cancellablePromise = useCancellablePromise();
  const [list, setList, setListLoading] = useList<Types.TAdvanceItem>();
  const [form, setForm] = useState<Types.TGetAdvanceListForm>({
    estados: ['RECHAZADA', 'FINALIZADO', 'CANCELADA'],
    fechaInicio: '',
    fechaFin: '',
  });
  const t = useLocalTranslation(i18n as Resource);
  const { cardInfo } = useAdvancesData();
  const navigate = useNavigate();
  const [localScroll, setlocalScroll] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (!(location.state as TStateLocation)?.previusPath) {
      setScroll(0);
    }
  }, [location]);

  const requestList = (query?: Types.TGetAdvanceListForm, page = 0) => {
    setListLoading(true, !page);
    cancellablePromise(
      getAdvancesList({
        ...(query || form),
        numeroPagina: page,
        totalElementos: scroll > 0 ? Math.ceil(scroll / 150) + 2 : 5,
        fechaOrdenAscendente: true,
      })
        .finally(() => setListLoading(false))
        .then((res) => {
          setList(res.dato || [], !!page, res.cantidadPaginas, res.ultimaPagina, page);
        })
        .catch((e) => addToast(e.message, 'danger')),
    );
    if (query) setForm(query);
  };

  useEffect(() => {
    requestList();
  }, []);

  return (
    <div className={styles['history']}>
      <WhatsAppButton phoneNumberPragma={phoneNumberPragma}/>
      <Filter handleSearch={requestList} />
      <InfiniteScrollList
        list={list}
        handleNext={(page) => requestList(form, page)}
        setLocalScroll={setlocalScroll}
      >
        {list.list.map((item) => (
          <Card
            key={item.id}
            _visible={item._visible}
            header={{
              id: item.id,
              photo: item.fotografiaUsuarioSolicitante?.contenido,
              name: item.nombreUsuario,
              email: item.correoUsuario,
              date: new Date(item.fechaCreacion),
            }}
            data={cardInfo(item)}
            state={
              <div className={styles['state']}>
                {t('state')}
                <StateLabel state={item.estado} />
              </div>
            }
            actions={[
              <Button
                name="ant-history_button-details"
                key="ant-history_button-details"
                className={styles['details']}
                onClick={() => {
                  setScroll(localScroll);
                  navigate('/detallesanticipo', {
                    state: { id: item.id, previusPath: location.pathname },
                  });
                }}
                theme="tertiary"
              >
                {t('actions.seeDetails')}
              </Button>,
            ]}
          />
        ))}
      </InfiniteScrollList>
    </div>
  );
};

export default AdvancesHistory;
