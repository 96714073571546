/**
 * Solicitudes Pragma SA
 */

import { useEffect, useState } from 'react';

export const makeCancelable = <T>(promise: Promise<T>) => {
  let isCanceled = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise
      .then((val) => !isCanceled && resolve(val))
      .catch((error) => !isCanceled && reject(error));
  });

  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true;
    },
  };
};

export default (cancelable = makeCancelable) => {
  // think of useRef as member variables inside a hook
  const emptyPromise = Promise.resolve(true);

  // test if the input argument is a cancelable promise generator
  if (cancelable(emptyPromise).cancel === undefined) {
    throw new Error('promise wrapper argument must provide a cancel() function');
  }

  const [promises, setPromises] = useState<
    {
      promise: Promise<unknown>;
      cancel: () => void;
    }[]
  >([]);
  // useEffect initializes the promises array
  // and cleans up by calling cancel on every stored
  // promise.
  // Empty array as input to useEffect ensures that the hook is
  // called once during mount and the cancel() function called
  useEffect(() => {
    // eslint-disable-next-line no-self-assign
    setPromises(promises || []);

    return () => {
      promises.forEach((p) => p.cancel());
      setPromises([]);
    };
  }, []);

  // cancelablePromise remembers the promises that you
  // have called so far. It returns a wrapped cancelable
  // promise
  const cancellablePromise = <T>(p: Promise<T>) => {
    const cPromise = makeCancelable<T>(p);
    promises.push(cPromise);
    return cPromise.promise;
  };

  return cancellablePromise;
};
