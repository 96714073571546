/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import { useMatch } from 'react-router-dom';

import ApproveURL from '@/app/vacations/paramsApprove';

const ApproveURLPage = () => {
  const match = useMatch('/approve/:id');

  return <ApproveURL id={Number(match?.params.id) || null} />;
};

export default ApproveURLPage;
