/**
 * Solicitudes Pragma SA
 */

import React, { useContext } from 'react';

import History from '@/app/advances/pending/history';
import PendingOwn from '@/app/advances/pending/own';
import PendingTeam from '@/app/advances/pending/team';
import Tabs from '@/components/ui/tab';
import { sessionContext } from '@/contexts/session';
import { useTranslation } from '@/hooks/useLocalTranslation';

const AdvancesPage = () => {
  const { t } = useTranslation('pages');
  const { state } = useContext(sessionContext);
  const advancesRoute = state.menu.data.advances?.find(
    (route) => route.path === '/solicitudesanticipos',
  );

  return advancesRoute?.hijos ? (
    <Tabs
      isMain
      subMenus={[
        {
          name: 'missolicitudesanticipos',
          title: t('advances.title.subMenu.missolicitudesanticipos'),
          element: <PendingOwn />,
          notifications: state.notifications.advances.solicitudesanticipos?.missolicitudesanticipos,
        },
        {
          name: 'poraprobardemiequipoanticipos',
          title: t('advances.title.subMenu.poraprobardemiequipoanticipos'),
          element: <PendingTeam />,
          notifications:
            state.notifications.advances.solicitudesanticipos?.poraprobardemiequipoanticipos,
        },
        {
          name: 'historialanticiposaprobador',
          title: t('advances.title.subMenu.historialanticiposaprobador'),
          element: <History />,
        },
      ].filter((route) => advancesRoute.hijos.find((subMenu) => subMenu.nombre === route.name))}
    />
  ) : null;
};

export default AdvancesPage;
