/**
 * Solicitudes Pragma SA
 */

import React, { PropsWithChildren, useContext, useId, useRef } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';

import EmptyState from '@/components/emptyState';
import Shimmer from '@/components/ui/shimmer';
import { utilsContext } from '@/contexts/utils';
import { TList } from '@/hooks/useList';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';

import i18n from './i18n.json';
import styles from './styles.module.scss';

type TProps<L> = {
  list: TList<L>;
  handleNext: (page?: number) => void;
  setLocalScroll?: React.Dispatch<React.SetStateAction<number>>;
};

const InfiniteScrollList = ({
  list,
  handleNext,
  children,
  setLocalScroll,
}: PropsWithChildren<TProps<unknown>>) => {
  const t = useLocalTranslation(i18n as Resource);
  const id = useId();
  const {
    state: { scroll },
  } = useContext(utilsContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null);

  return (
    <div className={styles['list']} id={id}>
      {list.isNewSearch && list.isLoading ? (
        <Shimmer repetitions={3} />
      ) : list.count > 0 ? (
        <InfiniteScroll
          scrollableTarget="template"
          dataLength={list.count}
          next={() => handleNext(list.pages === 0 ? 0 : list.page + 1)}
          hasMore={!list.lastPage}
          loader={<Shimmer />}
          scrollThreshold={0.9}
          initialScrollY={scroll}
          ref={ref}
          onScroll={() => {
            setLocalScroll && setLocalScroll(ref.current?.lastScrollTop);
          }}
        >
          {children}
        </InfiniteScroll>
      ) : (
        <EmptyState msg={t('emptyState')} />
      )}
    </div>
  );
};

export default InfiniteScrollList;
