/**
 * Solicitudes Pragma SA
 */

import React, { useState } from 'react';

import { formatISO } from 'date-fns';

import SelectState from '@/components/selects/selectState';
import Button from '@/components/ui/button';
import calendar from '@/components/ui/calendar';
import TextField from '@/components/ui/textField';
import useConstants from '@/hooks/useConstants';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import type { Types } from '@/services/vacations';

import i18n from './i18n.json';
import styles from './style.module.scss';

type TProps = {
  handleSearch: (form: Types.TGetVacationsFormExt, page?: number) => void;
};

const Filter = ({ handleSearch }: TProps) => {
  const t = useLocalTranslation(i18n as Resource);
  const [dateStart, setDateStart] = useState<Date | null>();
  const [dateEnd, setDateEnd] = useState<Date | null>();
  const [selectedState, setSelectedState] = useState<Types.TVacationStates | 'ALL'>('ALL');
  const [filterData, setFilterData] = useState({
    userName: '',
    requestId: '',
    userDocument: '',
    authorizer: '',
  });
  const { getStatesVacations } = useConstants();

  const states: Types.TVacationStates[] = [
    'APROBADA',
    'PENDIENTE',
    'LIQUIDADA',
    'RECHAZADA',
    'PROCESO_LIQUIDACION',
  ];

  return (
    <div className={styles['filter']}>
      <TextField
        label={t('requestId.label')}
        placeholder={t('requestId.placeholder')}
        onChange={(e) => setFilterData({ ...filterData, requestId: e.currentTarget.value })}
        value={filterData.requestId}
      />
      <TextField
        label={t('document.label')}
        placeholder={t('document.placeholder')}
        onChange={(e) => setFilterData({ ...filterData, userDocument: e.currentTarget.value })}
        value={filterData.userDocument}
      />
      <TextField
        label={t('authorizer.label')}
        placeholder={t('authorizer.placeholder')}
        onChange={(e) => setFilterData({ ...filterData, authorizer: e.currentTarget.value })}
        value={filterData.authorizer}
      />
      <TextField
        label={t('username.label')}
        placeholder={t('username.placeholder')}
        onChange={(e) => setFilterData({ ...filterData, userName: e.currentTarget.value })}
        value={filterData.userName}
      />
      <SelectState
        states={getStatesVacations(['ALL', ...states])}
        onChange={(state) => setSelectedState(state as Types.TVacationStates)}
        value={selectedState}
        searchable={false}
      />
      <calendar.Input
        label={t('startDate.label')}
        placeholderText={t('startDate.placeholder')}
        onChange={(from) => {
          setDateStart(from);
          setDateEnd(from);
        }}
        selected={dateStart}
      />
      <calendar.Input
        label={t('returnDate.label')}
        placeholderText={t('returnDate.placeholder')}
        minDate={dateStart}
        onChange={(to) => to && setDateEnd(to)}
        selected={dateEnd}
        disabled={!dateStart}
      />
      <Button
        name="vac-overview_button-search-user"
        className={styles['search']}
        onClick={() =>
          handleSearch({
            estados: selectedState === 'ALL' ? [] : [selectedState],
            fechaInicio: dateStart ? formatISO(dateStart, { representation: 'date' }) : '',
            fechaFinal: dateEnd ? formatISO(dateEnd, { representation: 'date' }) : '',
            correoUsuario: filterData.userName,
            usuarioSolicitante: filterData.userName,
            idSolicitud: Number(filterData.requestId) || undefined,
            documentoSolicitante: filterData.userDocument,
            correoAutorizador: filterData.authorizer,
          })
        }
        theme="primary-white"
      >
        {t('actions.search')}
      </Button>
      <Button
        name="vac-overview_button-search-user"
        className={styles['search']}
        onClick={() => {
          setFilterData({
            userName: '',
            requestId: '',
            userDocument: '',
            authorizer: '',
          });
          setDateStart(null);
          setDateEnd(null);
          setSelectedState('ALL');
          handleSearch({
            estados: [],
            fechaInicio: '',
            fechaFinal: '',
            correoUsuario: '',
            idSolicitud: undefined,
            documentoSolicitante: '',
            correoAutorizador: '',
          });
        }}
        theme="tertiary"
      >
        {t('actions.clear')}
      </Button>
    </div>
  );
};

export default Filter;
