/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import Compensation from '@/app/advances/compensation';

const CompensationPage = () => <Compensation />;

export default CompensationPage;
