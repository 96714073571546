/**
 * Solicitudes Pragma SA
 */

import React, { forwardRef, useId, Ref } from 'react';

import Select from '@/components/ui/select';
import useConstants from '@/hooks/useConstants';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';

import i18n from './i18n.json';
import styles from './styles.module.scss';

type TProps = {
  name?: string;
  onChange?: (value?: number) => void;
  isClearable?: boolean;
  value?: number;
  className?: string;
  error?: string;
  setAllOption?: boolean;
  isDisabled?: boolean;
  searchable?: boolean;
};

const SelectMonth = (
  {
    name,
    onChange,
    isClearable,
    value,
    className,
    error,
    setAllOption,
    isDisabled,
    searchable = true,
  }: TProps,
  ref: Ref<HTMLLabelElement>,
) => {
  const t = useLocalTranslation(i18n as Resource, 'selects');
  const uName = useId();
  const { months } = useConstants();

  const options = [...(setAllOption ? [months[0]] : []), ...months];

  return (
    <Select
      isSearchable={searchable}
      labelRef={ref}
      name={name || uName}
      label={t('selectMonth.label')}
      placeholder={t('selectMonth.placeholder')}
      noOptionsMessage={() => t('selectMonth.noData')}
      error={error}
      className={`${styles['month']} ${className || ''}`}
      options={months}
      onChange={(option, actionMeta) => {
        if (actionMeta.action === 'clear') onChange && onChange(undefined);
        else if (option) onChange && onChange(option.id);
      }}
      getOptionLabel={(option) => option.name}
      value={value !== undefined ? options.find((option) => option.id === value) : null}
      isClearable={isClearable}
      isDisabled={isDisabled}
    />
  );
};

export default forwardRef(SelectMonth);
