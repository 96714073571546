/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import Check from '@/assets/images/check.png';
import Cancelada from '@/assets/images/iconcancelada.png';
import Rechazada from '@/assets/images/iconrechazada.png';
import PorLegalizar from '@/assets/images/porlegalizar.png';
import Revision from '@/assets/images/revision.png';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Types as ATypes } from '@/services/advances';
import { Types as VTypes } from '@/services/vacations';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const StateLabel = ({ state }: { state: VTypes.TVacationStates | ATypes.TAdvanceStates }) => {
  const states: Record<VTypes.TVacationStates | ATypes.TAdvanceStates, string> = {
    APROBADA: Check,
    LIQUIDADA: Check,
    FINALIZADO: Check,
    PENDIENTE: Revision,
    EN_REVISION: Revision,
    OCULTAR: Cancelada,
    CANCELADA: Cancelada,
    RECHAZADA: Rechazada,
    PROCESO_LIQUIDACION: Revision,
    GESTIONADA: Check,
    POR_LEGALIZAR: PorLegalizar,
  };

  const t = useLocalTranslation(i18n as Resource);

  return (
    <span className={styles['state']} data-state={state.toLowerCase()}>
      {t(`states.${state}`)}
      <img src={states[state]} alt={state} />
    </span>
  );
};

export default StateLabel;
