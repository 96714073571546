/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect, useState } from 'react';

import { MdOutlineFiberNew } from 'react-icons/md';

import DetailsHosts from '@/app/advances/details/hosts';
import DetailsTickets from '@/app/advances/details/tickets';
import { CancelTreasuryIcon } from '@/assets/icons/icons';
import Button from '@/components/ui/button';
import TextArea from '@/components/ui/textArea';
import UploadButton from '@/components/uploadButton';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { addFacilitatorSupports, Types } from '@/services/advances';
import makeId from '@/utils/makeId';

import i18n from './i18n.json';
import styles from './styles.module.scss';

type TProps = {
  isTickets?: boolean;
  data: Types.TAdvanceDetails;
  info: Types.TFacilitatorSupports;
};

const convertBase64ToBlob = (base64: string) => {
  const parts = base64.split(';base64,');
  const decodedData = window.atob(parts[1]);
  const uInt8Array = new Uint8Array(decodedData.length);
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }
  return new Blob([uInt8Array], { type: parts[0].split(':')[1] });
};

const Supports = ({ isTickets, data, info }: TProps) => {
  const t = useLocalTranslation(i18n as Resource);
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const [comment, setComment] = useState(info.comentario);
  const [newFiles, setNewFiles] = useState<{ id: string; base64: string; name: string }[]>([]);
  const [deleteFiles, setDeleteFiles] = useState<number[]>([]);
  const [response, setResponse] = useState<Types.TFacilitatorSupports>();

  const listSupports = [
    ...(response?.soportes || info.soportes)
      .map((item, i) => ({
        id: String(item.id),
        url: item.url,
        name: item?.nombreArchivo !== null ? item.nombreArchivo : `${t('fileName')} ${i + 1}`,
        new: false,
      }))
      .filter((item) => deleteFiles.indexOf(Number(item.id)) === -1),
    ...newFiles.map((item) => ({
      id: item.id,
      url: URL.createObjectURL(convertBase64ToBlob(item.base64)),
      name: item.name,
      new: true,
    })),
  ];

  const handleDelete = (newFile: boolean, id: string) => {
    if (newFile) {
      const tmpNewFiles = newFiles.filter((item) => item.id !== id);
      setNewFiles([...tmpNewFiles]);
    } else {
      setDeleteFiles([...deleteFiles, Number(id)]);
    }
  };

  const handleSave = () => {
    setLoading(true);
    addFacilitatorSupports(data.id, {
      nuevosSoportes: newFiles.map((item) => {
        return { nombreSoporte: item.name, archivoSoporte: item.base64 };
      }),
      soportesEliminar: deleteFiles,
      tiquete: !!isTickets,
      hospedaje: !isTickets,
      comentario: comment,
    })
      .finally(() => setLoading(false))
      .then((res) => {
        addToast(res.mensaje, 'success');
        setResponse(res.dato);
      })
      .catch((e) => addToast(e.message, 'danger'));
  };

  useEffect(() => {
    setComment(response?.comentario || info.comentario);
    setDeleteFiles([]);
    setNewFiles([]);
  }, [info.comentario, response]);

  return (
    <div className={styles['tickets']}>
      {isTickets ? <DetailsTickets data={data} /> : <DetailsHosts data={data} />}
      <h3>{t('supports.title')}</h3>
      <div className={styles['supports']}>
        <UploadButton
          className={styles['upload']}
          maxSize={1000000}
          fileTypes={['png', 'jpge', 'jpg', 'jpeg', 'pdf']}
          onChange={(file, base64) => {
            setNewFiles([...newFiles, { id: makeId(), base64: base64 as string, name: file.name }]);
          }}
        />
        <ul className={styles['file-list']}>
          {listSupports.map((s, _key) => (
            <li key={_key} className={styles['file']}>
              {s.new && <MdOutlineFiberNew className={styles['new']} />}
              <Button
                name={s.name}
                text
                className={styles['link']}
                onClick={() => window.open(s.url)}
              >
                {s.name}
              </Button>
              <Button
                name={s.name}
                theme="error"
                className={styles['cancel']}
                onClick={() => handleDelete(s.new, s.id)}
              >
                <CancelTreasuryIcon width={12} height={12} />
              </Button>
            </li>
          ))}
        </ul>
      </div>
      <TextArea
        label={t('comment.label')}
        placeholder={t('comment.placeholder')}
        className={styles['comment']}
        onChange={(e) => setComment(e.currentTarget.value)}
        value={comment}
      />
      <div className={styles['actions']}>
        <Button
          name="ant-facilitate-supports_clear"
          theme="secondary"
          onClick={() => {
            setNewFiles([]);
            setDeleteFiles(info.soportes.map((item) => item.id));
            setComment('');
          }}
        >
          {t('actions.clear')}
        </Button>
        <Button name="ant-facilitate-supports_save" onClick={handleSave}>
          {t('actions.save')}
        </Button>
      </div>
    </div>
  );
};

export default Supports;
