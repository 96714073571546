/**
 * Solicitudes Pragma SA
 */

import React, { useEffect, useState } from 'react';

import { CancelTreasuryIcon, PlusIcon } from '@/assets/icons/icons';
import SelectBusiness from '@/components/selects/selectBusiness';
import SelectClient from '@/components/selects/selectClient';
import Button from '@/components/ui/button';
import TextField from '@/components/ui/textField';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { TCostsForm } from '@/services/advances/types';

import i18n from './i18n.json';
import styles from './styles.module.scss';

type TProps = {
  handleAddProject: (business: string | undefined, client: string | undefined) => void;
  costCenter: TCostsForm[];
  index: number;
  handleEditPercent: (percent: number, index: number) => void;
  handleEditProject: (property: 'idMarca' | 'idNegocio', value: string, index: number) => void;
  isAccept: boolean;
  handleDelete: (index: number) => void;
  totalPercent: number;
};

const Form = ({
  handleAddProject,
  costCenter,
  index,
  handleEditPercent,
  handleEditProject,
  isAccept,
  handleDelete,
  totalPercent,
}: TProps) => {
  const t = useLocalTranslation(i18n as Resource);

  const [business, setBusiness] = useState<string>();
  const [client, setClient] = useState<string>();
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    setBusiness(costCenter[index].idNegocio);
    setClient(costCenter[index].idMarca);
    setPercent(costCenter[index].porcentaje);
  }, [costCenter, index]);

  return (
    <div className={styles['row']}>
      <SelectBusiness
        className={styles['business']}
        onChange={(value) => {
          if (value) {
            setBusiness(value);
            setClient(undefined);
            handleEditProject('idNegocio', value, index);
          }
        }}
        value={business}
      />
      <SelectClient
        className={styles['client']}
        onChange={(value) => {
          if (value) {
            setClient(value);
            handleEditProject('idMarca', value, index);
          }
        }}
        value={client}
        business={business}
        isDisabled={!business}
      />
      {costCenter.length - 1 === index && totalPercent < 100 ? (
        <Button
          name="ant-request_button-add-business"
          className={styles['add']}
          disabled={!business || !client}
          onClick={() => {
            handleAddProject(business, client);
          }}
        >
          <PlusIcon width={18} height={18} />
        </Button>
      ) : (
        <TextField
          label={t('percent.label')}
          className={styles['percent']}
          placeholder="0"
          value={percent}
          leading="%"
          keyPattern={/^[0-9]*\.?[0-9]*$/}
          onChange={(e) => {
            if (
              parseFloat(e.currentTarget.value) <= 100 &&
              parseFloat(e.currentTarget.value) >= 0
            ) {
              setPercent(Number(e.currentTarget.value));
              handleEditPercent(Number(e.currentTarget.value), index);
            } else {
              setPercent(0);
            }
          }}
        />
      )}
      {isAccept && (
        <Button
          name="ant-request_button-delete-bussiness"
          theme="error"
          className={styles['delete']}
          onClick={() => {
            handleDelete(index);
          }}
        >
          <CancelTreasuryIcon width={14} height={14} />
        </Button>
      )}
    </div>
  );
};

export default Form;
