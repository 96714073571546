/**
 * Solicitudes Pragma SA
 */

import React, { useEffect, useContext, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import Card from '@/components/card';
import InfiniteScrollList from '@/components/infiniteScroll';
import StateLabel from '@/components/stateLabel';
import Timeline from '@/components/timeline';
import Button from '@/components/ui/button';
import { utilsContext } from '@/contexts/utils';
import useAdvancesData from '@/hooks/useAdvancesData';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import useList from '@/hooks/useList';
import useLocalTranslation, { Resource, useTranslation } from '@/hooks/useLocalTranslation';
import { Types, getAdvancesList } from '@/services/advances';
import { TStateLocation } from '@/template';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const PendingOwn = () => {
  const {
    actions: { addToast, setScroll },
    state: { scroll },
  } = useContext(utilsContext);
  const cancellablePromise = useCancellablePromise();
  const [list, setList, setListLoading] = useList<Types.TAdvanceItem>();
  const { t } = useTranslation('advances');
  const l = useLocalTranslation(i18n as Resource);
  const { cardInfo } = useAdvancesData();
  const navigate = useNavigate();
  const [localScroll, setlocalScroll] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (!(location.state as TStateLocation)?.previusPath) {
      setScroll(0);
    }
  }, [location]);

  const requestList = (page = 0) => {
    setListLoading(true, !page);
    cancellablePromise(
      getAdvancesList({
        estados: ['PENDIENTE', 'APROBADA', 'POR_LEGALIZAR', 'EN_REVISION'],
        numeroPagina: page,
        totalElementos: scroll > 0 ? Math.ceil(scroll / 150) + 2 : 5,
        fechaOrdenAscendente: false,
      })
        .finally(() => setListLoading(false))
        .then((res) => {
          setList(res.dato || [], !!page, res.cantidadPaginas, res.ultimaPagina, page);
        })
        .catch((e) => addToast(e.message, 'danger')),
    );
  };

  useEffect(() => {
    requestList();
  }, []);

  return (
    <>
      <div className={styles['refresh']}>
        <Button
          className={styles['button']}
          name="ant-search_button_search"
          onClick={() => requestList()}
        >
          {l('actionsRefresh.refresh')}
        </Button>
      </div>
      <InfiniteScrollList list={list} handleNext={requestList} setLocalScroll={setlocalScroll}>
        {list.list.map((item) => (
          <Card
            key={item.id}
            _visible={item._visible}
            header={{
              id: item.id,
              photo: item.fotografiaUsuarioSolicitante?.contenido,
              name: item.nombreUsuario,
              email: item.correoUsuario,
              date: new Date(item.fechaCreacion),
            }}
            data={cardInfo(item)}
            state={
              <div className={styles['state']}>
                <div className={styles['state-info']}>
                  {t('state')}
                  <StateLabel state={item.estado} />
                </div>
              </div>
            }
            actions={
              item.estado === 'POR_LEGALIZAR'
                ? [
                    <Button
                      key="ant-my-request_button-legalizar"
                      name="ant-my-request_button-legalizar"
                      onClick={() =>
                        navigate('/legalizaranticipo', {
                          state: { id: item.id },
                        })
                      }
                    >
                      {l('actions.legalize')}
                    </Button>,
                    <Button
                      name="ant-pending-own_button-details"
                      key="ant-pending-own_button-details"
                      className={styles['button-details']}
                      onClick={() => {
                        setScroll(localScroll);
                        navigate('/detallesanticipo', {
                          state: { id: item.id, previusPath: location.pathname },
                        });
                      }}
                      theme="tertiary"
                    >
                      {l('actions.seeDetails')}
                    </Button>,
                  ]
                : [
                    <Button
                      name="ant-pending-own_button-details"
                      key="ant-pending-own_button-details"
                      className={styles['button-details']}
                      onClick={() => {
                        setScroll(localScroll);
                        navigate('/detallesanticipo', {
                          state: { id: item.id, previusPath: location.pathname },
                        });
                      }}
                      theme="tertiary"
                    >
                      {l('actions.seeDetails')}
                    </Button>,
                  ]
            }
            footer={<Timeline data={item} />}
          />
        ))}
      </InfiniteScrollList>
    </>
  );
};

export default PendingOwn;
