/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect, useState } from 'react';

import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { AlertTooltipIcon } from '@/assets/icons/icons';
import Button from '@/components/ui/button';
import Checkbox from '@/components/ui/checkbox';
import TextArea from '@/components/ui/textArea';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { addIncidence, Types } from '@/services/advances';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const DetailsIncidence = ({ id }: { id: number }) => {
  const {
    actions: { addToast },
  } = useContext(utilsContext);
  const t = useLocalTranslation(i18n as Resource);
  const [form, setForm] = useState<Types.TIncidenceFacilitator>({
    incidenciaVuelos: false,
    incidenciaHospedaje: false,
    incidenciaRoaming: false,
    incidenciaTransporte: false,
    incidenciaObservacion: '',
    incidenciaFecha: '',
  });
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(false);
  const [hosting, setHosting] = useState(false);
  const [flights, setFlights] = useState(false);
  const [transportation, setTransportation] = useState(false);
  const [roaming, setRoaming] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (hosting || flights || roaming || transportation) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [hosting, flights, roaming, transportation]);

  return (
    <div className={styles['container']}>
      <Button
        name="ant-incidence_button-show"
        onClick={() => setShow(!show)}
        trailing={show ? <FaChevronUp /> : <FaChevronDown />}
        className={`${styles['show']}`}
      >
        <AlertTooltipIcon width={24} height={24} className={styles['icon']} fill={'#FFF'} />
        {t('title')}
      </Button>
      {show && (
        <div className={styles['wrapper']}>
          <span>
            <strong>• </strong>
            {t('message')}
          </span>
          <div className={styles['checkboxes']}>
            <Checkbox
              key="ant-incidence_checkout-hosting"
              name="ant-incidence_checkout-hosting"
              label={t('items.hosting')}
              positionLabel="end"
              onChange={() => (
                setHosting(!hosting), setForm({ ...form, incidenciaHospedaje: !hosting })
              )}
            />
            <Checkbox
              key="ant-incidence_checkout-flights"
              name="ant-incidence_checkout-flights"
              label={t('items.flights')}
              positionLabel="end"
              onChange={() => (
                setFlights(!flights), setForm({ ...form, incidenciaVuelos: !flights })
              )}
            />
            <Checkbox
              key="ant-incidence_checkout-transportation"
              name="ant-incidence_checkout-transportation"
              label={t('items.transportation')}
              positionLabel="end"
              onChange={() => (
                setTransportation(!transportation),
                setForm({ ...form, incidenciaTransporte: !transportation })
              )}
            />
            <Checkbox
              key="ant-incidence_checkout-roaming"
              name="ant-incidence_checkout-roaming"
              label={t('items.roaming')}
              positionLabel="end"
              onChange={() => (
                setRoaming(!roaming), setForm({ ...form, incidenciaRoaming: !roaming })
              )}
            />
          </div>
          <TextArea
            disabled={checked === false}
            className={styles['text']}
            onChange={(e) => setForm({ ...form, incidenciaObservacion: e.currentTarget.value })}
          />
          <Button
            name="ant-incidence_button"
            onClick={() => (
              addIncidence(form, id),
              navigate('/porfacilitardemiequipoanticipos'),
              addToast(t('toast.incidenceSuccess'), 'success')
            )}
          >
            {t('button')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default DetailsIncidence;
