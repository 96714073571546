/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect, useState } from 'react';

import { MdMenu } from 'react-icons/md';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { LogoHeaderIcon } from '@/assets/icons/icons';
import Translation from '@/components/translation';
import Button from '@/components/ui/button';
import { sessionContext } from '@/contexts/session';
import { useTranslation } from '@/hooks/useLocalTranslation';
import { PRAGMA } from '@/pages';

import styles from './styles.module.scss';
import UserMenu from './userMenu';

type TProps = {
  setShowMenu: (show: boolean) => void;
};

enum Constant {
  NAME_VACATIONS = 'vacations',
  URL_SOLICITUD_ANTICIPO = '/solicitaranticipo',
  USER_PRAGMA = 'pragma.com.co',
  PAGES = 'pages',
}

const Header = ({ setShowMenu }: TProps) => {
  const { state } = useContext(sessionContext);
  const emailBunissess = state.data?.correoEmpresarial;
  const location = useLocation();
  const { t } = useTranslation(Constant.PAGES);
  const [hover, setHover] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (state.isUserPragmatic) {
      navigate(Constant.URL_SOLICITUD_ANTICIPO);
    }
  }, []);
  return (
    <header className={styles.header}>
      <div className={styles['extend-line']}></div>
      <div className={styles['container']}>
        <div className={styles['header-line']}>
          <div className={`${styles['line']} ${styles['line-left']}`}></div>
          <div className={styles['container-logo']}>
            <Button
              name="user-menu_button-menu"
              text
              className={styles['menu']}
              onClick={() => setShowMenu(true)}
            >
              <MdMenu />
            </Button>
            <div className={styles['box-logo']}>
              <LogoHeaderIcon className={styles['logo']} height={36} width={36} />
            </div>
            <div className={styles['separate']}></div>
            <span className={styles['header-logo']}>{t('advances.header.requests')}</span>
          </div>
        </div>
        <div className={styles.links}>
          {PRAGMA.map((app) => {
            // Verificamos que el usario tenga acceso al menu y haya submenus disponibles
            if (
              app.name === Constant.NAME_VACATIONS &&
              emailBunissess?.includes(Constant.USER_PRAGMA)
            ) {
              return;
            }

            if (
              Object.keys(state.menu.data).find(
                (key) => key === app.name && state.menu.data[key].length > 0,
              )
            ) {
              return (
                <NavLink
                  onClick={() => setHover(false)}
                  onMouseOver={() =>
                    !app.routes.find(
                      (page) => page.path?.split('/')[1] === location.pathname.split('/')[1],
                    ) && setHover(true)
                  }
                  onMouseLeave={() =>
                    !app.routes.find(
                      (page) => page.path?.split('/')[1] === location.pathname.split('/')[1],
                    ) && setHover(false)
                  }
                  key={app.name}
                  end
                  to={app.index}
                  className={`${styles.link}
                  ${
                    app.routes.find(
                      (page) => page.path?.split('/')[1] === location.pathname.split('/')[1],
                    ) && styles.active
                  } ${
                    hover &&
                    app.routes.find(
                      (page) => page.path?.split('/')[1] === location.pathname.split('/')[1],
                    ) &&
                    styles['active-custom']
                  }`}
                >
                  <div className={styles['container-title']}>
                    <h2 className={`${styles.title} ${styles['title-custom']}`}>
                      {t(`${app.name}.title.complete`)}
                    </h2>
                    {state.notifications[app.name].total > 0 && (
                      <span className={styles.notifications}>
                        {state.notifications[app.name].total}
                      </span>
                    )}
                  </div>
                  <span
                    className={
                      app.routes.find(
                        (page) => page.path?.split('/')[1] === location.pathname.split('/')[1],
                      )
                        ? styles['lighting']
                        : styles['lighting-off']
                    }
                  ></span>
                </NavLink>
              );
            }
            return null;
          })}
        </div>
        <div className={styles['header-line']}>
          <div className={`${styles['line']} ${styles['line-right']}`}></div>
          <div className={styles['container-user']}>
            <Translation className={styles.translation} />
            <div className={styles['separate']}></div>
            <UserMenu />
          </div>
        </div>
      </div>
      <div className={styles['extend-line']}></div>
    </header>
  );
};

export default Header;
