/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useState } from 'react';

import Card from '@/components/card';
import { TReassign } from '@/components/reassignModal';
import ReassignModalApproverVacations from '@/components/reassignModalApproverVacations';
import StateLabel from '@/components/stateLabel';
import Button from '@/components/ui/button';
import TextField from '@/components/ui/textField';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import useVacationsData from '@/hooks/useVacationsData';
import { getVacationItem, Types } from '@/services/vacations';

import i18n from './i18n.json';
import styles from './styles.module.scss';

export const ChangeApprover = () => {
  const t = useLocalTranslation(i18n as Resource);
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const [idSearched, setIdSearched] = useState('');
  const [item, setItem] = useState<Types.TVacationItem>();
  const { cardInfo } = useVacationsData();
  const [reassingModal, setReassingModal] = useState<TReassign>({ show: false });

  const handleSearch = (id: number) => {
    setLoading(true);
    getVacationItem(Number(id || idSearched))
      .finally(() => setLoading(false))
      .then((res) => setItem(res.dato))
      .catch((e) => {
        if (idSearched) {
          addToast(e.message, 'danger');
        }
        setItem(undefined);
      });
  };

  return (
    <section className={styles['search']}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch(0);
        }}
      >
        <TextField
          label={t('request.label')}
          placeholder={t('request.placeholder')}
          keyPattern={/^[0-9]*$/}
          onChange={(e) => setIdSearched(e.currentTarget.value)}
          value={idSearched}
        />
        <Button name="vac-search_button" onClick={() => handleSearch(0)}>
          {t('actions.search')}
        </Button>
      </form>
      {item && (
        <div className={styles['content']}>
          <Card
            overflow
            key={item.id}
            header={{
              id: item.id,
              photo: item.fotografiaUsuarioSolicitante?.contenido,
              name: item.usuarioNombre,
              email: item.usuarioCorreo,
              date: new Date(item.fechaCreacion),
            }}
            data={cardInfo(item)}
            state={
              <div className={styles['state']}>
                {t('request.stateLabel')}
                <StateLabel state={item.estado} />
              </div>
            }
            actions={[
              <Button
                key="vac-administrator_button-reassign"
                name="vac-administrator_button-reassign"
                onClick={() => setReassingModal({ id: item.id, show: true })}
              >
                {t('actions.reassign')}
              </Button>,
            ]}
          />
        </div>
      )}
      <ReassignModalApproverVacations
        reassign={reassingModal}
        setReassign={setReassingModal}
        searchable={false}
        search={handleSearch}
      />
    </section>
  );
};
