/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import FacilitateSupports from '@/app/advances/facilitateSupports';
import { utilsContext } from '@/contexts/utils';
import { getDetails, Types } from '@/services/advances';

type TLocationState = {
  id: number | null;
};

const FacilitateSupportsPage = () => {
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const location = useLocation();
  const { id } = (location.state as TLocationState) || { id: null };
  const [details, setDetails] = useState<Types.TAdvanceDetails | null>(null);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getDetails(id)
        .finally(() => setLoading(false))
        .then((res) => setDetails(res))
        .catch((e) => addToast(e.message, 'danger'));
    }
  }, []);

  return details && <FacilitateSupports data={details} />;
};

export default FacilitateSupportsPage;
