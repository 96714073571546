/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useEffect, useState } from 'react';

import { DownloadIcon, EyeIcon } from '@/assets/icons/icons';
import Button from '@/components/ui/button';
import Toggle from '@/components/ui/toggle';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { getFacilitatorSupports, Types } from '@/services/advances';

import DetailsHosts from '../hosts';
import DetailsTickets from '../tickets';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const AttachedFiles = ({ data }: { data: Types.TAdvanceDetails | null }) => {
  const t = useLocalTranslation(i18n as Resource);
  const {
    actions: { addToast, setLoading },
  } = useContext(utilsContext);
  const [supports, setSupports] = useState<Types.TGetFacilitatorSupportsRes>();

  const viewFile = (fileURL: string) => {
    const splitFile = fileURL.split('.');
    if (splitFile[splitFile.length - 1] === 'pdf')
      window.open(`https://docs.google.com/viewerng/viewer?url=${fileURL}`);
    else {
      window.open('', '_blank')?.document.write(`
        <html>
          <head>
          </head>
          <body>
            <img src="${fileURL}" alt="${fileURL}" maxheight="100%" maxwidth="100%">
          </body>
        </html>
      `);
    }
  };

  useEffect(() => {
    if (data) {
      setLoading(true);
      getFacilitatorSupports(data.id)
        .finally(() => setLoading(false))
        .then((res) => setSupports(res))
        .catch((e) => addToast(e.message, 'danger'));
    }
  }, []);

  return (
    <section className={styles['attached-files']}>
      {!!data?.tiquetes.length && (
        <div className={styles['module']}>
          <DetailsTickets data={data} hideServices />
          <h3>{t('title')}</h3>
          <ul className={styles['file-list']}>
            {supports?.soportesTiquete.soportes
              .filter((s) => s.tiquete)
              .map((s, _key) => (
                <li key={s.id} className={styles['file']}>
                  <span className={styles['file-name']}>
                    {s?.nombreArchivo !== null ? s.nombreArchivo : `${t('fileName')} ${_key + 1}`}
                  </span>
                  <Button
                    name={`${t('fileName')} ${_key}`}
                    ghost
                    className={styles['download']}
                    onClick={() => window.open(s.url)}
                  >
                    <DownloadIcon width={16} height={16} />
                  </Button>
                  <Button
                    name={`${t('fileName')} ${_key}`}
                    ghost
                    className={styles['view']}
                    onClick={() => viewFile(s.url)}
                  >
                    <EyeIcon width={16} height={12} />
                  </Button>
                </li>
              ))}
          </ul>
          <div className={styles['note']}>
            <h4>{t('note.title')}</h4>
            <p>{supports?.soportesTiquete.comentario}</p>
          </div>
          <div className={styles['services']}>
            <h3>{t('services.title')}</h3>
            <Toggle
              label={t('services.roaming.question')}
              checked={data.necesitaRoaming}
              readOnly
            />
            <Toggle
              label={t('services.transport.question')}
              checked={data.necesitaTransporte}
              readOnly
            />
          </div>
        </div>
      )}
      {!!data?.hospedajes.length && (
        <div className={styles['module']}>
          <DetailsHosts data={data} />
          <h3>{t('title')}</h3>
          <ul className={styles['file-list']}>
            {supports?.soportesHospedaje.soportes
              .filter((s) => s.hospedaje)
              .map((s, _key) => (
                <li key={s.id} className={styles['file']}>
                  <span className={styles['file-name']}>
                    {s?.nombreArchivo !== null ? s.nombreArchivo : `${t('fileName')} ${_key + 1}`}
                  </span>
                  <Button
                    name={`${t('fileName')} ${_key}`}
                    ghost
                    className={styles['download']}
                    onClick={() => window.open(s.url)}
                  >
                    <DownloadIcon width={16} height={16} />
                  </Button>
                  <Button
                    name={`${t('fileName')} ${_key}`}
                    ghost
                    className={styles['view']}
                    onClick={() => viewFile(s.url)}
                  >
                    <EyeIcon width={16} height={12} />
                  </Button>
                </li>
              ))}
          </ul>
          <div className={styles['note']}>
            <h4>{t('note.title')}</h4>
            <p>{supports?.soportesHospedaje.comentario}</p>
          </div>
        </div>
      )}
    </section>
  );
};

export default AttachedFiles;
