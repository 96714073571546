/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import { CancelIcon, CheckTimelineIcon } from '@/assets/icons/icons';

import styles from './styles.module.scss';

type TProps = {
  text: string;
  theme?: 'active' | 'inactive' | 'semiactive' | 'error';
  className?: string;
  line?: 'active' | 'inactive' | 'error';
  nextLine?: 'active' | 'inactive' | 'error';
};

const Step = ({ text, theme = 'inactive', className, line, nextLine }: TProps) => {
  return (
    <div className={`${styles['step']} ${className || ''}`}>
      <div className={styles['draw']}>
        <span className={styles['line']} data-line={line} />
        <div className={styles['status']} data-theme={theme}>
          {theme === 'active' ? (
            <CheckTimelineIcon height={17} width={17} />
          ) : theme === 'error' ? (
            <CancelIcon height={9} width={9} />
          ) : (
            ''
          )}
        </div>
        <span className={styles['line']} data-line={nextLine} />
      </div>
      <span className={styles['text']}>{text}</span>
    </div>
  );
};

export default Step;
