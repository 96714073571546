/**
 * Solicitudes Pragma SA
 */

import React from 'react';

import styles from './styles.module.scss';

export type TSize = 'XS' | 'S' | 'M';

type TProps = {
  size: TSize;
  titleCard: string;
  children: React.ReactNode;
  selected?: boolean;
  onclick?: (size: TSize) => void;
  readOnly?: boolean;
};

const CardSize = ({ size, titleCard, children, selected, onclick, readOnly }: TProps) => {
  return (
    <div
      className={`${styles['card-size']} ${readOnly ? styles['disabled'] : ''}`}
      data-theme={selected ? 'selected' : undefined}
      onClick={() => (onclick ? onclick(size) : null)}
      aria-hidden="true"
    >
      <div className={styles['header-size']} data-theme={selected ? 'selected' : undefined}>
        <div className={styles['container-size']} data-theme={selected ? 'selected' : undefined}>
          {size}
        </div>
        {titleCard}
      </div>
      <div className={styles['children']} data-theme={selected ? 'selected' : undefined}>
        {children}
      </div>
    </div>
  );
};

export default CardSize;
